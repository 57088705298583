<!--StepTwo.vue-->
<template>
  <SubStep2_1 v-if="navigationState.currentSubStep === '2.1'"/>
  <SubStep2_2 v-if="navigationState.currentSubStep === '2.2'"/>
  <SubStep2_3 v-if="navigationState.currentSubStep === '2.3'"/>
  <SubStep2_4 v-if="navigationState.currentSubStep === '2.4'"/>
  <SubStep2_5 v-if="navigationState.currentSubStep === '2.5'"/>
  <SubStep2_6 v-if="navigationState.currentSubStep === '2.6'"/>
  <SubStep2_7 v-if="navigationState.currentSubStep === '2.7'"/>
  <SubStep2_8 v-if="navigationState.currentSubStep === '2.8'"/>
  <SubStep2_9 v-if="navigationState.currentSubStep === '2.9'"/>
  <SubStep2_10 v-if="navigationState.currentSubStep === '2.10'"/>
  <SubStep2_11 v-if="navigationState.currentSubStep === '2.11'"/>
  <SubStep2_12 v-if="navigationState.currentSubStep === '2.12'"/>
</template>

<style scoped>

</style>

<script>


import SubStep2_1 from "@/views/host/listing/Step2/SubStep2_1.vue";
import SubStep2_2 from "@/views/host/listing/Step2/SubStep2_2.vue";
import SubStep2_3 from "@/views/host/listing/Step2/SubStep2_3.vue";
import SubStep2_4 from "@/views/host/listing/Step2/SubStep2_4.vue";
import SubStep2_5 from "@/views/host/listing/Step2/SubStep2_5.vue";
import SubStep2_6 from "@/views/host/listing/Step2/SubStep2_6.vue";
import SubStep2_7 from "@/views/host/listing/Step2/SubStep2_7.vue";
import SubStep2_8 from "@/views/host/listing/Step2/SubStep2_8.vue";
import SubStep2_9 from "@/views/host/listing/Step2/SubStep2_9.vue";
import SubStep2_10 from "@/views/host/listing/Step2/SubStep2_10.vue";
import SubStep2_11 from "@/views/host/listing/Step2/SubStep2_11.vue";
import SubStep2_12 from "@/views/host/listing/Step2/SubStep2_12.vue";
import stepNavigationMixin from "@/mixins/stepNavigationMixin";
import {mapState} from "pinia";
import {createListingStore} from "@/stores/host/listing/createListingStore";

export default {
  name: 'StepTwo',
  inject: ['navigationState'],
  mixins: [stepNavigationMixin],
  components: {
    SubStep2_1,
    SubStep2_2,
    SubStep2_3,
    SubStep2_4,
    SubStep2_5,
    SubStep2_6,
    SubStep2_7,
    SubStep2_8,
    SubStep2_9,
    SubStep2_10,
    SubStep2_11,
    SubStep2_12,
  },
  computed: {
    ...mapState(createListingStore, ['formData']),
    // currentSubStep() {
    //   return this.$parent.currentSubStep;
    // },
    // subStepArray() {
    //   return this.subSteps['2'];
    // },
  },
  methods: {

  },
};
</script>