<template>
  <div class="form-step active" data-step="2-11">
    <div>
      <h2 class="heading-2">What amenities are available at your place?</h2>

      <p class="font-size-medium">Select all that applies.</p>
    </div>

    <div class="form-group">
      <div class="flex-container custom-checkbox-container mt-5" role="group" aria-labelledby="amenities-label">
        <!-- First Column -->
        <div class="checkbox-container">
          <input type="checkbox" id="free-parking" v-model="formData.amenities" value="free_parking">

          <label for="free-parking">
            <img src="@/assets/svgs/parking.svg" alt="Street parking">

            Free parking on site</label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="wifi" v-model="formData.amenities" value="wifi">

          <label for="wifi">
            <img src="@/assets/svgs/wifi.svg" alt="wifi">

            Wifi</label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="window-covering" v-model="formData.amenities" value="window_covering">

          <label for="window-covering">
            <img src="@/assets/svgs/window-covering.svg" alt="Window covering">

            Window covering</label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="dryer" v-model="formData.amenities" value="dryer">

          <label for="dryer">
            <img src="@/assets/svgs/dryer.svg" alt="wifi">

            Dryer</label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="microwave" v-model="formData.amenities" value="microwave">

          <label for="microwave">
            <img src="@/assets/svgs/microwave.svg" alt="microwave">

            Microwave</label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="fridge" v-model="formData.amenities" value="fridge">

          <label for="fridge">
            <img src="@/assets/svgs/fridge.svg" alt="microwave">

            Fridge</label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="air-conditioner" v-model="formData.amenities" value="air_conditioner">

          <label for="air-conditioner">
            <img src="@/assets/svgs/air-conditioner.svg" alt="air conditioner">

            Air conditioner</label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="iron" v-model="formData.amenities" value="iron">

          <label for="iron">
            <img src="@/assets/svgs/iron.svg" alt="air conditioner">

            Iron</label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="swimming-pool" v-model="formData.amenities" value="swimming_pool">

          <label for="swimming-pool">
            <img src="@/assets/svgs/swimming-pool.svg" alt="swimming pool">

            Swimming pool</label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="street-parking" v-model="formData.amenities" value="street_parking">

          <label for="street-parking">
            <img src="@/assets/svgs/parking.svg" alt="Street parking">

            Street parking</label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="kitchenware" v-model="formData.amenities" value="kitchenware">

          <label for="kitchenware">
            <img src="@/assets/svgs/kitchenware.svg" alt="kitchenware">

            Kitchenware</label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="washer" v-model="formData.amenities" value="washer">

          <label for="washer">
            <img src="@/assets/svgs/washer.svg" alt="washer">

            Washer</label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="stove-oven" v-model="formData.amenities" value="stove_and_oven">

          <label for="stove-oven">
            <img src="@/assets/svgs/stove-oven.svg" alt="stove and oven">

            Stove and oven</label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="dishwasher" v-model="formData.amenities" value="dishwasher">

          <label for="dishwasher">
            <img src="@/assets/svgs/dishwasher.svg" alt="dishwasher">

            Dishwasher</label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="tv" v-model="formData.amenities" value="tv">

          <label for="tv">
            <img src="@/assets/svgs/tv.svg" alt="tv">

            TV</label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="blow-dryer" v-model="formData.amenities" value="blow_dryer">

          <label for="blow-dryer">
            <img src="@/assets/svgs/blow-dryer.svg" alt="blow_dryer">

            Blow dryer</label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="hot-tub" v-model="formData.amenities" value="hot_tub">

          <label for="hot-tub">
            <img src="@/assets/svgs/hot-tub.svg" alt="hot tub">

            Hot tub</label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="coffee-machine" v-model="formData.amenities" value="coffee_machine">

          <label for="coffee-machine">
            <img src="@/assets/svgs/coffee-machine.svg" alt="coffee machine">

            Coffee machine</label>
        </div>
      </div>

      <div id="amenitiesErrorText" class="validation-error"></div>

      <div id="additional-amenities">
        <fieldset>
          <legend>Additional amenities</legend>
          <div
              class="form-group amenity-input-group"
              v-for="(amenity, index) in additionalAmenities"
              :key="index"
          >
            <label :for="'additional-amenity-' + index" class="sr-only"></label>
            <input
                type="text"
                v-model="additionalAmenities[index]"
                placeholder="Enter amenity name"
                :id="'additional-amenity-' + index"
                required
            />
            <button
                type="button"
                class="delete-button"
                @click="removeAmenity(index)"
                aria-label="Remove amenity"
            >
              <!-- Delete icon -->
              <svg width="16" height="16" fill="currentColor" aria-hidden="true">
                <path d="M1 1L15 15M15 1L1 15" stroke="currentColor" stroke-width="2"/>
              </svg>
            </button>
            <div
                :id="'amenitiesErrorText-' + index"
                class="validation-error"
            >
              {{ validationErrors[index] }}
            </div>
          </div>
        </fieldset>

        <div
            class="add-additional-amenities-container add-container"
            tabindex="0"
            role="button"
            aria-label="Add more"
            @click="addAmenity"
            @keyup.enter="addAmenity"
        >
          <img src="assets/svgs/plus.svg" alt="Add icon">
          <p><strong>Add more</strong></p>
        </div>
      </div>

    </div>

    <div class="btns-container">
      <button type="button" class="secondary-btn prev-step" @click="prevSubStep">Back</button>
      <button type="button" class="primary-btn next-step" @click="nextSubStep" :class="{'btn-disabled': !isValid}" :disabled="!isValid">Next</button>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from "pinia";
import {createListingStore} from "@/stores/host/listing/createListingStore";
import stepNavigationMixin from "@/mixins/stepNavigationMixin";

export default {
  name: "SubStep2_11",
  mixins: [stepNavigationMixin],
  inject: ['navigationState'],
  data (){
    return {
      additionalAmenities: [],
      validationErrors: [],
      validationError: '',
    }
  },
  mounted() {
    if (
        this.formData.additional_amenities &&
        this.formData.additional_amenities.length > 0
    ) {
      this.additionalAmenities = this.formData.additional_amenities;
    }
  },
  computed: {
    ...mapState(createListingStore, ['formData']),
    isValid() {
      return ((this.formData.amenities && this.formData.amenities.length > 0) || this.additionalAmenities.some((amenity) => amenity.trim() !== ''));
    },
  },
  methods: {
    ...mapActions(createListingStore, ['updateFormData', 'saveDraft']),
    addAmenity() {
      const hasEmptyAmenity = this.additionalAmenities.some(
          (amenity) => amenity.trim() === ''
      );

      if (hasEmptyAmenity) {
        this.validationError =
            'Please fill in all existing amenity fields before adding a new one.';
        return;
      }
      this.additionalAmenities.push('');
      this.validationErrors.push('');
    },
    removeAmenity(index) {
      this.additionalAmenities.splice(index, 1);
      this.validationErrors.splice(index, 1);
    },
    nextSubStep() {
      // Validate that all additional amenities inputs are filled
      const hasEmptyAmenity = this.additionalAmenities.some(
          (amenity) => amenity.trim() === ''
      );

      if (hasEmptyAmenity) {
        this.validationError =
            'Please fill in all additional amenities or remove empty ones.';
        return;
      }

      // Clear validation error if validation passes
      this.validationError = '';

      // Update formData with additionalAmenities in snake_case
      this.updateFormData({
        additional_amenities: this.additionalAmenities,
      });

      // Now save the updated formData
      this.saveDraft(this.navigationState);

      // Proceed to next sub-step
      this.nextStep();
    },
    prevSubStep() {
      this.prevStep();
    },
  }
}
</script>

<style scoped>
.amenity-input-group {
  position: relative;
  display: flex;
  align-items: center;
}

.amenity-input-group input {
  flex: 1;
  padding-right: 2rem; /* Adjust to accommodate the delete button */
}

.delete-button {
  position: absolute;
  right: 0.5rem;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.delete-button svg {
  width: 1rem;
  height: 1rem;
  color: #ff0000; /* Adjust color as needed */
}

.delete-button:focus {
  outline: none;
}

.amenity-input-group .validation-error {
  margin-top: 0.5rem;
}
</style>
