import { defineStore } from 'pinia';

export const useHostFormStore = defineStore('hostForm', {
    state: () => ({
        currentStep: 1,
        formData: {
            firstName: '',
            lastName: '',
            phone: '',
            contactMethod: false,
            birthYear: '',
            province: '',
            password: '',
            email: '',
            postal_code: '',
            agree_terms: false, // Confirmed to match the component
        },
        errors: {},
        provinces: {
            AB: 'Alberta',
            BC: 'British Columbia',
            MB: 'Manitoba',
            NB: 'New Brunswick',
            NL: 'Newfoundland and Labrador',
            NS: 'Nova Scotia',
            NT: 'Northwest Territories',
            NU: 'Nunavut',
            ON: 'Ontario',
            PE: 'Prince Edward Island',
            QC: 'Quebec',
            SK: 'Saskatchewan',
            YT: 'Yukon',
        },
    }),
    getters: {
        selectedProvinceText(state) {
            return state.formData.province
                ? state.provinces[state.formData.province]
                : 'Select your province';
        },
    },
});
