// http.js
import axios from 'axios';

// Create an Axios instance
const http = axios.create({
    baseURL: 'https://happipad.net', // Ensure this is the correct API base URL
    // baseURL: process.env.VUE_APP_API_BASE_URL, // Ensure this is the correct API base URL
    timeout: 10000, // Set a timeout limit
    headers: {
        'Accept': 'application/json',
        // Do not set 'Content-Type' here; we'll set it per request if needed
    },
    // withCredentials: true, // Include this if you need to send cookies or other credentials
});

// You can add request/response interceptors if needed
http.interceptors.request.use(
    config => {
        // Example: Add an authorization token if needed
        const token = localStorage.getItem('auth_token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

http.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        // Enhanced error handling example
        if (error.response && error.response.status === 401) {
            // Handle unauthorized access (e.g., redirect to login)
            // For example:
            // window.location.href = '/login';
        } else if (error.response && error.response.status === 422) {
            // Handle validation errors
        }
        return Promise.reject(error);
    }
);

export default http;
