// router.js
import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/host/Signup/Signup.vue';
import HostProfile from '@/views/host/profile/Create.vue';
import CreateListing from '@/views/host/listing/Create.vue';
import VerifyAccount from '@/views/host/Signup/VerifyAccount.vue';
import AccountReady from '@/views/host/Signup/AccountReady.vue';
import NextSteps from '@/views/host/Signup/NextSteps.vue';
import Login from '@/views/auth/Login.vue';
import RecoverPassword from "@/views/auth/RecoverPassword.vue";
import ResetPassword from "@/views/auth/ResetPassword.vue"; // Import the Login component
import RenterDashboard from "@/views/renter/dashboard/RenterDashboard.vue";
import RenterProfile from "@/views/renter/dashboard/RenterProfile.vue";
import RenterSettings from "@/views/renter/dashboard/RenterSettings.vue";
import RenterSecurity from "@/views/renter/dashboard/RenterSecurity.vue";
import RenterNotificationControl from "@/views/renter/dashboard/RenterNotificationControl.vue";
import RenterReceipts from "@/views/renter/dashboard/RenterReceipts.vue";
import RenterHelp from "@/views/renter/dashboard/RenterHelp.vue";
import RenterMyStay from "@/views/renter/dashboard/RenterMyStay.vue";

const routes = [
    {
        path: '/host',
        children: [
            {
                path: 'signup',
                name: 'Home',
                component: Home,
            },
            {
                path: 'profile/create',
                name: 'HostProfile',
                component: HostProfile,
                meta: { requiresAuth: true }, // Protect this route
            },
            {
                path: 'listing/create',
                name: 'CreateListing',
                component: CreateListing,
                meta: { requiresAuth: true }, // Protect this route
            },
            {
                path: 'listing/properties/:id/edit',
                name: 'EditListing',
                component: CreateListing,
                props: true,
                meta: { requiresAuth: true }, // Protect this route
            },
            {
                path: 'listing/properties/:id',
                name: 'ShowListing',
                component: CreateListing,
                props: true,
                meta: { requiresAuth: true }, // Protect this route
            },
        ],
    },
    {
        path: '/renter',
        children: [
            {
                path: "dashboard",
                name: "RenterDashboard",
                component: RenterDashboard,
            },
            {
                path: "profile",
                name: "RenterProfile",
                component: RenterProfile,
            },
            {
                path: "settings",
                name: "RenterSettings",
                component: RenterSettings,
            },
            {
                path: "security",
                name: "RenterSecurity",
                component: RenterSecurity,
            },
            {
                path: "notification-control",
                name: "RenterNotificationControl",
                component: RenterNotificationControl,
            },
            {
                path: "receipts",
                name: "RenterReceipts",
                component: RenterReceipts,
            },
            {
                path: "help",
                name: "RenterHelp",
                component: RenterHelp,
            },
            {
                path: "mystay",
                name: "RenterMyStay",
                component: RenterMyStay,
            }
        ],
    },
    {
        path: '/awaiting-verification',
        name: 'AwaitingVerification',
        component: VerifyAccount,
    },
    {
        path: '/verify-email',
        name: 'VerifyEmail',
        component: AccountReady,
    },
    {
        path: '/next',
        name: 'NextSteps',
        component: NextSteps,
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/recover-password',
        name: 'RecoverPassword',
        component: RecoverPassword,
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: ResetPassword,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

// Navigation Guard
router.beforeEach((to, from, next) => {
    const isAuthenticated = localStorage.getItem('auth_token') !== null;

    if (to.matched.some((record) => record.meta.requiresAuth)) {
        // This route requires authentication
        if (!isAuthenticated) {
            // User is not authenticated, redirect to login
            next({
                path: '/login',
                query: { redirect: to.fullPath }, // Save the intended route for later
            });
        } else {
            // User is authenticated, proceed to route
            next();
        }
    } else {
        // This route doesn't require authentication
        next();
    }
});

export default router;
