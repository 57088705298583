import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import { createI18n } from 'vue-i18n';

import './assets/sass/style.scss';
import './assets/custom.css';

import en from './locales/en.json';
import fr from './locales/fr.json';
import ht from './locales/ht.json';
import es from './locales/es.json';

const messages = {
    en: en,
    fr: fr,
    ht: ht,
    es: es,
};

// Create an i18n instance
const i18n = createI18n({
    locale: 'en', // set locale by default
    fallbackLocale: 'en', // fallback locale if the current locale is missing
    messages, // set locale messages
});


const app = createApp(App);

// Create a Pinia instance
const pinia = createPinia();

// We are using Pinia as store instead of Vuex Guys (because its deprecated)
app.use(pinia);
app.use(i18n);
app.use(router);

app.mount('#app');
