export default {
    methods: {
        nextStep() {
            const steps = this.navigationState.steps;
            const currentStepIndex = steps.indexOf(this.navigationState.currentStep);
            const currentSubSteps = this.navigationState.subSteps[this.navigationState.currentStep] || [];

            if (currentSubSteps.length > 0) {
                if (!this.navigationState.currentSubStep) {
                    this.navigationState.currentSubStep = currentSubSteps[0];
                    return;
                }
                const currentSubStepIndex = currentSubSteps.indexOf(this.navigationState.currentSubStep);
                if (currentSubStepIndex < currentSubSteps.length - 1) {
                    this.navigationState.currentSubStep = currentSubSteps[currentSubStepIndex + 1];
                    return;
                }
            }
            if (currentStepIndex < steps.length - 1) {
                const nextStep = steps[currentStepIndex + 1];
                this.navigationState.currentStep = nextStep;
                const nextSubSteps = this.navigationState.subSteps[nextStep] || [];
                this.navigationState.currentSubStep = nextSubSteps.length > 0 ? nextSubSteps[0] : null;
            } else {
                console.log('Already at the last step');
            }
        },
        prevStep() {
            const steps = this.navigationState.steps;
            const currentStepIndex = steps.indexOf(this.navigationState.currentStep);
            const currentSubSteps = this.navigationState.subSteps[this.navigationState.currentStep] || [];

            if (currentSubSteps.length > 0 && this.navigationState.currentSubStep) {
                const currentSubStepIndex = currentSubSteps.indexOf(this.navigationState.currentSubStep);
                if (currentSubStepIndex > 0) {
                    this.navigationState.currentSubStep = currentSubSteps[currentSubStepIndex - 1];
                    return;
                }
            }
            if (currentStepIndex > 0) {
                const prevStep = steps[currentStepIndex - 1];
                this.navigationState.currentStep = prevStep;
                const prevSubSteps = this.navigationState.subSteps[prevStep] || [];
                this.navigationState.currentSubStep = prevSubSteps.length > 0 ? prevSubSteps[prevSubSteps.length - 1] : null;
            } else {
                console.log('Already at the first step');
            }
        },
        goToStep(stepIndex, subStepIndex = null) {
            const steps = this.navigationState.steps;
            const subSteps = this.navigationState.subSteps;

            if (steps.includes(stepIndex)) {
                this.navigationState.currentStep = stepIndex;
                if (subStepIndex && subSteps[stepIndex]?.includes(subStepIndex)) {
                    this.navigationState.currentSubStep = subStepIndex;
                } else if (subSteps[stepIndex]) {
                    this.navigationState.currentSubStep = subSteps[stepIndex][0];
                } else {
                    this.navigationState.currentSubStep = null;
                }
            } else {
                console.warn(`Step ${stepIndex} does not exist.`);
            }
        },
        isStepCompleted(stepIndex) {
            const steps = this.navigationState.steps;
            const currentStep = this.navigationState.currentStep;
            const stepOrder = steps.indexOf(stepIndex);
            const currentStepOrder = steps.indexOf(currentStep);
            return stepOrder < currentStepOrder;
        },
        isSubStepCompleted(mainStepIndex, subStepIndex) {
            const steps = this.navigationState.steps;
            const currentStep = this.navigationState.currentStep;
            const currentSubStep = this.navigationState.currentSubStep;
            const subSteps = this.navigationState.subSteps;

            const mainStepOrder = steps.indexOf(mainStepIndex);
            const currentStepOrder = steps.indexOf(currentStep);

            if (mainStepOrder < currentStepOrder) {
                return true;
            }
            if (mainStepOrder === currentStepOrder) {
                const subStepArray = subSteps[mainStepIndex];
                const subStepOrder = subStepArray.indexOf(subStepIndex);
                const currentSubStepOrder = subStepArray.indexOf(currentSubStep);
                return subStepOrder < currentSubStepOrder;
            }
            return false;
        },
        getStepProgress(stepIndex) {
            const subSteps = this.navigationState.subSteps;
            const steps = this.navigationState.steps;
            const currentStep = this.navigationState.currentStep;
            const currentSubStep = this.navigationState.currentSubStep;

            if (!subSteps[stepIndex]) {
                return this.isStepCompleted(stepIndex) || currentStep === stepIndex ? 100 : 0;
            } else {
                const subStepArray = subSteps[stepIndex];
                const totalSubSteps = subStepArray.length;
                let completedSubSteps = 0;

                const mainStepOrder = steps.indexOf(stepIndex);
                const currentStepOrder = steps.indexOf(currentStep);

                if (mainStepOrder < currentStepOrder) {
                    completedSubSteps = totalSubSteps;
                } else if (mainStepOrder === currentStepOrder) {
                    const currentSubStepOrder = subStepArray.indexOf(currentSubStep);
                    completedSubSteps = currentSubStepOrder + 1;
                } else {
                    completedSubSteps = 0;
                }

                const percentage = (completedSubSteps / totalSubSteps) * 100;
                return percentage;
            }
        },
        getSubStepLabel(subStepIndex) {
            const labelKeys = this.navigationState.labelKeys;
            const key = labelKeys[subStepIndex];
            if (key) {
                return this.$t(key);
            } else {
                return '';
            }
        },
    },
};
