<template>
  <success-message-card>
    <template #title>
      Your account is ready<span class="dots">...</span>
    </template>

    <template #description>
      <!-- You can add additional messages or content here -->
    </template>
  </success-message-card>
</template>

<script>
import SuccessMessageCard from '@/views/shared/SuccessMessageCard.vue';
import http from '@/http.js'; // Import your custom Axios instance

export default {
  name: 'AccountReady',
  components: { SuccessMessageCard },
  data() {
    return {
      email: '',
      expires: '',
      signature: '',
    };
  },
  created() {
    this.verifyEmail();
  },
  methods: {
    verifyEmail() {
      const token = this.$route.query.token;

      if (!token) {
        this.message = 'Invalid verification link.';
        return;
      }

      http.post('/api/auth/email/verify', { token })
          .then(response => {
            this.message = response.data.message;
            const authToken = response.data.token;
            localStorage.setItem('auth_token', authToken);
            this.$router.push({ name: 'NextSteps', query: { step: 2 } });
          })
          .catch(error => {
            this.message = error.response.data.message || 'Verification failed.';
          });
    },
  },

};
</script>

<style scoped>
/* Dot animation */
.dots {
  display: inline-block;
  margin-left: 5px;
}

.dots::after {
  content: '.';
  animation: dots 1.5s steps(3, end) infinite;
}

@keyframes dots {
  0%, 20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: .25em 0 0 rgba(0, 0, 0, 0), .5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: #333;
    text-shadow: .25em 0 0 rgba(0, 0, 0, 0), .5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: .25em 0 0 #333, .5em 0 0 rgba(0, 0, 0, 0);
  }
  80%, 100% {
    text-shadow: .25em 0 0 #333, .5em 0 0 #333;
  }
}
</style>

