<template>
  <div class="form-step active" data-step="4.2">
    <div>
      <h1 class="heading-2">Let’s set a monthly price.</h1>
    </div>

    <div class="form-group listing-price-container">
      <input type="text" v-model="formData.price" placeholder="$..." id="listingPrice" required>

      <div id="listingPriceErrorText" class="validation-error"></div>

      <div class="form-group default-checkbox mt-4" tabindex="0">
        <input type="checkbox" id="utilities-included" v-model="formData.utilities_included" value="true" checked disabled/>

        <label for="utilities-included">
          <span>Utilities included</span>
        </label>
      </div>

      <p class="text-faded">Your price should include utilities</p>

      <hr>

      <div class="listing-final-price">
        <p>Price, including Happipad fee</p>

        <span id="finalPrice"><strong>$1000</strong></span>
      </div>
    </div>

    <div class="btns-container">
      <button type="button" class="secondary-btn prev-step" @click="prevSubStep">Back</button>
      <button type="button" class="primary-btn next-step" @click="nextSubStep" :class="{'btn-disabled': !isValid}" :disabled="!isValid">Next</button>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from "pinia";
import {createListingStore} from "@/stores/host/listing/createListingStore";
import stepNavigationMixin from "@/mixins/stepNavigationMixin";

export default {
  name: "SubStep4_2",
  inject: ['navigationState'],
  mixins: [stepNavigationMixin],
  computed: {
    ...mapState(createListingStore, ['formData']),
    isValid() {
      return this.formData.price !== null && this.validatePrice();
    }
  },
  methods: {
    ...mapActions(createListingStore, ['saveDraft']),
    nextSubStep() {
      this.saveDraft(this.navigationState);
      // Emit an event to the parent component
      this.nextStep();
    },
    prevSubStep() {
      // Emit an event to the parent component
      this.prevStep();
    },
    validatePrice() {
      const price = this.formData.price;

      // Regular expression to check if the value is a valid number or float
      const validPriceRegex = /^\d+(\.\d{1,2})?$/;

      if (!validPriceRegex.test(price)) {
        this.priceError = 'Price must be a valid number with up to two decimal places';
        return false;
      } else if (parseFloat(price) < 0) {
        this.priceError = 'Price cannot be negative';
        return false;
      } else {
        this.priceError = '';
        return true;
      }
    }

  }
}
</script>

<style scoped>

</style>