<template>
  <div class="container flex-container verify-email mb-6">
    <div class="content">
      <h1 class="heading-2">Verify your account.</h1>
      <img class="mt-4" src="@/assets/svgs/verify-email.svg" alt="Email icon" width="160" height="160"/>
    </div>

    <p>
      A verification link was emailed to
      <strong>{{ userEmail }}</strong>.<br />
      To confirm your account, please, follow the link.
    </p>

    <div class="timer-container">
      <p>Nothing yet?</p>
      <div class="timer">{{ formattedTime }}</div>
      <form @submit.prevent="resendVerificationEmail">
        <input type="hidden" name="email" class="form-control" :value="userEmail"
        />
        <button type="submit" class="secondary-btn" :disabled="!canResend">
          Resend Verification Email
        </button>
      </form>
    </div>
  </div>
</template>


<script>
import http from '@/http'
export default {
  data() {
    return {
      userEmail: this.$route.query.email, // You should replace this with a dynamic way to pass user email.
      timeLeft: 30,
      canResend: false,
      countdown: null,
    };
  },
  computed: {
    formattedTime() {
      return `00:${this.timeLeft < 10 ? "0" + this.timeLeft : this.timeLeft}`;
    },
  },
  methods: {
    startTimer() {
      this.timeLeft = 30;
      this.canResend = false;
      this.countdown = setInterval(() => {
        this.timeLeft--;
        if (this.timeLeft <= 0) {
          clearInterval(this.countdown);
          this.canResend = true;
        }
      }, 1000);
    },
    resendVerificationEmail() {
      this.canResend = false;
      this.startTimer();
      // Make an API call to resend the verification email
      http.post('/auth/email/verify/resend', {
        email: this.userEmail,
      })
          .then(response => {
            console.log(response)
            // Handle success
            // this.$toast.success(response.data.message || 'Verification email has been resent.');
          })
          .catch(error => {
            console.log(error)
            // Handle error
            // console.error(error);
            // const message = error.response?.data?.message || 'An error occurred while resending the verification email.';
            // this.$toast.error(message);
          });
    },
  },
  mounted() {
    this.startTimer();
  },
  beforeUnmount() {
    if (this.countdown) {
      clearInterval(this.countdown);
    }
  },
};
</script>


<style scoped>

</style>