<template>
  <div class="custom-dropdown" :class="{ active: isOpen }" @click.stop tabindex="0">
    <div
        class="dropdown-selected"
        @click.stop="toggleDropdown"
        :aria-expanded="isOpen ? 'true' : 'false'"
    >
      {{ selectedOptionText || 'Select an option' }}
    </div>
    <ul v-if="isOpen" class="dropdown-options" role="listbox">
      <li
          v-for="([value, text], index) in optionArray"
          :key="value"
          class="dropdown-option"
          :class="{ selected: selectedOption === value }"
          @click.stop="selectOption(value, text)"
          @keydown="onOptionKeydown($event, index)"
          tabindex="0"
          role="option"
      >
        {{ text }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: [Array, Object], // Accept either Array or Object
      required: true,
    },
    selected: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      selectedOption: this.selected,  // Tracks selected option value
      selectedOptionText: '',         // Tracks selected option text
      isOpen: false,                  // Dropdown open/close state
    };
  },
  computed: {
    // Convert options into an array of [key, value] pairs if it's an object
    optionArray() {
      if (Array.isArray(this.options)) {
        return this.options.map(opt => [opt, opt]);
      }
      return Object.entries(this.options); // Transform object to array
    }
  },
  watch: {
    selected: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.selectedOption = newVal;
          this.selectedOptionText = this.options[newVal] || newVal;
        }
      }
    }
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    selectOption(value, text) {
      this.selectedOption = value;
      this.selectedOptionText = text;
      this.isOpen = false; // Close dropdown after selection
      this.$emit('select', value); // Emit selected value to parent
    },
    onOptionKeydown(event, index) {
      const optionElements = this.$el.querySelectorAll('.dropdown-option');
      if (event.key === 'ArrowDown' && index < optionElements.length - 1) {
        optionElements[index + 1].focus();
      } else if (event.key === 'ArrowUp' && index > 0) {
        optionElements[index - 1].focus();
      }
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.isOpen = false;
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }
};
</script>

<style scoped>
.custom-dropdown {
  cursor: pointer;
  position: relative;
}
.custom-dropdown.active .dropdown-options {
  display: block;
}
.custom-dropdown .dropdown-options .dropdown-option {
  padding:5px;
}
.dropdown-options {
  display: none;
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  position: absolute;
  background: white;
  width: 100%;
  z-index: 100;
}
.dropdown-option {
  padding: 5px;
  cursor: pointer;
}
.dropdown-option.selected {
  background-color: #f0f0f0;
}
</style>
