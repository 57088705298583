<template>
  <div class="form-step active" data-step="step1">
    <div>
      <h1 class="heading-2">{{ $t('property_address') }}.</h1>

      <p class="font-size-medium">{{ $t('address_slogan') }}.</p>
    </div>

    <div class="form-group">
      <label for="address">{{ $t('address') }}</label>
      <input type="text" v-model="formData.address" :placeholder="`${$t('input_start_typing') } ${ $t('address') }..`" id="autocomplete" required/>
      <div id="addressErrorText" class="validation-error"></div>

      <div class="form-group">
        <label for="city">{{ $t('city') }}</label>
        <input type="text" v-model="formData.city" :placeholder="`${$t('input_start_typing') } ${ $t('city') }..`" id="city" required/>
        <div id="cityErrorText" class="validation-error"></div>
      </div>

      <div class="form-group flex-container flex-col-2">
        <div>
          <label for="province">{{ $t('province') }}</label>
          <input type="text" name="province" id="state" :placeholder="`${$t('input_start_typing') } ${ $t('province') }..`" v-model="formData.province" required/>
          <div id="stateErrorText" class="validation-error"></div>
        </div>

        <div>
          <label for="postalCode">{{ $t('postal_code') }}</label>
          <input type="text" v-model="formData.postal_code" :placeholder="`${$t('input_start_typing') } ${ $t('postal_code') }..`" id="zip" required/>
          <div id="zipErrorText" class="validation-error"></div>
        </div>
      </div>

      <div>
        <input type="hidden" id="latitude" readonly v-model="formData.latitude"/>
        <input type="hidden" id="longitude" readonly v-model="formData.longitude"/>
      </div>

      <div class="form-group">
        <div class="google_map mt-2">
          <div style="height: 400px;" id="map"></div>
        </div>

        <label class="mt-3">{{ formData.city }} {{ formData.province }}</label>

        <p class="text-faded">{{ $t('map_slogan') }}.</p>
      </div>
    </div>

    <div class="btns-container">
      <button type="button" class="primary-btn next-step" :class="{'btn-disabled': !isValid}" :disabled="!isValid" @click="handleNext">
        {{ $t('next_button') }}
      </button>
    </div>
  </div>
</template>

<script>
// do not remove /* global google */
/* global google */
import { mapState, mapActions } from 'pinia';
import { createListingStore } from '@/stores/host/listing/createListingStore';
import stepNavigationMixin from "@/mixins/stepNavigationMixin";
import { Loader } from '@googlemaps/js-api-loader';
export default {
  name: 'StepOne',
  mixins: [stepNavigationMixin],
  inject: ['navigationState'],
  data() {
    return {
      map: null,
      marker: null,
      autocomplete: null,
      geocoder: null,
    };
  },
  computed: {
    ...mapState(createListingStore, ['formData']),
    isValid() {
      return (
          this.formData.address.trim() !== '' &&
          this.formData.city.trim() !== '' &&
          this.formData.province.trim() !== '' &&
          this.formData.postal_code.trim() !== '' &&
          this.formData.latitude !== '' &&
          this.formData.longitude !== ''
      );
    },
  },
  methods: {
    ...mapActions(createListingStore, ['saveDraft']),

    async handleNext() {
      try {
        console.log('Navigation State:', this.navigationState); // Debugging line
        if (!this.navigationState) {
          throw new Error('navigationState is null');
        }

        // Save the draft and await the response
        const response = await this.saveDraft(this.navigationState);

        // Check if a new property was created and an ID exists
        if (response.property && response.property.property_id) {

          // Update the property_id in formData
          this.formData.property_id = response.property.property_id;

          // Redirect to the EditListing route using params
          this.$router.replace({
            name: 'EditListing',
            params: {id: response.property.property_id},
          });

        }

        // Proceed to the next step in the form
        this.nextStep();
      } catch (error) {
        // Handle errors (e.g., show a notification to the user)
        console.error('Error saving draft:', error);
        // Optionally, display an error message to the user using a notification system
        // this.$toast.error('Failed to save the draft. Please try again.');
      }
    },
    initAutocomplete() {
      const input = document.getElementById('autocomplete');
      const options = {
        types: ['address'],
      };

      this.autocomplete = new google.maps.places.Autocomplete(input, options);
      this.autocomplete.addListener('place_changed', this.onPlaceChanged);
    },
    onPlaceChanged() {
      const place = this.autocomplete.getPlace();

      if (!place.geometry) {
        window.alert("No details available for input: '" + place.name + "'");
        return;
      }

      // Update formData directly
      this.formData.address = place.formatted_address || '';
      this.formData.city = '';
      this.formData.province = '';
      this.formData.postal_code = '';
      this.formData.latitude = '';
      this.formData.longitude = '';

      for (const component of place.address_components) {
        const types = component.types;
        if (types.includes('locality')) {
          this.formData.city = component.long_name;
        }
        if (types.includes('administrative_area_level_1')) {
          this.formData.province = component.short_name;
        }
        if (types.includes('postal_code')) {
          this.formData.postal_code = component.long_name;
        }
      }

      if (place.geometry && place.geometry.location) {
        const location = place.geometry.location;
        this.formData.latitude = location.lat();
        this.formData.longitude = location.lng();

        this.updateMap(location);
      }
    },
    initMap() {
      const mapElement = document.getElementById('map');
      this.map = new google.maps.Map(mapElement, {
        center: { lat: 43.6532, lng: -79.3832 }, // Default to Toronto
        zoom: 12,
      });
      this.marker = new google.maps.Marker({
        map: this.map,
      });
    },
    updateMap(location) {
      this.map.setCenter(location);
      this.map.setZoom(16);
      this.marker.setPosition(location);
    },
    getCurrentLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            (position) => {
              const lat = position.coords.latitude;
              const lng = position.coords.longitude;
              this.formData.latitude = lat;
              this.formData.longitude = lng;

              this.geocoder.geocode(
                  { location: { lat, lng } },
                  (results, status) => {
                    if (status === 'OK' && results[0]) {
                      const place = results[0];
                      this.formData.address = place.formatted_address;

                      for (const component of place.address_components) {
                        const types = component.types;
                        if (types.includes('locality')) {
                          this.formData.city = component.long_name;
                        }
                        if (types.includes('administrative_area_level_1')) {
                          this.formData.province = component.short_name;
                        }
                        if (types.includes('postal_code')) {
                          this.formData.postal_code = component.long_name;
                        }
                      }

                      const location = place.geometry.location;
                      this.updateMap(location);
                    } else {
                      console.error('Geocoder failed due to:', status);
                    }
                  }
              );
            },
            (error) => {
              console.error('Error fetching location:', error);
            }
        );
      }
    },
  },
  mounted() {
    const loader = new Loader({
      apiKey: 'AIzaSyCCfSrPTp0wnNNwR9cEXPO8OZXpAaXZkZ8',
      libraries: ['places'],
    });

    loader.load().then(() => {
      this.initMap();
      this.initAutocomplete();
      this.geocoder = new google.maps.Geocoder();
      this.getCurrentLocation(); // Fetch current location on mount
    });
  },
};
</script>
