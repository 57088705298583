// createListingStore.js
import { defineStore } from 'pinia';
import http from "@/http";

export const createListingStore = defineStore('form', {
    state: () => ({
        formData: {
            uploaded_photos: [],


            //
            address: '',
            city: '',
            province: '',
            postal_code: '',
            latitude: '',
            longitude: '',
            property_type: '',
            space_type: '',
            preferred_host: [],
            space_size: '',
            furnishing: '',
            bed_types: [],
            bedroom_furnishings: [],
            amenities: [],
            additional_amenities: [],
            bathroom: [],
            number_people: 1,
            number_pets: 1,
            bathroom_occupants: 1,
            shared_spaces: [],
            occupants: [],
            renter_provisions: [],
            safety_features: [],
            facilities: [],
            additional_facilities: [],


            photos: [],
            allows_pets: '',
            allowed_pet_types: [],
            house_rules: [],
            additional_house_rules: [],
            title: '',
            description: '',
            price: 0,
            utilities_included: true,
            owner_pets: [],
            pet_photos: [],
            available_from: null,
            preferred_stay_lengths: [],

            status: 'draft',
            property_id: null,

            listing_step: null,
        },

    }),
    actions: {
        updateFormData(newData) {
            Object.assign(this.formData, newData);
        },
        async saveDraft(navigationState) {
            // Track where the user is at, so we can lead them to the step in question if they return later ;)
            this.formData.listing_step = navigationState?.currentSubStep;

            // Ensure 'status' is set to 'draft' in formData
            this.formData.status = 'draft';

            const formData = new FormData();

            function isPlainObject(obj) {
                return Object.prototype.toString.call(obj) === '[object Object]';
            }

            // Fields that should always be serialized to JSON
            const jsonFields = ['house_rules', 'additional_house_rules'];

            // Arrays to hold new photos and identifiers of uploaded photos
            const newPhotos = [];
            const uploadedPhotoIds = [];

            // Process 'photos' field separately
            if (this.formData.photos && this.formData.photos.length > 0) {
                this.formData.photos.forEach((fileData) => {
                    if (!fileData.isUploaded) {
                        // Photo hasn't been uploaded yet
                        const file = this.dataURLtoFile(fileData.originalDataURL, fileData.name);
                        if (file) {
                            formData.append('photos[]', file);
                            newPhotos.push(fileData);
                        }
                    } else {
                        // Photo has already been uploaded
                        uploadedPhotoIds.push(fileData.serverId);
                    }
                });
            }

            // Include the identifiers of already uploaded photos
            // Always include 'uploaded_photo_ids[]' field, even if empty
            if (uploadedPhotoIds.length > 0) {
                uploadedPhotoIds.forEach((id) => {
                    formData.append('uploaded_photo_ids[]', id);
                });
            } else {
                // Include an empty 'uploaded_photo_ids[]' field
                formData.append('uploaded_photo_ids[]', '');
            }

            // Process 'pet_photos' similarly if needed
            if (this.formData.pet_photos && this.formData.pet_photos.length > 0) {
                this.formData.pet_photos.forEach((fileData) => {
                    const file = this.dataURLtoFile(fileData.originalDataURL, fileData.name);
                    if (file) {
                        formData.append('pet_photos[]', file);
                    }
                });
            }

            // Process other form data
            for (const key in this.formData) {
                const value = this.formData[key];

                // Skip 'property_id' if it's null or undefined
                if (key === 'property_id' && (value === null || value === undefined || value === 'null')) {
                    continue;
                }

                // Skip 'photos' and 'pet_photos' as they're already processed
                if (key === 'photos' || key === 'pet_photos') {
                    continue;
                }

                if (jsonFields.includes(key)) {
                    // For fields that should be JSON serialized
                    formData.append(key, JSON.stringify(value));
                } else if (Array.isArray(value)) {
                    if (value.length > 0) {
                        if (value.every(item => isPlainObject(item))) {
                            // For arrays of plain objects, serialize to JSON
                            formData.append(key, JSON.stringify(value));
                        } else {
                            // For arrays of primitives (strings, numbers)
                            value.forEach((item) => {
                                if (item !== null && item !== undefined && item !== '') {
                                    formData.append(`${key}[]`, item);
                                }
                            });
                        }
                    } else {
                        // Do not append anything for empty arrays
                        // Remove or comment out the line below
                        // formData.append(`${key}[]`, '');
                    }
                } else if (value instanceof File) {
                    // For single files
                    // formData.append(key, value);
                } else if (typeof value === 'object' && value !== null) {
                    // If the value is a plain object, serialize it to JSON
                    if (isPlainObject(value)) {
                        formData.append(key, JSON.stringify(value));
                    } else {
                        // Handle other object types (e.g., Date)
                        formData.append(key, value.toString());
                    }
                } else {
                    formData.append(key, value !== null ? value : '');
                }
            }

            // Determine if this is an update or create operation
            const method = 'post'; // Always use 'post' for multipart form data
            const isUpdate = this.formData.property_id && this.formData.property_id !== null;
            const url = isUpdate ? `/api/properties/${this.formData.property_id}` : '/api/properties';

            // If updating, append '_method' as 'PUT' for method spoofing
            if (isUpdate) {
                formData.append('_method', 'PUT');
            }

            try {
                const response = await http({
                    method,
                    url,
                    data: formData,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                // Update isUploaded flag and serverId for new photos
                if (response.data.uploadedPhotoIds) {
                    newPhotos.forEach((fileData, index) => {
                        fileData.isUploaded = true;
                        fileData.serverId = response.data.uploadedPhotoIds[index];
                    });

                    // Update formData with the updated photo information
                    this.updateFormData({ photos: this.formData.photos });
                }

                // ... existing code ...

                return response.data; // Return the response data
            } catch (error) {
                // Handle errors
                console.error(
                    'Error saving draft:',
                    error.response ? error.response.data : error
                );
                throw error;
            }
        },
        dataURLtoFile(dataurl, filename) {
            const arr = dataurl.split(',');
            const mimeMatch = arr[0].match(/:(.*?);/);
            const mime = mimeMatch ? mimeMatch[1] : '';
            const bstr = atob(arr[1]);
            let n = bstr.length;
            const u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }

            return new File([u8arr], filename, { type: mime });
        }
    },

});
