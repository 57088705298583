<template>
  <nav aria-label="Main Navigation">
    <div class="flex-container">
      <a href="https://app.happipad.net" class="logo">
        <img src="../assets/svgs/Happipad_Logo_Dark.svg" alt="Happipad Logo" />
      </a>

      <div class="right-menu">
        <!-- Language Selector Dropdown -->
        <div class="dropdown">
          <button class="dropbtn">
            <img :src="currentFlag" alt="Current Language" />
            <span>{{ currentLanguageName }}</span>
          </button>
          <div class="dropdown-content">
            <a href="#" @click.prevent="changeLanguage('en')">
              <img src="https://flagcdn.com/w20/gb.png" alt="English Flag" /> English
            </a>
            <a href="#" @click.prevent="changeLanguage('fr')">
              <img src="https://flagcdn.com/w20/fr.png" alt="French Flag" /> Français
            </a>
            <a href="#" @click.prevent="changeLanguage('es')">
              <img src="https://flagcdn.com/w20/es.png" alt="Spain Flag" /> Espagnol
            </a>
            <a href="#" @click.prevent="changeLanguage('ht')">
              <img src="https://flagcdn.com/w20/ht.png" alt="Creole Flag" /> Kreyòl
            </a>
          </div>
        </div>

        <!-- Help 24/7 Button -->
        <a href="#" class="tertiary-btn">{{ $t('help') }} <strong>24/7</strong></a>
      </div>
    </div>
  </nav>
</template>


<script>
export default {
  name: 'AppHeader',
  data() {
    return {
      languages: {
        en: {
          name: 'English',
          flag: 'https://flagcdn.com/w20/gb.png',
        },
        fr: {
          name: 'Français',
          flag: 'https://flagcdn.com/w20/fr.png',
        },
        es: {
          name: 'Espagnol',
          flag: 'https://flagcdn.com/w20/es.png',
        },
        ht: {
          name: 'Kreyòl',
          flag: 'https://flagcdn.com/w20/ht.png',
        },
      },
    };
  },
  computed: {
    currentLanguage() {
      return this.$i18n.locale;
    },
    currentLanguageName() {
      return this.languages[this.currentLanguage].name;
    },
    currentFlag() {
      return this.languages[this.currentLanguage].flag;
    },
  },
  methods: {
    changeLanguage(lang) {
      this.$i18n.locale = lang;
    },
  },
};
</script>


<style scoped>
/* Existing styles */
.tertiary-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  min-width: 120px;
  height: 56px;
  padding: 0 0.7rem;
  text-align: center;
  color: #4969AF;
  text-decoration: none;
  background-color: #F2F6FA;
  border: 1px solid #F2F6FA;
  font-family: "NexaBold", sans-serif;
  border-radius: 70px;
  box-sizing: border-box;
  transition: all 0.2s;
  cursor: pointer;
}

header, hgroup, menu, nav, section {
  display: block;
}

.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1320px;
  margin: auto;
  padding: 1rem;
}

/* New styles */
.right-menu {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 120px;
  height: 56px;
  padding: 0 0.7rem;
  background-color: #F2F6FA;
  border: 1px solid #F2F6FA;
  border-radius: 70px;
  color: #4969AF;
  font-family: "NexaBold", sans-serif;
  font-size: 16px;
  cursor: pointer;
}

.dropbtn img {
  width: 24px;
  height: auto;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: #F2F6FA;
  min-width: 160px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: #4969AF;
  padding: 12px 16px;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.dropdown-content a img {
  width: 24px;
  height: auto;
  margin-right: 8px;
}

.dropdown-content a:hover {
  background-color: #e1e5e9;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #e1e5e9;
}

</style>
