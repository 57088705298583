export default {
    methods: {
        /**
         * Increments the value of the specified attribute path by 1.
         * @param {string} attributePath - The dot-separated path to the attribute (e.g., 'formData.number_pets').
         */
        incrementAttribute(attributePath) {
            const currentValue = this.getAttributeByPath(attributePath);

            if (typeof currentValue === 'number') {
                this.setAttributeByPath(attributePath, currentValue + 1);
            } else {
                console.warn(`Cannot increment. Attribute '${attributePath}' is not a number or does not exist.`);
            }
        },

        /**
         * Decrements the value of the specified attribute path by 1.
         * Ensures the value does not go below zero.
         * @param {string} attributePath - The dot-separated path to the attribute (e.g., 'formData.number_pets').
         */
        decrementAttribute(attributePath) {
            const currentValue = this.getAttributeByPath(attributePath);

            if (typeof currentValue === 'number') {
                if (currentValue > 0) {
                    this.setAttributeByPath(attributePath, currentValue - 1);
                } else {
                    console.warn(`Cannot decrement. Attribute '${attributePath}' is already at zero.`);
                }
            } else {
                console.warn(`Cannot decrement. Attribute '${attributePath}' is not a number or does not exist.`);
            }
        },

        /**
         * Retrieves the value of a nested attribute using a dot-separated path.
         * @param {string} path - The dot-separated path to the attribute (e.g., 'formData.number_pets').
         * @returns {*} - The value of the attribute or undefined if it doesn't exist.
         */
        getAttributeByPath(path) {
            return path.split('.').reduce((prev, curr) => (prev ? prev[curr] : undefined), this);
        },

        /**
         * Sets the value of a nested attribute using a dot-separated path.
         * Ensures the attribute exists before setting its value.
         * @param {string} path - The dot-separated path to the attribute (e.g., 'formData.number_pets').
         * @param {*} value - The value to set.
         */
        setAttributeByPath(path, value) {
            const keys = path.split('.');
            const lastKey = keys.pop();
            const target = keys.reduce((prev, curr) => (prev ? prev[curr] : undefined), this);

            if (target && Object.prototype.hasOwnProperty.call(target, lastKey)) {
                // Direct assignment for Vue 3 reactivity
                target[lastKey] = value;
            } else {
                console.warn(`Unable to set value for attribute '${path}'.`);
            }
        },
    },
};
