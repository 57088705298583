<template>
  <div class="form-step active" data-step="2-8">
    <div>
      <h2 class="heading-2">Who lives at your space?</h2>
      <p class="font-size-medium">Select all that applies.</p>
    </div>

    <div class="form-group">
      <div class="flex-container custom-checkbox-container" role="group" aria-labelledby="who-lives-label">
        <div class="checkbox-container">
          <input type="checkbox" id="me" v-model="formData.occupants" value="me">
          <label for="me">Me</label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="my-partner" v-model="formData.occupants" value="partner_and_spouse">
          <label for="my-partner">My partner/spouse</label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="my-children" v-model="formData.occupants" value="my_children_and_relatives">
          <label for="my-children">My children/other relatives</label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="tenants" v-model="formData.occupants" value="tenants">
          <label for="tenants">Tenants</label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="pets" v-model="formData.occupants" value="pets">
          <label for="pets">Pets</label>
        </div>
      </div>

      <div class="form-group">
        <label class="heading-6" for="number-of-people">How many people in total live at your place?</label>
        <input type="number" min="0" v-model="formData.number_people" id="number-of-people">
      </div>

      <div class="form-group mt-4" :class="{ hidden: !hasPets }">
        <div>
          <h3 class="heading-6">What pets do you have?</h3>
          <p class="font-size-medium">Select all that applies.</p>
        </div>

        <div class="flex-container custom-checkbox-container" role="group" aria-labelledby="pets-selection-label">
          <div class="checkbox-container">
            <input type="checkbox" id="fish" v-model="formData.owner_pets" value="fish">
            <label for="fish">Fish</label>
          </div>

          <div class="checkbox-container">
            <input type="checkbox" id="cat" v-model="formData.owner_pets" value="cat">
            <label for="cat">Cat</label>
          </div>

          <div class="checkbox-container">
            <input type="checkbox" id="dog" v-model="formData.owner_pets" value="dog">
            <label for="dog">Dog</label>
          </div>

          <div class="checkbox-container">
            <input type="checkbox" id="parrot" v-model="formData.owner_pets" value="parrot">
            <label for="parrot">Parrot</label>
          </div>

          <div class="checkbox-container">
            <input type="checkbox" id="other-pets" v-model="formData.owner_pets" value="other">
            <label for="other-pets">Other</label>
          </div>
        </div>

        <div :class="{ hidden: !hasPets }">
          <div class="form-group" id="quantity-container">
            <label class="heading-6 mb-3" for="quantity">How many pets do you have?</label>

            <div class="input-number-container">
              <button type="button" class="decrement" aria-label="Decrease value" @click="decrement()">-</button>
              <input type="number" v-model="formData.number_pets" value="1" min="0" max="100" aria-label="Quantity">
              <button type="button" class="increment" aria-label="Increase value" @click="increment()">+</button>
            </div>
          </div>

<!--          <div class="form-group">-->
<!--            <div class="upload-photo-container" tabindex="0" role="button" aria-label="Add pet's photo">-->
<!--              <input type="file" id="pet_photos" name="pet_photos" class="photo-input" @change="handlePetPhotoUpload" multiple hidden>-->

<!--              <label for="pet_photos" class="photo-label">-->
<!--                <div class="photo-icon-container">-->
<!--                  <img src="@/assets/svgs/plus.svg" alt="Add photo icon" class="photo-icon">-->
<!--                  <p><strong>Add pet’s photo</strong> <span>(optional)</span></p>-->
<!--                </div>-->
<!--              </label>-->
<!--            </div>-->
<!--          </div>-->


        </div>
      </div>
    </div>

    <div class="btns-container">
      <button type="button" class="secondary-btn prev-step" @click="prevSubStep">Back</button>
      <button type="button" class="primary-btn next-step" @click="nextSubStep" :class="{'btn-disabled': !isValid}" :disabled="!isValid">Next</button>
    </div>
  </div>
</template>


<style scoped>
.hidden {
  display: none;
}
</style>

<script>
import {mapState, mapActions} from "pinia";
import { createListingStore } from "@/stores/host/listing/createListingStore";
import stepNavigationMixin from "@/mixins/stepNavigationMixin";
import attributeIncrementMixin from "@/mixins/attributeIncrementMixin";

export default {
  name: "SubStep2_8",
  mixins: [stepNavigationMixin, attributeIncrementMixin],
  inject: ['navigationState'],
  data() {
    return {
      petPhotoPreviews: [],
    };
  },
  computed: {
    ...mapState(createListingStore, ['formData']),
    hasPets() {
      return this.formData.occupants.includes("pets");
    },
    isValid() {
      if (this.hasPets && this.formData.owner_pets.length === 0) {
        return false;
      }
      return this.formData.occupants.length > 0;
    },
  },
  methods: {
    ...mapActions(createListingStore, ['saveDraft']),
    increment() {
      this.incrementAttribute('formData.number_pets');
    },
    decrement() {
      this.decrementAttribute('formData.number_pets');
    },
    nextSubStep() {
      this.saveDraft(this.navigationState);
      // to avoid duplication while submiting the next step
      this.formData.pet_photos = [];
      // Emit an event to the parent component
      this.nextStep(); // Directly call the store's action
    },
    prevSubStep() {
      // Emit an event to the parent component
      this.prevStep(); // Directly call the store's action
    },
    handlePetPhotoUpload(event) {
      const files = Array.from(event.target.files);
      this.formData.pet_photos = files;

      // Optionally, if you need previews, store them separately
      files.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          // Store previews separately if needed
          this.petPhotoPreviews.push(e.target.result);
        };
        reader.readAsDataURL(file);
      });
    },
    removePetPhoto(index) {
      this.formData.pet_photos.splice(index, 1);
    },
  },
  mounted() {
    if (!this.formData.pet_photos) {
      this.formData.pet_photos = [];
    }
  },
};
</script>
