<template>
  <div class="form-step active">
    <div>
      <h2 class="heading-2">Do you have any requirements due to disability or condition?  (optional)</h2>

      <p class="font-size-medium">Select what applies.</p>
    </div>

    <div class="form-group">
      <div class="flex-container custom-radio-container" role="radiogroup" aria-labelledby="disability-label">
        <div class="radio-container">
          <input type="radio" id="no-disability" v-model="formData.host_requirements_disability" value="no" aria-labelledby="no-disability-label">

          <label for="no-disability" id="no-disability-label">
            No
          </label>
        </div>

        <div class="radio-container">
          <input type="radio" id="yes-disability" v-model="formData.host_requirements_disability" value="Yes" aria-labelledby="yes-disability-label">

          <label for="yes-disability" id="yes-disability-label">
            Yes
          </label>
        </div>
      </div>
    </div>

    <div class="form-group" style="display:none;" id="disability-container">
      <label class="heading-6" for="disability-description">Please explain</label>

      <input type="text" name="disability-description" placeholder="Start typing.." id="disability-description">
    </div>

    <div class="btns-container">
      <button type="button" class="secondary-btn prev-step" @click.prevent="prevSubStep">Back</button>
      <button type="button" class="primary-btn next-step" @click.prevent="nextSubStep">Next</button>
    </div>
  </div>
</template>


<script>
import stepNavigationMixin from "@/mixins/stepNavigationMixin";
import {useCreateHostProfile} from "@/stores/host/profile/useCreateHostProfile";
import {mapState} from "pinia";

export default {
  name: "SubStep2_6",
  mixins: [stepNavigationMixin],
  inject: ['navigationState'],
  computed: {
    ...mapState(useCreateHostProfile, ['formData'])
  },
  methods: {
    nextSubStep() {
      this.nextStep();
    },
    prevSubStep() {
      this.prevStep();
    }
  }
}
</script>


<style scoped>

</style>