<template>
  <div class="form-step active" data-step="2-2">
    <div>
      <h2 class="heading-2">What type of space are you renting out?</h2>

      <p class="font-size-medium">Select one option.</p>
    </div>

    <div class="form-group">
      <div class="flex-container custom-radio-container" role="radiogroup" aria-labelledby="type-of-space-label">
        <div class="radio-container radio-container-with-icon">
          <input type="radio" id="shared-room" v-model="formData.space_type" value="bed_in_shared_room" aria-labelledby="shared-room-label">

          <label for="shared-room" id="shared-room-label">
            <img src="../../../../assets/svgs/shared-room.svg" alt="shared room">

            <div class="label-content">
              <strong>Bed in a shared room</strong>

              <span>A space in a room with shares amenities like bathroom.</span>
            </div>
          </label>
        </div>

        <div class="radio-container radio-container-with-icon">
          <input type="radio" id="private-room" v-model="formData.space_type" value="private_room" aria-labelledby="private-room-label">

          <label for="shared-room" id="private-room-label">
            <img src="../../../../assets/svgs/private-room.svg" alt="shared room">

            <div class="label-content">
              <strong>Private room</strong>

              <span>A separate room with shares amenities.</span>
            </div>
          </label>
        </div>

        <div class="radio-container radio-container-with-icon">
          <input type="radio" id="separate-unit" v-model="formData.space_type" value="separate_unit" aria-labelledby="separate-unit-label">

          <label for="separate-unit" id="separate-unit-label">
            <img src="../../../../assets/svgs/separate-unit.svg" alt="separate unit">

            <div class="label-content">
              <strong>Separate unit</strong>

              <span>A unit in a house with its own amenities.</span>
            </div>
          </label>
        </div>

        <div class="radio-container radio-container-with-icon">
          <input type="radio" id="entire-space" v-model="formData.space_type" value="entire_space" aria-labelledby="entire-space-label">

          <label for="entire-space" id="entire-space-label">
            <img src="../../../../assets/svgs/entire-space.svg" alt="entire space">
            <div class="label-content">
              <strong>Entire space</strong>

              <span>Tenants will be able to use all place.</span>
            </div>
          </label>
        </div>
      </div>

      <div id="typeOfSpaceErrorText" class="validation-error"></div>
    </div>

    <div class="btns-container">
      <button type="button" class="secondary-btn prev-step" @click="prevSubStep">Back</button>
      <button type="button" class="primary-btn next-step" @click="nextSubStep" :class="{'btn-disabled': !isValid}" :disabled="!isValid">Next</button>
    </div>
  </div>
</template>

<style scoped>

</style>
<script>
import {mapActions, mapState} from "pinia";
import {createListingStore} from "@/stores/host/listing/createListingStore";
import stepNavigationMixin from "@/mixins/stepNavigationMixin";


export default {
  name: "SubStep2_2",
  mixins: [stepNavigationMixin],
  inject: ['navigationState'],
  computed: {
    ...mapState(createListingStore, ['formData']),
    isValid() {
      return (
          this.formData.space_type !== ''
      );
    }
  },
  methods: {
    ...mapActions(createListingStore, ['saveDraft']),
    nextSubStep() {
      this.saveDraft(this.navigationState);
      this.nextStep(); // Directly call the store's action
    },
    prevSubStep() {
      // Emit an event to the parent component
      this.prevStep(); // Directly call the store's action
    },
  }
}
</script>