<template>
  <div class="form-step active">
    <div>
      <h2 class="heading-2">How often do you cook?  (optional)</h2>

      <p class="font-size-medium">Select what applies.</p>
    </div>

    <div class="form-group">
      <div class="flex-container custom-radio-container" role="radiogroup" aria-labelledby="cooking-label">
        <div class="radio-container">
          <input type="radio" id="never" v-model="formData.host_cooking_frequency" value="never" aria-labelledby="never-label">

          <label for="never" id="never-label">
            Never
          </label>
        </div>

        <div class="radio-container">
          <input type="radio" id="one-two-times-week" v-model="formData.host_cooking_frequency" value="1-2 per week" aria-labelledby="one-two-times-week-label">

          <label for="one-two-times-week" id="one-two-times-week-label">
            1-2 per week
          </label>
        </div>

        <div class="radio-container">
          <input type="radio" id="three-four-month" v-model="formData.host_cooking_frequency" value="3-4 per week" aria-labelledby="three-four-month-label">

          <label for="three-four-month" id="three-four-month-label">
            3-4 per week
          </label>
        </div>

        <div class="radio-container">
          <input type="radio" id="daily" v-model="formData.host_cooking_frequency" value="daily" aria-labelledby="daily-label">

          <label for="daily" id="daily-label">
            Daily
          </label>
        </div>
      </div>
    </div>

    <div class="btns-container">
      <button type="button" class="secondary-btn prev-step" @click.prevent="prevSubStep">Back</button>

      <button type="button" class="primary-btn next-step" @click.prevent="nextSubStep">Next</button>
    </div>
  </div>
</template>


<script>
import {mapState} from "pinia";
import stepNavigationMixin from "@/mixins/stepNavigationMixin";
import {useCreateHostProfile} from "@/stores/host/profile/useCreateHostProfile";

export default {
  name: "SubStep2_4",
  mixins: [stepNavigationMixin],
  inject: ['navigationState'],
  computed: {
    ...mapState(useCreateHostProfile, ['formData'])
  },
  methods: {
    nextSubStep() {
      this.nextStep();
    },
    prevSubStep() {
      this.prevStep();
    }
  }
}
</script>


<style scoped>

</style>