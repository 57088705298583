<template>
  <SubStep3_1 v-if="navigationState.currentSubStep === '3.1'"/>
  <SubStep3_2 v-if="navigationState.currentSubStep === '3.2'"/>
</template>

<script>
import SubStep3_1 from "@/views/host/profile/Step3/SubStep3_1.vue";
import SubStep3_2 from "@/views/host/profile/Step3/SubStep3_2.vue";
import stepNavigationMixin from "@/mixins/stepNavigationMixin";

export default {
  name: "StepThree",
  inject: ['navigationState'],
  mixins: [stepNavigationMixin],
  components: {SubStep3_1, SubStep3_2},
}
</script>

<style scoped>

</style>