import { defineStore } from 'pinia';

export const useCreateHostProfile = defineStore('form', {
    state: () => ({
        formData: {
            host_gender: '',
            host_preferred_gender: '',
            host_languages: [],
            host_noise_tolerance: '',
            host_schedule: [],
            host_visit_frequency: '',
            host_cooking_frequency: '',
            host_allergies: [],
            host_requirements_disability: '',
            host_hobbies: [],
            host_cleaning_frequency: '',
            host_photo: [],
            host_description: ''
        }
    }),
});