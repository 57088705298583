<template>
  <div class="form-step active">
    <div>
      <h2 class="heading-2">What languages do you speak? *</h2>

      <p class="font-size-medium">Select all that applies.</p>
    </div>

    <div class="form-group" data-group="languages-group">
      <div class="flex-container custom-checkbox-container" role="group" aria-labelledby="languages-label">
        <div class="checkbox-container">
          <input type="checkbox" id="english" v-model="formData.host_languages" value="english" aria-labelledby="english-label">

          <label for="english" id="english-label">
            <div class="label-content">English</div>
          </label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="french" v-model="formData.host_languages" value="french" aria-labelledby="french-label">

          <label for="french" id="french-label">
            <div class="label-content">French</div>
          </label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="filipino" v-model="formData.host_languages" value="filipino" aria-labelledby="filipino-label">

          <label for="filipino" id="filipino-label">
            <div class="label-content">Filipino</div>
          </label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="spanish" v-model="formData.host_languages" value="spanish" aria-labelledby="spanish-label">

          <label for="spanish" id="spanish-label">
            <div class="label-content">Spanish</div>
          </label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="hindi" v-model="formData.host_languages" value="hindi" aria-labelledby="hindi-label">

          <label for="hindi" id="hindi-label">
            <div class="label-content">Hindi</div>
          </label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="german" v-model="formData.host_languages" value="german" aria-labelledby="german-label">

          <label for="german" id="german-label">
            <div class="label-content">German</div>
          </label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="chinese" v-model="formData.host_languages" value="chinese" aria-labelledby="chinese-label">

          <label for="chinese" id="chinese-label">
            <div class="label-content">Chinese</div>
          </label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="punjabi" v-model="formData.host_languages" value="punjabi" aria-labelledby="punjabi-label">

          <label for="punjabi" id="punjabi-label">
            <div class="label-content">Punjabi</div>
          </label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="japanese" v-model="formData.host_languages" value="japanese" aria-labelledby="japanese-label">

          <label for="japanese" id="japanese-label">
            <div class="label-content">Japanese</div>
          </label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="russian" v-model="formData.host_languages" value="russian" aria-labelledby="russian-label">

          <label for="russian" id="russian-label">
            <div class="label-content">Russian</div>
          </label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="farsi" v-model="formData.host_languages" value="farsi" aria-labelledby="farsi-label">

          <label for="farsi" id="farsi-label">
            <div class="label-content">Farsi</div>
          </label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="arabic" v-model="formData.host_languages" value="arabic" aria-labelledby="arabic-label">

          <label for="arabic" id="arabic-label">
            <div class="label-content">Arabic</div>
          </label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="portuguese" v-model="formData.host_languages" value="portuguese" aria-labelledby="portuguese-label">

          <label for="portuguese" id="portuguese-label">
            <div class="label-content">Portuguese</div>
          </label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="korean" v-model="formData.host_languages" value="korean" aria-labelledby="korean-label">

          <label for="korean" id="korean-label">
            <div class="label-content">Korean</div>
          </label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="malay" v-model="formData.host_languages" value="malay" aria-labelledby="malay-label">

          <label for="malay" id="malay-label">
            <div class="label-content">Malay</div>
          </label>
        </div>
      </div>

      <div id="additional-lang"></div>

      <div class="add-lang-container add-container" tabindex="0" role="button" aria-label="Add other language">
        <img src="@/assets/svgs/plus.svg" alt="Add icon">

        <p><strong>Add other language </strong></p>
      </div>

      <div id="langErrorText" class="validation-error"></div>
    </div>

    <div class="btns-container">
      <button type="button" class="secondary-btn prev-step" @click.prevent="prevSubStep">Back</button>

      <button type="button" class="primary-btn next-step" @click.prevent="nextSubStep">Next</button>
    </div>
  </div>
</template>


<script>
import stepNavigationMixin from "@/mixins/stepNavigationMixin";
import { useCreateHostProfile } from "@/stores/host/profile/useCreateHostProfile";
import {mapState} from "pinia";

export default {
  name: "SubStep1_2",
  mixins: [stepNavigationMixin],
  inject: ['navigationState'],
  computed: {
    ...mapState(useCreateHostProfile, ['formData']),
  },
  methods: {
    nextSubStep() {
      this.nextStep();
    },
    prevSubStep() {
      this.prevStep();
    }
  }
}
</script>

<style scoped>

</style>