<template>
  <div class="container flex-container verify-email mb-6">
    <div class="content">
      <h1 class="heading-2">
        <slot name="title">Default Title</slot>
      </h1>
      <img class="mt-4" src="@/assets/svgs/all-set.svg" alt="Checkmark icon" width="160" height="160">
    </div>
    <p>
      <slot name="description"></slot>
    </p>
  </div>
</template>


<script>
export default {
  name: "SuccessMessageCard"
}
</script>

<style scoped>

</style>