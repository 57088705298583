<!--SubStep1_1.vue-->
<template>
  <div class="form-step active" data-step="2-1">
    <div>
      <h2 class="heading-2">How would you describe your home?</h2>

      <p class="font-size-medium">Select one option.</p>
    </div>

    <div class="form-group">
      <div class="flex-container custom-radio-container" role="radiogroup" aria-labelledby="home-type-label">
        <div class="radio-container radio-container-with-icon">
          <input type="radio" id="house" v-model="formData.property_type" value="house" aria-labelledby="house-label">

          <label for="house" id="house-label">
            <img src="../../../../assets/svgs/house.svg" alt="house"/>

            <div class="label-content">
              House
            </div>
          </label>
        </div>

        <div class="radio-container radio-container-with-icon">
          <input type="radio" id="mobile-home" v-model="formData.property_type" value="mobile_home" aria-labelledby="mobile-home-label">

          <label for="mobile-home" id="mobile-home-label">
            <img src="../../../../assets/svgs/mobile-home.svg" alt="mobile home"/>

            <div class="label-content">
              Mobile home
            </div>
          </label>
        </div>

        <div class="radio-container radio-container-with-icon">
          <input type="radio" id="apartment" v-model="formData.property_type" value="apartment" aria-labelledby="apartment-label">

          <label for="apartment" id="apartment-label">
            <img src="../../../../assets/svgs/apartment.svg" />

            <div class="label-content">
              Apartment/condo
            </div>
          </label>
        </div>

        <div class="radio-container radio-container-with-icon">
          <input type="radio" id="townhouse" v-model="formData.property_type" value="townhouse" aria-labelledby="townhouse-label">

          <label for="townhouse" id="townhouse-label">
            <img src="../../../../assets/svgs/townhouse.svg" />

            <div class="label-content">
              Townhouse/duplex
            </div>
          </label>
        </div>

        <div class="radio-container radio-container-with-icon">
          <input type="radio" id="other" v-model="formData.property_type" value="other" aria-labelledby="other-label">

          <label for="other" id="other-label">
            <img src="../../../../assets/svgs/other-house.svg" alt="other type"/>

            <div class="label-content">
              Other
            </div>
          </label>
        </div>
      </div>

      <div id="homeTypeErrorText" class="validation-error"></div>
    </div>

    <div class="btns-container">
      <button type="button" class="secondary-btn prev-step" @click="prevSubStep">Back</button>
      <button type="button" class="primary-btn next-step" @click="nextSubStep" :class="{'btn-disabled': !isValid}" :disabled="!isValid">Next</button>
    </div>

  </div>
</template>

<style scoped>

</style>
<script>
import { mapState, mapActions } from 'pinia';
import stepNavigationMixin from '@/mixins/stepNavigationMixin.js';
import { createListingStore } from '@/stores/host/listing/createListingStore';
export default {
  name: "SubStep2_1",
  mixins: [stepNavigationMixin],
  inject: ['navigationState'],
  computed: {
    ...mapState(createListingStore, ['formData']),
    isValid() {
      return (
          this.formData.property_type !== ''
      );
    }
  },
  methods: {
    ...mapActions(createListingStore, ['saveDraft']),
    nextSubStep() {
      // Emit an event to the parent component
      this.saveDraft(this.navigationState);
      this.nextStep(); // Directly call the store's action
    },
    prevSubStep() {
      // Emit an event to the parent component
      this.prevStep(); // Directly call the store's action
    },
  }
}
</script>