// stores/auth.js
import { defineStore } from 'pinia';
import http from '@/http';

export const useAuthStore = defineStore('auth', {
    state: () => ({
        user: JSON.parse(localStorage.getItem('user')) || null,
        token: localStorage.getItem('auth_token') || null,
    }),
    actions: {
        async login(email, password) {
            try {
                const response = await http.post('/api/auth/login', { email, password });
                this.token = response.data.token;
                this.user = response.data.user;

                localStorage.setItem('auth_token', this.token);
                localStorage.setItem('user', JSON.stringify(this.user));
            } catch (error) {
                console.log(error);
                throw error;
            }
        },
        logout() {
            this.token = null;
            this.user = null;

            localStorage.removeItem('auth_token');
            localStorage.removeItem('user');

            this.$router.push({ name: 'Login' });
        },
    },
});
