<template>
  <footer role="contentinfo">
    <div class="flex-container flex-column">
      <a href="#" class="logo">
        <img src="../assets/svgs/Happipad_Logo_Light.svg" alt="Happipad Logo" />
      </a>
      <p>
        Happipad.org is a Canadian federally incorporated nonprofit on a mission to make home sharing safe, affordable,
        and accessible to those who can benefit most.
      </p>
    </div>
    <div class="copyright">
      <p>Copyright &copy; 2024 happipad — All Rights Reserved.</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
};
</script>


