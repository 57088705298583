<template>
  <RenterDashboardTopNav />

  <div class="renter-dashboard">
    <div class="invoices container flex-container">
      <RenterDashboardSidebar />

      <div class="dashboard-content">
        <header class="mb-2">
          <h1 class="heading-2">Receipts</h1>

          <div class="edit-info hidden-mobile">
            <a href="#" class="edit-link">
              Export selected
            </a>

            <a href="#" class="edit-link">
              <img src="@/assets/svgs/download.svg" alt="download">
              Export as CSV
            </a>
          </div>

          <p class="hidden-desktop" id="table-title">Showing 3 of 3</p>
        </header>

        <div class="cards-container card">
          <!-- Table Section -->
          <section class="mb-4" aria-labelledby="table-title">
            <div class="mb-4 hidden-mobile">
              <p class="hidden-mobile" id="table-title">Showing 3 of 3</p>
            </div>

            <div class="edit-info mb-3 hidden-desktop">
              <a href="#" class="edit-link">
                <img src="@/assets/svgs/download.svg" alt="download">
                Export as CSV
              </a>
            </div>

            <div class="dashboard-table-container">
              <table>
                <thead>
                  <tr>
                    <th scope="col">
                      <div class="default-checkbox" tabindex="0">
                        <input type="checkbox" id="select-all" name="select-all" />

                        <label for="select-all"></label>
                      </div>
                    </th>
                    <th class="hidden-desktop" scope="col">Payment</th>
                    <th scope="col">Host</th>
                    <th scope="col">Payment</th>
                    <th scope="col">Date</th>
                    <th scope="col">Purpose</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td class="hidden-desktop">
                      <div data-label="Payment 6574937"></div>
                      <a href="#">
                        <img src="@/assets/svgs/eye-white.svg" alt="view">
                        View
                      </a>
                    </td>

                    <td class="hidden-mobile">
                      <div class="default-checkbox" tabindex="0">
                        <input type="checkbox" id="select-1" name="select-1" />

                        <label for="select-1"></label>
                      </div>
                    </td>

                    <td data-label="Host">
                      <div>
                        <img src="@/assets/images/user-placeholder.png" alt="Host profile" />
                        Jay Doe
                      </div>
                    </td>

                    <td data-label="Payment">$1300</td>

                    <td data-label="Date">
                      <div>
                        Apr 23, 2021
                      </div>
                    </td>

                    <td data-label="Purpose">
                      <div>
                        September Rent
                      </div>
                    </td>

                    <td data-label="Status">
                      <span class="status-badge status-badge--green" aria-label="Listing status: Paid" role="status">Paid</span>
                    </td>

                    <td class="hidden-mobile">
                      <div>
                        <button class="tertiary-btn" aria-label="details" @click="showModal=true">
                          <img src="@/assets/svgs/eye.svg" alt="" />
                        </button>

                        <a href="#" class="tertiary-btn">Download</a>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td class="hidden-desktop">
                      <div data-label="Payment 6574937"></div>
                      <a href="#">
                        <img src="@/assets/svgs/eye-white.svg" alt="view">
                        View
                      </a>
                    </td>

                    <td class="hidden-mobile">
                      <div class="default-checkbox" tabindex="0">
                        <input type="checkbox" id="select-2" name="select-2" />

                        <label for="select-2"></label>
                      </div>
                    </td>

                    <td data-label="Host">
                      <div>
                        <img src="@/assets/images/user-placeholder.png" alt="Host profile" />
                        Jay Doe
                      </div>
                    </td>

                    <td data-label="Payment">$1300</td>

                    <td data-label="Date">
                      <div>
                        Apr 23, 2021
                      </div>
                    </td>

                    <td data-label="Purpose">
                      <div>
                        Screening
                      </div>
                    </td>

                    <td data-label="Status">
                      <span class="status-badge status-badge--green" aria-label="Listing status: Paid" role="status">Paid</span>
                    </td>

                    <td class="hidden-mobile">
                      <div>
                        <button class="tertiary-btn" aria-label="details">
                          <img src="@/assets/svgs/eye.svg" alt="" />
                        </button>

                        <a href="#" class="tertiary-btn">Download</a>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td class="hidden-desktop">
                      <div data-label="Payment 6574937"></div>
                      <a href="#">
                        <img src="@/assets/svgs/eye-white.svg" alt="view">
                        View
                      </a>
                    </td>

                    <td class="hidden-mobile">
                      <div class="default-checkbox" tabindex="0">
                        <input type="checkbox" id="select-3" name="select-3" />

                        <label for="select-3"></label>
                      </div>
                    </td>

                    <td data-label="Host">
                      <div>
                        <img src="@/assets/images/user-placeholder.png" alt="Host profile" />
                        Jay Doe
                      </div>
                    </td>

                    <td data-label="Payment">$1300</td>

                    <td data-label="Date">
                      <div>
                        Apr 23, 2021
                      </div>
                    </td>

                    <td data-label="Purpose">
                      <div>
                        Deposit
                      </div>
                    </td>

                    <td data-label="Status">
                      <span class="status-badge status-badge--green" aria-label="Listing status: Paid" role="status">Paid</span>
                    </td>

                    <td class="hidden-mobile">
                      <div>
                        <button class="tertiary-btn" aria-label="details">
                          <img src="@/assets/svgs/eye.svg" alt="" />
                        </button>

                        <a href="#" class="tertiary-btn">Download</a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- we need to make this work similar to host dashboard functionality -->
            <a href="#" class="view-more mt-4">Show 6 more
              <img src="@/assets/svgs/chevron-down-blue.svg" alt="chevron down">
            </a>
          </section>

          <div class="listing-card listing-card--empty hidden text-align-center">
            <p>Hey Jay! No transactions to display.<br>
              If you have any transactions in the future they will be displayed here.</p>

            <img src="@/assets/svgs/no-transactions.svg" alt="">

            <a href="#" class="tertiary-btn">
              <img src="@/assets/svgs/document.svg" alt="document">
              Read Q & A
            </a>
          </div>
        </div>
      </div>

      <!-- Modal Popup -->
      <div v-if="showModal" class="modal-overlay" @click.self="closeModal">
        <div class="modal-container">
          <div class="modal-header">
            <p>Receipt</p>
            <button class="close-btn" @click="closeModal"><img src="@/assets/svgs/close-big.svg" alt="close" /></button>
          </div>

          <!-- Integrated receipt in Modal -->
          <div class="modal-content-container">
            <div class="modal-content-container__btns">
              <button class="secondary-btn">Download</button>
              <button class="primary-btn"><img src="@/assets/svgs/upload.svg" alt="upload"></button>
            </div>

            <h3 class="heading-5">Receipt 546565</h3>
          </div>
        </div>
      </div>

      <RenterDashboardBottomNav />
    </div>
  </div>
</template>

<script>
 import RenterDashboardSidebar from "@/components/RenterDashboardSidebar.vue";
 import RenterDashboardBottomNav from "@/components/RenterDashboardBottomNav.vue";
 import RenterDashboardTopNav from "@/components/RenterDashboardTopNav.vue";

 export default {
   name: "RenterNotificationControl",

   components: {
     RenterDashboardSidebar,
     RenterDashboardBottomNav,
     RenterDashboardTopNav
   },
   data() {
    return {
      showModal: false,
    };
  },
   methods: {
    openModal() {
      this.showModal = true;
      document.body.style.overflow = "hidden"; // Prevent background scroll
    },
    closeModal() {
      this.showModal = false;
      document.body.style.overflow = "";
    },
    addNewReference() {
      this.references.push({
        fullName: "",
        phoneNumber: "",
        email: "",
        relation: "",
      });
    },
    submitForm() {
      this.closeModal();
    },
  },
 };
</script>