<template>
  <div class="form-step active" data-step="2-5">
    <div>
      <h2 class="heading-2">What is available in the space?</h2>

      <p class="font-size-medium">Select one option.</p>
    </div>

    <div class="form-group">
      <div class="flex-container custom-radio-container" role="radiogroup" aria-labelledby="furnishing-label">
        <div class="radio-container">
          <input type="radio" id="fully-furnished" v-model="formData.furnishing" value="fully_furnished" aria-labelledby="fully-furnished-label">

          <label for="fully-furnished" id="fully-furnished-label">
            Fully furnished
          </label>
        </div>

        <div class="radio-container">
          <input type="radio" id="partly-furnished" v-model="formData.furnishing" value="partly_furnished" aria-labelledby="partly-furnished-label">

          <label for="partly-furnished" id="partly-furnished-label">
            Partly furnished
          </label>
        </div>

        <div class="radio-container">
          <input type="radio" id="unfurnished" v-model="formData.furnishing" value="unfurnished" aria-labelledby="unfurnished-label">

          <label for="unfurnished" id="unfurnished-label">
            Unfurnished
          </label>
        </div>

        <div id="furnishingErrorText" class="validation-error"></div>
      </div>

      <div class="form-group mt-5"  :class="{ hidden: !isPartlyFurnished }">
        <p class="heading-6">What bed is in the bedroom?</p>

        <p>Select if applies.</p>

        <div class="flex-container custom-checkbox-container mt-5" role="group" aria-labelledby="single-bed-label">
          <div class="checkbox-container">
            <input type="checkbox" id="single-bed" v-model="formData.bed_types" value="single">

            <label for="single-bed">
              <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 14V7.5C1 7.06902 1.17559 6.6557 1.48816 6.35095C1.80072 6.04621 2.22464 5.875 2.66667 5.875M2.66667 5.875H14.3333M2.66667 5.875V2.625C2.66667 2.19402 2.84226 1.7807 3.15482 1.47595C3.46738 1.1712 3.89131 1 4.33333 1H12.6667C13.1087 1 13.5326 1.1712 13.8452 1.47595C14.1577 1.7807 14.3333 2.19402 14.3333 2.625V5.875M14.3333 5.875C14.7754 5.875 15.1993 6.04621 15.5118 6.35095C15.8244 6.6557 16 7.06902 16 7.5V14M1 12.375H16" stroke="#3C3F4C" stroke-width="1.44444" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>

              Single bed
            </label>
          </div>

          <div class="checkbox-container">
            <input type="checkbox" id="double-bed" v-model="formData.bed_types" value="double">

            <label for="double-bed">
              <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.91176 2.79058e-09H12.5588C13.1355 -2.86088e-05 13.6905 0.219958 14.1106 0.615088C14.5307 1.01022 14.7842 1.55072 14.8194 2.12635L14.8235 2.26471V5.02682C15.2725 5.15404 15.6713 5.41687 15.9652 5.77935C16.2592 6.14184 16.4339 6.58628 16.4656 7.05188L16.4706 7.20588V13.3824C16.4705 13.5388 16.4111 13.6895 16.3043 13.8038C16.1974 13.9182 16.0512 13.9877 15.895 13.9984C15.7389 14.009 15.5846 13.96 15.4632 13.8613C15.3418 13.7625 15.2624 13.6214 15.2411 13.4664L15.2353 13.3824V11.5294H1.23529V13.3824C1.23529 13.5316 1.18124 13.6758 1.08313 13.7883C0.985031 13.9008 0.849515 13.9739 0.701647 13.9942L0.617647 14C0.468393 14 0.324189 13.9459 0.211704 13.8478C0.0992194 13.7497 0.0260633 13.6142 0.00576468 13.4664L0 13.3824V7.20588C0 6.16906 0.696706 5.29529 1.64706 5.026V2.26471C1.64703 1.688 1.86702 1.13301 2.26215 0.712932C2.65728 0.292857 3.19778 0.0393493 3.77341 0.00411774L3.91176 2.79058e-09ZM14.2059 6.17647H2.26471C2.00986 6.17635 1.76403 6.27077 1.57478 6.44146C1.38554 6.61215 1.26633 6.84696 1.24024 7.10047L1.23529 7.20588V10.2941H15.2353V7.20588C15.2352 6.95118 15.1407 6.70555 14.97 6.51648C14.7994 6.3274 14.5647 6.20832 14.3113 6.18224L14.2059 6.17647ZM12.5588 1.23529H3.91176C3.65692 1.23518 3.41109 1.3296 3.22184 1.50028C3.03259 1.67097 2.91339 1.90579 2.88729 2.15929L2.88235 2.26471V4.94118H4.11765C4.11765 4.72276 4.20441 4.5133 4.35885 4.35885C4.51329 4.20441 4.72276 4.11765 4.94118 4.11765H6.58823C6.78994 4.11767 6.98463 4.19173 7.13537 4.32577C7.2861 4.4598 7.3824 4.6445 7.406 4.84482L7.41176 4.94118H9.05882C9.05882 4.72276 9.14559 4.5133 9.30003 4.35885C9.45447 4.20441 9.66394 4.11765 9.88235 4.11765H11.5294C11.7311 4.11767 11.9258 4.19173 12.0765 4.32577C12.2273 4.4598 12.3236 4.6445 12.3472 4.84482L12.3529 4.94118H13.5882V2.26471C13.5884 2.00986 13.4939 1.76403 13.3232 1.57478C13.1526 1.38554 12.9177 1.26633 12.6642 1.24024L12.5588 1.23529Z" fill="#3C3F4C"/>
              </svg>

              Double bed
            </label>
          </div>

          <div class="checkbox-container">
            <input type="checkbox" id="queen-bed" v-model="formData.bed_types" value="queen">

            <label for="queen-bed">
              <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.08023 14H1.44559L0.998991 11.4545H2.28292e-05V7.14C2.28292e-05 6.57067 0.18493 6.08703 0.554744 5.68909C0.924558 5.2903 1.37272 5.09091 1.89924 5.09091H2.35054V2.05545C2.35054 1.48697 2.53544 1.00206 2.90526 0.600727C3.27507 0.200243 3.72324 0 4.24975 0L13.7515 0C14.2764 0 14.7242 0.200243 15.0948 0.600727C15.4646 1.00121 15.6495 1.48612 15.6495 2.05545V5.09091H16.102C16.6269 5.09091 17.0747 5.29115 17.4453 5.69164C17.8151 6.09212 18 6.57745 18 7.14764V11.4545H17.001L16.5544 14H15.9198L15.4732 11.4545H2.52682L2.08023 14ZM9.68042 5.09091H14.4742V2.05545C14.4742 1.82721 14.4065 1.6397 14.2709 1.49291C14.1354 1.34612 13.9622 1.27273 13.7515 1.27273H9.68042V5.09091ZM3.52579 5.09091H8.50517V1.27273H4.24975C4.0382 1.27273 3.86466 1.34612 3.72911 1.49291C3.59357 1.6397 3.52579 1.82721 3.52579 2.05545V5.09091ZM1.17528 10.1818H9.0928H16.8247V7.14764C16.8247 6.91855 16.757 6.73061 16.6214 6.58382C16.4859 6.43703 16.3127 6.36364 16.102 6.36364H1.89806C1.6873 6.36364 1.51414 6.43703 1.3786 6.58382C1.24305 6.73061 1.17528 6.91855 1.17528 7.14764V10.1818Z" fill="#3C3F4C"/>
              </svg>

              Queen bed
            </label>
          </div>

          <div class="checkbox-container">
            <input type="checkbox" id="king-bed" v-model="formData.bed_types" value="king">

            <label for="king-bed">
              <svg width="24" height="13" viewBox="0 0 24 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.99692 13H1.38769L0.958974 10.6364H0V6.63C0 6.10133 0.177504 5.65224 0.532513 5.28273C0.887521 4.91243 1.31774 4.72727 1.82318 4.72727H2.25641V1.90864C2.25641 1.38076 2.43391 0.930484 2.78892 0.557818C3.14393 0.18594 3.57415 0 4.07959 0L19.9215 1.1868e-06C20.4255 1.1868e-06 20.8553 0.185941 21.2111 0.557819C21.5661 0.929698 21.7436 1.37997 21.7436 1.90864V4.72727H22.1779C22.6819 4.72727 23.1117 4.91321 23.4675 5.28509C23.8225 5.65697 24 6.10764 24 6.63709V10.6364H23.041L22.6123 13H22.0031L21.5744 10.6364L2.42564 10.6364L1.99692 13ZM12.5641 4.72727L20.6154 4.72727V1.90864C20.6154 1.6967 20.5503 1.52258 20.4202 1.38627C20.2901 1.24997 20.1239 1.18182 19.9215 1.18182L12.5641 1.18182V4.72727ZM3.38462 4.72727H11.4359V1.18182H4.07959C3.87651 1.18182 3.70991 1.24997 3.57979 1.38627C3.44968 1.52258 3.38462 1.6967 3.38462 1.90864V4.72727ZM1.12821 9.45455H12L22.8718 9.45455V6.63709C22.8718 6.42437 22.8067 6.24985 22.6766 6.11355C22.5465 5.97724 22.3803 5.90909 22.1779 5.90909L1.82205 5.90909C1.61973 5.90909 1.4535 5.97724 1.32338 6.11355C1.19326 6.24985 1.12821 6.42436 1.12821 6.63709V9.45455Z" fill="#3C3F4C"/>
              </svg>

              King bed
            </label>
          </div>
        </div>
      </div>

      <div class="form-group" id="amenities-details" :class="{ hidden: !isFullyFurnished }">
        <p class="heading-6">Other available amenities?</p>

        <p>Select all that applies</p>

        <div class="flex-container custom-checkbox-container mt-5" role="group" aria-labelledby="amenities-label">
          <div class="checkbox-container">
            <input type="checkbox" id="bedding" v-model="formData.bedroom_furnishings" value="bedding">

            <label for="bedding">
              <img src="../../../../assets/svgs/bedding.svg" alt="bedding">
              Bedding
            </label>
          </div>

          <div class="checkbox-container">
            <input type="checkbox" id="pillows" v-model="formData.bedroom_furnishings" value="pillows">

            <label for="pillows">
              <img src="../../../../assets/svgs/pillows.svg" alt="pillows">
              Pillows
            </label>
          </div>

          <div class="checkbox-container">
            <input type="checkbox" id="blankets" v-model="formData.bedroom_furnishings" value="blankets">

            <label for="blankets">
              <img src="../../../../assets/svgs/blankets.svg" alt="blankets">
              Blankets
            </label>
          </div>

          <div class="checkbox-container">
            <input type="checkbox" id="towels" v-model="formData.bedroom_furnishings" value="towels">

            <label for="towels">
              <img src="../../../../assets/svgs/towels.svg" alt="towels">
              Towels
            </label>
          </div>

          <div class="checkbox-container">
            <input type="checkbox" id="closet" v-model="formData.bedroom_furnishings" value="closet">

            <label for="closet">
              <img src="../../../../assets/svgs/closet.svg" alt="closet">
              Closet
            </label>
          </div>

          <div class="checkbox-container">
            <input type="checkbox" id="desk" v-model="formData.bedroom_furnishings" value="desk">

            <label for="desk">
              <img src="../../../../assets/svgs/desk.svg" alt="desk">
              Desk
            </label>
          </div>

          <div class="checkbox-container">
            <input type="checkbox" id="drawers" v-model="formData.bedroom_furnishings" value="drawers">

            <label for="drawers">
              <img src="../../../../assets/svgs/drawers.svg" alt="drawers">
              Drawers
            </label>
          </div>

          <div class="checkbox-container">
            <input type="checkbox" id="chair" v-model="formData.bedroom_furnishings" value="chair">

            <label for="chair">
              <img src="../../../../assets/svgs/chair.svg" alt="chair">
              Chair
            </label>
          </div>

          <div class="checkbox-container">
            <input type="checkbox" id="hangers" v-model="formData.bedroom_furnishings" value="hangers">

            <label for="hangers">
              <img src="../../../../assets/svgs/hangers.svg" alt="hangers">
              Hangers
            </label>
          </div>

          <div class="checkbox-container">
            <input type="checkbox" id="lamp" v-model="formData.bedroom_furnishings" value="lamp">

            <label for="lamp">
              <img src="../../../../assets/svgs/lamp.svg" alt="lamp">
              Lamp
            </label>
          </div>

          <div class="checkbox-container">
            <input type="checkbox" id="television" v-model="formData.bedroom_furnishings" value="tv">

            <label for="television">
              <img src="../../../../assets/svgs/tv.svg" alt="television">
              Television
            </label>
          </div>

          <div class="checkbox-container">
            <input type="checkbox" id="fridge" v-model="formData.bedroom_furnishings" value="fridge">

            <label for="fridge">
              <img src="../../../../assets/svgs/fridge.svg" alt="fridge">
              Fridge
            </label>
          </div>

          <div class="checkbox-container">
            <input type="checkbox" id="curtains" v-model="formData.bedroom_furnishings" value="curtains">

            <label for="curtains">
              <img src="../../../../assets/svgs/curtains.svg" alt="curtains">
              Curtains
            </label>
          </div>
        </div>
      </div>

    </div>

    <div class="btns-container">
      <button type="button" class="secondary-btn prev-step" @click="prevSubStep">Back</button>
      <button type="button" class="primary-btn next-step" @click="nextSubStep" :class="{'btn-disabled': !isValid}" :disabled="!isValid">Next</button>
    </div>
  </div>
</template>

<style scoped>
.hidden {
  display: none;
}
</style>
<script>
import {mapActions, mapState} from "pinia";
import {createListingStore} from "@/stores/host/listing/createListingStore";
import stepNavigationMixin from "@/mixins/stepNavigationMixin";

export default {
  name: "SubStep2_5",
  mixins: [stepNavigationMixin],
  inject: ['navigationState'],
  computed: {
    ...mapState(createListingStore, ['formData']),
    isPartlyFurnished() {
      return (this.formData.furnishing === 'partly_furnished' || this.formData.furnishing === 'fully_furnished');
    },
    isFullyFurnished() {
      return (this.formData.furnishing === 'fully_furnished');
    },
    isValid() {
      if (this.formData.furnishing === 'partly_furnished') {
        return this.formData.bed_types && this.formData.bed_types.length > 0;
      }

      if (this.formData.furnishing === 'fully_furnished') {
        return (
            this.formData.bed_types && this.formData.bed_types.length > 0 &&
            this.formData.bedroom_furnishings && this.formData.bedroom_furnishings.length > 0
        );
      }

      if (this.formData.furnishing === 'unfurnished') {
        return true;
      }

      // Default return value to satisfy ESLint and ensure the computed property always returns something
      return false;
    }
  },
  watch: {
    'formData.furnishing' (newVal) {
      if(newVal === 'unfurnished'){
        this.formData.bed_types = [];
        this.formData.bedroom_furnishings = [];
      }
    }
  },
  methods: {
    ...mapActions(createListingStore, ['saveDraft']),
    nextSubStep() {
      this.saveDraft(this.navigationState);
      // Emit an event to the parent component
      this.nextStep(); // Directly call the store's action
    },
    prevSubStep() {
      // Emit an event to the parent component
      this.prevStep(); // Directly call the store's action
    },
  }
}
</script>