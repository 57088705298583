<template>
 <RenterDashboardTopNav />

 <div class="renter-dashboard settings-dashboard">
   <div class="main-dashboard container flex-container">
     <RenterDashboardSidebar />

     <div class="dashboard-content">
       <header class="mb-2">
         <h1 class="heading-2">Settings</h1>
       </header>

       <div class="cards-container flex-container">
         <a href="#" class="card">
           <div class="card-left-col">
             <img src="@/assets/svgs/security.svg" alt="security" />

             <div class="card-content">
               <p><strong>Security</strong></p>
               <p class="mt-2">Set your password, delete your account.</p>
             </div>
           </div>

           <div class="card-right-col">
             <img src="@/assets/svgs/chevron-right-dark.svg" alt="chevron" />
           </div>
         </a>

         <a href="#" class="card">
           <div class="card-left-col">
             <img src="@/assets/svgs/notifications-control.svg" alt="notifications" />

             <div class="card-content">
               <p><strong>Notifications control</strong></p>
               <p class="mt-2">Select what applies.</p>
             </div>
           </div>

           <div class="card-right-col">
             <img src="@/assets/svgs/chevron-right-dark.svg" alt="chevron" />
           </div>
         </a>
       </div>
     </div>

     <RenterDashboardBottomNav />
   </div>
 </div>
</template>


<script>
 import RenterDashboardSidebar from "@/components/RenterDashboardSidebar.vue";
 import RenterDashboardBottomNav from "@/components/RenterDashboardBottomNav.vue";
 import RenterDashboardTopNav from "@/components/RenterDashboardTopNav.vue";

 export default {
   name: "RenterSettings",

   components: {
     RenterDashboardSidebar,
     RenterDashboardBottomNav,
     RenterDashboardTopNav
   },
 };
</script>