<template>
  <SubStep3_1 v-if="navigationState.currentSubStep === '3.1'"/>
  <SubStep3_2 v-if="navigationState.currentSubStep === '3.2'"/>
</template>

<style scoped>

</style>

<script>

import SubStep3_1 from "@/views/host/listing/Step3/SubStep3_1.vue";
import SubStep3_2 from "@/views/host/listing/Step3/SubStep3_2.vue";
import stepNavigationMixin from "@/mixins/stepNavigationMixin";


export default {
  name: "StepThree",
  inject: ['navigationState'],
  mixins: [stepNavigationMixin],
  components: {
    SubStep3_1,
    SubStep3_2,
  },
  computed: {
    subStepArray() {
      return this.subSteps['3'];
    },
  },
  methods: {
    // nextSubStep() {
    //   const currentIndex = this.subStepArray.indexOf(this.currentSubStep);
    //   if (currentIndex < this.subStepArray.length - 1) {
    //     // Move to next sub-step
    //     const nextSubStep = this.subStepArray[currentIndex + 1];
    //     this.setCurrentSubStep(nextSubStep);
    //   } else {
    //     // Finished sub-steps for step 2, move to next main step
    //     this.nextStep();
    //   }
    // },
    // prevSubStep() {
    //   const currentIndex = this.subStepArray.indexOf(this.currentSubStep);
    //   if (currentIndex > 0) {
    //     // Move to previous sub-step
    //     const prevSubStep = this.subStepArray[currentIndex - 1];
    //     this.setCurrentSubStep(prevSubStep);
    //   } else {
    //     // At the first sub-step, move back to previous main step
    //     this.prevStep();
    //   }
    // },
  },
};
</script>