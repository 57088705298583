<template>
  <SubStep1_1 v-if="navigationState.currentSubStep === '1.1'"/>
  <SubStep1_2 v-if="navigationState.currentSubStep === '1.2'"/>
</template>

<script>
import SubStep1_1 from "@/views/host/profile/Step1/SubStep1_1.vue";
import SubStep1_2 from "@/views/host/profile/Step1/SubStep1_2.vue";
import stepNavigationMixin from "@/mixins/stepNavigationMixin";

export default {
  name: "StepOne",
  inject: ['navigationState'],
  mixins: [stepNavigationMixin],
  components: {SubStep1_1, SubStep1_2},
}
</script>

<style scoped>

</style>