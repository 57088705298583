<template>
  <div class="form-step active" data-step-index="2">
    <div>
      <h2 class="heading-2">How often do you bring friends for a visit?  (optional)</h2>

      <p class="font-size-medium">Select what applies.</p>
    </div>

    <div class="form-group">
      <div class="flex-container custom-radio-container" role="radiogroup" aria-labelledby="friends-label">
        <div class="radio-container">
          <input type="radio" id="never" v-model="formData.host_visit_frequency" value="never" aria-labelledby="never-label">

          <label for="never" id="never-label">
            Never
          </label>
        </div>

        <div class="radio-container">
          <input type="radio" id="once-a-month" v-model="formData.host_visit_frequency" value="Once a month" aria-labelledby="once-a-month-label">

          <label for="once-a-month" id="once-a-month-label">
            Once a month
          </label>
        </div>

        <div class="radio-container">
          <input type="radio" id="few-times" v-model="formData.host_visit_frequency" value="A few times per month" aria-labelledby="few-times-label">

          <label for="few-times" id="few-times-label">
            A few times per month
          </label>
        </div>

        <div class="radio-container">
          <input type="radio" id="every-week" v-model="formData.host_visit_frequency" value="Every week" aria-labelledby="every-week-label">

          <label for="every-week" id="every-week-label">
            Every week
          </label>
        </div>

        <div class="radio-container">
          <input type="radio" id="few-times-per-week" v-model="formData.host_visit_frequency" value="A few times per week" aria-labelledby="few-times-per-week-label">

          <label for="few-times-per-week" id="few-times-per-week-label">
            A few times per week
          </label>
        </div>

        <div class="radio-container">
          <input type="radio" id="daily" v-model="formData.host_visit_frequency" value="Daily" aria-labelledby="daily-label">

          <label for="daily" id="daily-label">
            Daily
          </label>
        </div>
      </div>
    </div>

    <div class="btns-container">
      <button type="button" class="secondary-btn prev-step" @click.prevent="prevSubStep">Back</button>

      <button type="button" class="primary-btn next-step" @click.prevent="nextSubStep">Next</button>
    </div>
  </div>
</template>


<script>
import {mapState} from "pinia";
import stepNavigationMixin from "@/mixins/stepNavigationMixin";
import { useCreateHostProfile } from "@/stores/host/profile/useCreateHostProfile";

export default {
  name: "SubStep2_3",
  mixins: [stepNavigationMixin],
  inject: ['navigationState'],
  computed: {
    ...mapState(useCreateHostProfile, ['formData']),
  },
  methods: {
    nextSubStep() {
      this.nextStep();
    },
    prevSubStep() {
      this.prevStep();
    }
  }
}
</script>


<style scoped>

</style>