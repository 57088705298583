<template>
  <RenterDashboardTopNav />

  <div class="renter-dashboard">
    <div class="main-dashboard container flex-container">
      <RenterDashboardSidebar />

      <div class="dashboard-content">
        <header class="mb-2">
          <h1 class="heading-2">Dashboard</h1>

          <div>
            <p>Your next steps</p>
            <span class="notification-badge" aria-hidden="true">
              <strong>5</strong>
            </span>
          </div>
        </header>

        <div class="cards-container flex-container">
          <section>
            <a href="#" class="card" v-for="(card, index) in cards" :key="index">
              <div class="card-left-col">
                <img :src="getAsset(card.icon)" :alt="card.iconAlt" />

                <div class="card-content">
                  <p><strong>Required</strong></p>

                  <p class="mt-2">
                    <a :href="card.link">{{ card.text }}</a> before the 1st of December.
                  </p>
                </div>
              </div>
            </a>
          </section>
        </div>
      </div>

      <RenterDashboardBottomNav />
    </div>
  </div>
</template>

<script>
  import RenterDashboardSidebar from "@/components/RenterDashboardSidebar.vue";
  import RenterDashboardBottomNav from "@/components/RenterDashboardBottomNav.vue";
  import RenterDashboardTopNav from "@/components/RenterDashboardTopNav.vue";

  export default {
    name: "RenterDashboard",

    components: {
      RenterDashboardSidebar,
      RenterDashboardBottomNav,
      RenterDashboardTopNav
    },
    data() {
      return {
        cards: [
          {
            icon: "warning.svg",
            iconAlt: "Warning",
            text: "Pay monthly rent",
            link: "#",
          },
          {
            icon: "warning.svg",
            iconAlt: "Warning",
            text: "Upload your insurance",
            link: "#",
          },
        ],
      };
    },
    methods: {
      getAsset(filename) {
        return require(`@/assets/svgs/${filename}`);
      },
    },
  };
</script>