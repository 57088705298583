<template>
  <div class="form-step active" data-step="2-3">
    <div>
      <h2 class="heading-2">Who would you like to host?</h2>

      <p class="font-size-medium">Select all that applies.</p>
    </div>

    <div class="form-group">
      <div class="flex-container custom-checkbox-container" role="group" aria-labelledby="hosting-preference-label">
        <div class="checkbox-container checkbox-container-with-icon">
          <input type="checkbox" id="students" v-model="localPreferredHost" @change="handleCheckboxChange('students')" value="students" aria-labelledby="students-label">
          <label for="students" id="students-label">
            <img src="../../../../assets/svgs/students.svg" alt="students">
            <div class="label-content">Students</div>
          </label>
        </div>

        <div class="checkbox-container checkbox-container-with-icon">
          <input type="checkbox" id="seniors" v-model="localPreferredHost" @change="handleCheckboxChange('seniors')" value="seniors" aria-labelledby="seniors-label">
          <label for="seniors" id="seniors-label">
            <img src="../../../../assets/svgs/seniors.svg" alt="seniors">
            <div class="label-content">Seniors</div>
          </label>
        </div>

        <div class="checkbox-container checkbox-container-with-icon">
          <input type="checkbox" id="refugees" v-model="localPreferredHost" @change="handleCheckboxChange('refugees')" value="refugees" aria-labelledby="refugees-label">
          <label for="refugees" id="refugees-label">
            <img src="../../../../assets/svgs/refugees.svg" alt="refugees">
            <div class="label-content">Refugees</div>
          </label>
        </div>

        <div class="checkbox-container checkbox-container-with-icon">
          <input type="checkbox" id="working-professionals" v-model="localPreferredHost" @change="handleCheckboxChange('working_professionals')" value="working_professionals" aria-labelledby="working-professionals-label">
          <label for="working-professionals" id="working-professionals-label">
            <img src="../../../../assets/svgs/working-professionals.svg" alt="working professionals">
            <div class="label-content">Working professionals</div>
          </label>
        </div>

        <div class="checkbox-container checkbox-container-with-icon">
          <input type="checkbox" id="disabled-people" v-model="localPreferredHost" @change="handleCheckboxChange('disabled_people')" value="disabled_people" aria-labelledby="disabled-people-label">

          <label for="disabled-people" id="disabled-people-label">
            <img src="../../../../assets/svgs/disabled-people.svg" alt="disabled people">

            <div class="label-content">People with disabilities</div>
          </label>
        </div>

        <div class="checkbox-container checkbox-container-with-icon">
          <input type="checkbox" id="no-preference" v-model="localPreferredHost" @change="handleCheckboxChange('no_preference')" value="no_preference" aria-labelledby="no-preference-label">

          <label for="no-preference" id="no-preference-label">
            <img src="../../../../assets/svgs/no-preference.svg" alt="no preference">

            <div class="label-content">No preference</div>
          </label>
        </div>
      </div>

      <div id="hostingErrorText" class="validation-error"></div>
    </div>

    <div class="btns-container">
      <button type="button" class="secondary-btn prev-step" @click="prevSubStep">Back</button>
      <button type="button" class="primary-btn next-step" @click="nextSubStep" :class="{'btn-disabled': !isValid}" :disabled="!isValid">Next</button>
    </div>
  </div>
</template>

<style scoped>

</style>
<script>
import {mapState, mapActions} from "pinia";
import {createListingStore} from "@/stores/host/listing/createListingStore";
import stepNavigationMixin from "@/mixins/stepNavigationMixin";

export default {
  name: "SubStep2_3",
  mixins: [stepNavigationMixin],
  inject: ['navigationState'],
  data() {
    return {
      localPreferredHost: [],
    }
  },
  computed: {
    ...mapState(createListingStore, ['formData']),
    isValid() {
      return this.localPreferredHost.length > 0;
    },
  },
  mounted() {
    // Initialize localPreferredHost with the value from the store
    if (this.formData.preferred_host) {
      this.localPreferredHost = [...this.formData.preferred_host];
    }
  },
  methods: {
    ...mapActions(createListingStore, ['updateFormData', 'saveDraft']),
    handleCheckboxChange(changedValue) {
      if (changedValue === 'no_preference' && this.localPreferredHost.includes('no_preference')) {
        this.localPreferredHost = ['no_preference'];
      } else if (this.localPreferredHost.includes('no_preference')) {
        this.localPreferredHost = this.localPreferredHost.filter(value => value !== 'no_preference');
      }
      this.updateFormData({ preferred_host: this.localPreferredHost });
    },
    nextSubStep() {
      this.saveDraft(this.navigationState);
      // Emit an event to the parent component
      this.nextStep(); // Directly call the store's action
    },
    prevSubStep() {
      // Emit an event to the parent component
      this.prevStep(); // Directly call the store's action
    },
  }
}
</script>