<template>
  <div class="container flex-container flex-col-2 mb-6 reset-password-container">
    <div class="form-container">
      <form @submit.prevent="handleResetPassword">
        <div class="form-step active">
          <div>
            <h2 class="heading-2">Reset your password</h2>
            <p class="font-size-medium">Please enter a strong password you can remember.</p>
          </div>
          <div class="form-group">
            <input type="password" v-model="password" placeholder="Password" maxlength="30" required/>
            <div class="validation-error">
              <span v-if="passwordError">{{ passwordError }}</span>
            </div>

            <input type="password" v-model="passwordConfirmation" placeholder="Confirm Password" maxlength="30" required/>
            <div class="validation-error">
              <span v-if="passwordConfirmationError">{{ passwordConfirmationError }}</span>
            </div>
          </div>

          <div v-if="successMessage" class="success-message">
            {{ successMessage }}
          </div>
          <div v-if="errorMessage" class="error-message">
            {{ errorMessage }}
          </div>

          <div class="btns-container">
            <button type="submit" class="primary-btn">Reset Password</button>
          </div>
        </div>
      </form>
    </div>

    <div class="illustration-container background-light-blue">
      <img src="@/assets/svgs/signup-illustration.svg" alt="Happipad Logo" />
    </div>
  </div>
</template>

<script>
import http from '@/http';

export default {
  name: 'ResetPassword',
  data() {
    return {
      password: '',
      passwordConfirmation: '',
      successMessage: '',
      errorMessage: '',
      passwordError: '',
      passwordConfirmationError: '',
      email: '',
      expires: '',
      signature: '',
    };
  },
  created() {
    // Retrieve the query parameters from the route
    const urlParams = new URLSearchParams(this.$route.query);
    this.email = urlParams.get('email') || '';
    this.expires = urlParams.get('expires') || '';
    this.signature = urlParams.get('signature') || '';
  },
  methods: {
    async handleResetPassword() {
      // Clear previous error messages
      this.errorMessage = '';
      this.passwordError = '';
      this.passwordConfirmationError = '';

      // Client-side validation
      if (this.password !== this.passwordConfirmation) {
        this.passwordConfirmationError = 'Passwords do not match.';
        return;
      }

      try {
        // Prepare the data to send in the POST request
        const postData = {
          email: this.email,
          password: this.password,
          password_confirmation: this.passwordConfirmation,
          expires: this.expires,
          signature: this.signature,
        };

        // Send a POST request to the correct backend API endpoint
        const response = await http.post('/api/auth/password/reset', postData);

        // Handle success response
        this.successMessage =
            response.data.message || 'Your password has been reset successfully.';
        this.errorMessage = '';

        // Optionally redirect to login page after a delay
        setTimeout(() => {
          this.$router.push({ name: 'Login' });
        }, 3000);
      } catch (error) {
        // Handle error response
        if (error.response && error.response.data) {
          const data = error.response.data;

          if (data.errors) {
            // Handle validation errors
            if (data.errors.password) {
              this.passwordError = data.errors.password[0];
            }
            if (data.errors.password_confirmation) {
              this.passwordConfirmationError = data.errors.password_confirmation[0];
            }
          }
          this.errorMessage = data.message || 'An error occurred. Please try again.';
        } else {
          this.errorMessage = 'An error occurred. Please try again.';
        }
        this.successMessage = '';
      }
    },
  },
};
</script>

<style scoped>
.success-message {
  color: green;
  margin-top: 1em;
}
.error-message {
  color: red;
  margin-top: 1em;
}
.validation-error span {
  color: red;
  font-size: 0.9em;
}
</style>
