<template>
  <div class="form-step active" data-step="2-7">
    <div>
      <h2 class="heading-2">What other spaces are shared?</h2>

      <p class="font-size-medium">Select all that applies.</p>
    </div>

    <div class="form-group">
      <div class="flex-container custom-checkbox-container mt-5" role="group" aria-labelledby="shared-spaces-label">
        <div class="checkbox-container">
          <input type="checkbox" id="kitchen" v-model="formData.shared_spaces" value="kitchen">

          <label for="kitchen">
            <img src="../../../../assets/svgs/kitchen.svg" alt="kitchen">

            Kitchen
          </label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="living-room" v-model="formData.shared_spaces" value="living_room">

          <label for="living-room">
            <img src="../../../../assets/svgs/living-room.svg" alt="Living room"/>

            Living room
          </label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="office" v-model="formData.shared_spaces" value="office">

          <label for="office">
            <img src="../../../../assets/svgs/office.svg" alt="office">

            Office
          </label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="den" v-model="formData.shared_spaces" value="den">

          <label for="den">
            <img src="../../../../assets/svgs/den.svg" alt="Den">

            Den
          </label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="patio" v-model="formData.shared_spaces" value="patio">

          <label for="patio">
            <img src="../../../../assets/svgs/patio.svg" alt="Patio">

            Patio
          </label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="backyard" v-model="formData.shared_spaces" value="backyard">

          <label for="backyard">
            <img src="../../../../assets/svgs/backyard.svg" alt="Backyard">

            Backyard
          </label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="other" v-model="formData.shared_spaces" value="other">

          <label for="other">
            <img src="../../../../assets/svgs/other-shared-space.svg" alt="Other">

            Other
          </label>
        </div>
      </div>

      <div id="sharedSpacesErrorText" class="validation-error"></div>
    </div>

    <div class="btns-container">
      <button type="button" class="secondary-btn prev-step" @click="prevSubStep">Back</button>
      <button type="button" class="primary-btn next-step" @click="nextSubStep" :class="{'btn-disabled': !isValid}" :disabled="!isValid">Next</button>
    </div>
  </div>
</template>

<style scoped>

</style>
<script>
import {mapState, mapActions} from "pinia";
import {createListingStore} from "@/stores/host/listing/createListingStore";
import stepNavigationMixin from "@/mixins/stepNavigationMixin";

export default {
  name: "SubStep2_7",
  mixins: [stepNavigationMixin],
  inject: ['navigationState'],
  computed: {
    ...mapState(createListingStore, ['formData']),
    isValid() {
      return this.formData.shared_spaces.length > 0;
    },
  },
  methods: {
    ...mapActions(createListingStore, ['saveDraft']),
    nextSubStep() {
      this.saveDraft(this.navigationState);
      // Emit an event to the parent component
      this.nextStep(); // Directly call the store's action
    },
    prevSubStep() {
      // Emit an event to the parent component
      this.prevStep(); // Directly call the store's action
    },
  }
}
</script>