<template>
  <div class="form-step active" data-step="3.1">
    <div>
      <h2 class="heading-2">Do you allow pets?</h2>

      <p class="font-size-medium">This section is crucial to establish your house rules. It will be included in your contract.</p>
    </div>

    <div class="form-group">
      <div class="flex-container custom-radio-container" role="radiogroup" aria-labelledby="pets-label">
        <div class="radio-container">
          <input type="radio" id="allow-pets-yes" v-model="formData.allows_pets" value="yes" aria-labelledby="allow-pets-yes-label">

          <label for="allow-pets-yes" id="allow-pets-yes-label">
            Yes, I do
          </label>
        </div>

        <div class="radio-container">
          <input type="radio" id="allow-pets-no" v-model="formData.allows_pets" value="no" aria-labelledby="allow-pets-no-label">

          <label for="allow-pets-no" id="allow-pets-no-label">
            No, I don’t
          </label>
        </div>

        <div class="radio-container">
          <input type="radio" id="allow-pets-some" v-model="formData.allows_pets" value="some" aria-labelledby="allow-pets-some-label">

          <label for="allow-pets-some" id="allow-pets-some-label">
            Only some pets
          </label>
        </div>
      </div>

      <div id="petsErrorText" class="validation-error"></div>

      <div class="form-group mt-5" id="pets-details" :class="{hidden : !allowsSome}">
        <p class="heading-6">What pets do you allow?</p>

        <p>Select all that applies</p>

        <div class="flex-container custom-checkbox-container mt-3" role="group" aria-labelledby="pets-details-label">
          <div class="checkbox-container">
            <input type="checkbox" id="pet-small" v-model="formData.allowed_pet_types" value="small">

            <label for="pet-small">
              Small
            </label>
          </div>

          <div class="checkbox-container">
            <input type="checkbox" id="pet-medium" v-model="formData.allowed_pet_types" value="medium">

            <label for="pet-medium">
              Medium
            </label>
          </div>

          <div class="checkbox-container">
            <input type="checkbox" id="pet-large" v-model="formData.allowed_pet_types" value="large">

            <label for="pet-large">
              Large
            </label>
          </div>

          <div class="checkbox-container">
            <input type="checkbox" id="pet-cats" v-model="formData.allowed_pet_types" value="cats">

            <label for="pet-cats">
              Cats
            </label>
          </div>

          <div class="checkbox-container">
            <input type="checkbox" id="pet-dogs" v-model="formData.allowed_pet_types" value="dogs">

            <label for="pet-dogs">
              Dogs
            </label>
          </div>

          <div class="checkbox-container">
            <input type="checkbox" id="pet-other" v-model="formData.allowed_pet_types" value="other">

            <label for="pet-other">
              Other
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="btns-container">
      <button type="button" class="secondary-btn prev-step" @click="prevSubStep">Back</button>
      <button type="button" class="primary-btn next-step" @click="nextSubStep" :class="{'btn-disabled': !isValid}" :disabled="!isValid">Next</button>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from "pinia";
import {createListingStore} from "@/stores/host/listing/createListingStore";
import stepNavigationMixin from "@/mixins/stepNavigationMixin";

export default {
  name: "SubStep3_1",
  mixins: [stepNavigationMixin],
  inject: ['navigationState'],
  computed: {
    ...mapState(createListingStore, ['formData']),
    isValid() {
      if (this.formData.allows_pets === 'some') {
        // Check that at least one pet type is selected
        return this.formData.allowed_pet_types && this.formData.allowed_pet_types.length > 0;
      }
      // For "yes" and "no", no further validation is required
      return this.formData.allows_pets === 'yes' || this.formData.allows_pets === 'no';
    },
    allowsSome() {
      return this.formData.allows_pets === 'some';
    }
  },
  methods: {
    ...mapActions(createListingStore, ['saveDraft']),
    nextSubStep() {
      this.saveDraft(this.navigationState);
      // Emit an event to the parent component
      this.nextStep();
    },
    prevSubStep() {
      // Emit an event to the parent component
      this.prevStep();
    },
  }
}
</script>

<style scoped>
.hidden {
  display: none;
}
</style>