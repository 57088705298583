<template>
  <RenterDashboardTopNav />

  <div class="user-profile renter-profile renter-profile-edit">
    <RenterDashboardSidebar class="hidden-mobile mt-5" />



    <div class="container flex-container profile-section">
      <h1 class="heading-2">Profile</h1>

      <!-- Profile Section -->
      <section class="profile-header" aria-labelledby="profile-heading">
        <div tabindex="0" aria-labelledby="profile-name">
          <div
            class="profile-header__bg"
            :style="{ backgroundImage: `url(${require('@/assets/images/profile-background-placeholder.jpeg')})` }"
          >

            <div class="profile-header__edit-image">
              <img
                src="@/assets/images/profile-user-placeholder.jpeg"
                alt="profile picture"
                class="profile-image"
              />

              <a href="#" class="edit-link">
                <img src="@/assets/svgs/edit.svg" alt="edit">
              </a>
            </div>
          </div>

          <div class="profile-header__info">
            <h2 class="heading-2" id="profile-name">John, 54</h2>

            <p class="verification-status">
              <span aria-label="ID Verified">
                <img src="@/assets/svgs/verified.svg" alt="verified" />
                ID verified
              </span>
              <span aria-label="Email Verified">
                <img src="@/assets/svgs/verified.svg" alt="verified" />
                Email verified
              </span>
            </p>

            <div class="info-note mt-4">
              <img src="@/assets/images/certn-logo.webp" alt="Certn" />
              <p>Screening completed</p>
            </div>
          </div>
        </div>
      </section>

      <!-- Story Section -->
      <section aria-labelledby="story-heading" class="card" tabindex="0">
        <div class="story-section">
          <div class="edit-info">
            <h2 id="story-heading" class="heading-6">My story</h2>

            <a href="#" class="edit-link">
              <img src="@/assets/svgs/edit.svg" alt="edit">
              Edit
            </a>
          </div>

          <p class="profile-description">
            I’m an easy going person, and I do work full time doing maintenance and I stay to my self and Im looking for room and I love having fun but I’m a very quiet person.
          </p>
        </div>
      </section>

      <!-- verification pending Section -->
      <section aria-labelledby="verification-heading" class="card" tabindex="0">
        <div class="verification-pending-section">
          <div class="edit-info">
            <h2 id="verification-heading" class="heading-6">Verifications</h2>

            <a href="#" class="edit-link">
              <img src="@/assets/svgs/edit.svg" alt="edit">
              Edit
            </a>
          </div>

          <div class="additional-info">
            <p>
              <img src="@/assets/svgs/checkmark-circle-green.svg" alt="checkmark" />
              Screening was submitted and pending results
            </p>
          </div>
        </div>
      </section>

      <!-- verification not completed Section -->
      <section aria-labelledby="verification-heading" class="card" tabindex="0">
        <div class="verification-not-completed-section">
          <div class="edit-info">
            <h2 id="verification-heading" class="heading-6">Verifications</h2>

            <a href="#" class="edit-link">
              <img src="@/assets/svgs/edit.svg" alt="edit">
              Edit
            </a>
          </div>

          <div class="additional-info">
            <p>
              <img src="@/assets/images/certn-logo.webp" alt="Certn" />
              Screening not completed
            </p>

            <p>
              <img src="@/assets/images/certn-logo.webp" alt="Certn" />
              ID verification not completed
            </p>
          </div>

          <button class="tertiary-btn mt-3">
            Start verification
          </button>
        </div>
      </section>

      <!-- reference Section -->
      <section aria-labelledby="reference-heading" class="card" tabindex="0">
        <div class="reference-section">
          <h2 id="reference-heading" class="heading-6">References</h2>

          <p>No references provided</p>

          <button class="tertiary-btn mt-3" @click="showModal=true">
            Add references
          </button>

          <!-- Modal Popup -->
          <div v-if="showModal" class="modal-overlay" @click.self="closeModal">
            <div class="modal-container">
              <div class="modal-header">
                <p>Reference</p>
                <button class="close-btn" @click="closeModal"><img src="@/assets/svgs/close-big.svg" alt="close" /></button>
              </div>

              <!-- Integrated Form in Modal -->
              <div class="form-container">
                <h2 class="heading-4">Reference details</h2>

                <form id="screening-details-form" @submit.prevent="submitForm">
                  <div class="form-group">
                    <label for="full-name">Referee full name</label>
                    <input
                      type="text"
                      name="fullName"
                      placeholder="Enter your full name"
                      maxlength="30"
                      id="fullName"
                      v-model="form.fullName"
                    >
                  </div>

                  <div class="form-group">
                    <label for="phone">Phone number</label>
                    <input
                      type="text"
                      name="phoneNumber"
                      placeholder="xxxxxxxxxxx"
                      id="phone"
                      v-model="form.phoneNumber"
                    >
                    <div id="phoneErrorText" class="validation-error"></div>
                  </div>

                  <div class="form-group">
                    <label for="email">Email</label>
                    <input
                      type="email"
                      name="email"
                      placeholder="john@acme.com"
                      maxlength="254"
                      id="email"
                      v-model="form.email"
                    >
                    <div id="emailErrorText" class="validation-error"></div>
                  </div>

                  <div class="form-group">
                    <label for="relation">Relation to renter</label>
                    <input
                      type="relation"
                      name="relation"
                      placeholder="Type..."
                      maxlength="254"
                      id="relation"
                      v-model="form.relation"
                    >
                    <div id="relationErrorText" class="validation-error"></div>
                  </div>

                  <div class="info-note mt-4">
                    <img src="@/assets/svgs/info.svg" alt="Info" />
                    <p>Explain how do you know this person.</p>
                  </div>

                  <div
                    class="add-address-container add-container mt-4"
                    tabindex="0"
                    role="button"
                    aria-label="Add another address"
                    @click="addNewAddress"
                  >
                    <img src="@/assets/svgs/plus.svg" alt="Add address icon" class="add-address-icon" @click="addNewReference" />
                    <p><strong>Add another reference</strong></p>
                  </div>

                  <div class="form-actions sticky-btm">
                    <button type="submit" class="primary-btn">Save</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- looking in Section -->
      <section aria-labelledby="looking-in-heading" class="card" tabindex="0">
        <div class="looking-in-section">
          <div class="edit-info">
            <h2 id="looking-in-heading" class="heading-6">Looking in</h2>

            <a href="#" class="edit-link">
              <img src="@/assets/svgs/edit.svg" alt="edit">
              Edit
            </a>
          </div>

          <p>Kelowna</p>
        </div>
      </section>

      <!-- looking for Section -->
      <section aria-labelledby="looking-for-heading" class="card" tabindex="0">
        <div class="looking-for-section">
          <div class="edit-info">
            <h2 id="looking-for-heading" class="heading-6">Looking for</h2>

            <a href="#" class="edit-link">
              <img src="@/assets/svgs/edit.svg" alt="edit">
              Edit
            </a>
          </div>

          <p>12/06/2024-12/06/2024</p>
        </div>
      </section>

      <!-- Budget Section -->
      <section aria-labelledby="budget-heading" class="card" tabindex="0">
        <div class="budget-section">
          <div class="edit-info">
            <h2 id="budget-heading" class="heading-6">Looking for</h2>

            <a href="#" class="edit-link">
              <img src="@/assets/svgs/edit.svg" alt="edit">
              Edit
            </a>
          </div>

          <p>$ 2000/month</p>
        </div>
      </section>

      <!-- moving with Section -->
      <section aria-labelledby="moving-with-heading" class="card" tabindex="0">
        <div class="moving-with-section">
          <div class="edit-info">
            <h2 id="moving-with-heading" class="heading-6">Moving with</h2>

            <a href="#" class="edit-link">
              <img src="@/assets/svgs/edit.svg" alt="edit">
              Edit
            </a>
          </div>

          <div class="additional-info">
            <p>
              <img src="@/assets/svgs/checkmark.svg" alt="checkmark" />
              A family of 2
            </p>

            <p>
              <img src="@/assets/svgs/checkmark.svg" alt="checkmark" />
              Pet dog
            </p>
          </div>

          <div class="pets-list">
            <img
              src="@/assets/images/profile-pet-placeholder.jpeg"
              alt="pet image"
              class="pet-image"
            />

            <img
              src="@/assets/images/profile-pet-2-placeholder.jpeg"
              alt="pet image"
              class="pet-image"
            />
          </div>
        </div>
      </section>

      <!-- Preferences -->
      <section aria-labelledby="preferences-heading" class="card" tabindex="0">
        <div class="preferences-section">
          <div class="edit-info">
            <h2 id="preferences-heading" class="heading-6">Gender</h2>

            <a href="#" class="edit-link">
              <img src="@/assets/svgs/edit.svg" alt="edit">
              Edit
            </a>
          </div>

          <div class="additional-info" tabindex="0">
            <p>
              <img src="@/assets/svgs/profile-gender-female.svg" alt="female" />
              Male
            </p>

            <p>
              <img src="@/assets/svgs/profile-gender-female.svg" alt="female" />
              Prefers male host
            </p>
          </div>
        </div>
      </section>

      <!-- langs -->
      <section aria-labelledby="langs-heading" class="card" tabindex="0">
        <div class="langs-section">
          <div class="edit-info">
            <h2 id="langs-heading" class="heading-6">Languages</h2>

            <a href="#" class="edit-link">
              <img src="@/assets/svgs/edit.svg" alt="edit">
              Edit
            </a>
          </div>

          <div class="additional-info mt-3" tabindex="0">
            <p>
              <img src="@/assets/svgs/profile-lang.svg" alt="languages">
              Speaks English and French
            </p>
          </div>
        </div>
      </section>

      <!-- employment -->
      <section aria-labelledby="employment-heading" class="card" tabindex="0">
        <div class="employment-section">
          <div class="edit-info">
            <h2 id="employment-heading" class="heading-6">Jay’s employment</h2>

            <a href="#" class="edit-link">
              <img src="@/assets/svgs/edit.svg" alt="edit">
              Edit
            </a>
          </div>

          <div class="additional-info">
            <p>
              <img src="@/assets/svgs/checkmark.svg" alt="checkmark" />
              Part-time employee
            </p>

            <p>
              <img src="@/assets/svgs/checkmark.svg" alt="checkmark" />
              Student
            </p>

            <p>
              <img src="@/assets/svgs/checkmark.svg" alt="checkmark" />
              Saint Mary’s U
            </p>
          </div>
        </div>
      </section>

      <!-- Habits Section -->
      <section aria-labelledby="habits-heading" class="card" tabindex="0">
        <div class="habits-section">
          <h2 id="habits-heading" class="heading-6">John's Habits</h2>
          <div class="additional-info">
            <p>
              <img src="@/assets/svgs/profile-drinking.svg" alt="Drinking socially" />
              Drinking socially
            </p>
            <p>
              <img src="@/assets/svgs/profile-cannabis.svg" alt="Cannabis friendly" />
              Cannabis friendly
            </p>
            <p>
              <img
                src="@/assets/svgs/profile-not-pet-friendly.svg"
                alt="Not pet friendly"
              />
              Not pet friendly
            </p>
            <p>
              <img src="@/assets/svgs/profile-non-smoker.svg" alt="Smoke/vape: never" />
              Smoke/vape: never
            </p>
            <p>
              <img src="@/assets/svgs/profile-cooks.svg" alt="cooking" />
              Cooks 1-2 times per week
            </p>
            <p>
              <img src="@/assets/svgs/profile-cleans.svg" alt="cleans" />
              Cleaning: a few times per week
            </p>
            <p>
              <img src="@/assets/svgs/profile-organization.svg" alt="organization" />
              Organize only if necessary
            </p>
          </div>
        </div>
      </section>

      <!-- Hobbies Section -->
      <section aria-labelledby="hobbies-heading" class="card" tabindex="0">
        <div class="hobbies-section">
          <h2 id="hobbies-heading" class="heading-6">John's Hobbies</h2>
          <div class="additional-info">
            <p>
              <img src="@/assets/svgs/profile-traveling.svg" alt="traveling" />
              Traveling
            </p>
            <p>
              <img src="@/assets/svgs/profile-gardening.svg" alt="Gardening" />
              Gardening
            </p>
            <p>
              <img src="@/assets/svgs/profile-music.svg" alt="music" />
              Music
            </p>
          </div>
        </div>
      </section>

      <!-- email Section -->
      <section aria-labelledby="email-heading" class="card" tabindex="0">
        <div class="email-section">
          <div class="edit-info">
            <h2 id="email-heading" class="heading-6">Email</h2>

            <a href="#" class="edit-link">
              <img src="@/assets/svgs/edit.svg" alt="edit">
              Edit
            </a>
          </div>

          <p>jaydoe@gmail.com</p>
        </div>
      </section>

      <!-- phone Section -->
      <section aria-labelledby="phone-heading" class="card" tabindex="0">
        <div class="phone-section">
          <div class="edit-info">
            <h2 id="phone-heading" class="heading-6">Phone</h2>

            <a href="#" class="edit-link">
              <img src="@/assets/svgs/edit.svg" alt="edit">
              Edit
            </a>
          </div>

          <p>475 5736 4637</p>
        </div>
      </section>
    </div>
  </div>

  <RenterDashboardBottomNav />
</template>

<script>
import RenterDashboardSidebar from "@/components/RenterDashboardSidebar.vue";
import RenterDashboardBottomNav from "@/components/RenterDashboardBottomNav.vue";
import RenterDashboardTopNav from "@/components/RenterDashboardTopNav.vue";

export default {
  name: "RenterProfile",

  components: {
    RenterDashboardSidebar,
    RenterDashboardBottomNav,
    RenterDashboardTopNav,
  },

  data() {
    return {
      showModal: false,
      form: {
        email: "",
        fullName: "",
        relation: "",
        phoneNumber: "",
      },
    };
  },

  methods: {
    openModal() {
      this.showModal = true;
      document.body.style.overflow = "hidden"; // Prevent background scroll
    },
    closeModal() {
      this.showModal = false;
      document.body.style.overflow = "";
    },
    addNewReference() {
      this.references.push({
        fullName: "",
        phoneNumber: "",
        email: "",
        relation: "",
      });
    },
    submitForm() {
      this.closeModal();
    },
  },
};
</script>