<template>
  <div class="form-step active" data-step="2-4">
    <div>
      <h2 class="heading-2">How big is renter’s space?</h2>

      <p class="font-size-medium">Select one option.</p>
    </div>

    <div class="form-group">
      <div class="flex-container custom-radio-container" role="radiogroup" aria-labelledby="size-label">
        <div class="radio-container">
          <input type="radio" id="small" v-model="formData.space_size" value="small" aria-labelledby="small-label">

          <label for="small" id="small-label">
            <strong>Small</strong> up to 100 sqft
          </label>
        </div>

        <div class="radio-container">
          <input type="radio" id="medium" v-model="formData.space_size" value="medium" aria-labelledby="medium-label">

          <label for="medium" id="medium-label">
            <strong>Medium</strong> up to 200 sqft
          </label>
        </div>

        <div class="radio-container">
          <input type="radio" id="large" v-model="formData.space_size" value="large" aria-labelledby="large-label">

          <label for="large" id="large-label">
            <strong>Large</strong> up to 300 sqft
          </label>
        </div>

        <div class="radio-container">
          <input type="radio" id="xlarge" v-model="formData.space_size" value="extra_large" aria-labelledby="xlarge-label">

          <label for="xlarge" id="xlarge-label">
            <strong>Extra large</strong> more than 300 sqft
          </label>
        </div>
      </div>
    </div>

    <div class="btns-container">
      <button type="button" class="secondary-btn prev-step" @click="prevSubStep">Back</button>
      <button type="button" class="primary-btn next-step" @click="nextSubStep" :class="{'btn-disabled': !isValid}" :disabled="!isValid">Next</button>
    </div>
  </div>
</template>

<style scoped>

</style>
<script>
import {mapActions, mapState} from "pinia";
import {createListingStore} from "@/stores/host/listing/createListingStore";
import stepNavigationMixin from "@/mixins/stepNavigationMixin";

export default {
  name: "SubStep2_4",
  mixins: [stepNavigationMixin],
  inject: ['navigationState'],
  computed: {
    ...mapState(createListingStore, ['formData']),
    isValid() {
      return this.formData.space_size.length > 0;
    },
  },
  methods: {
    ...mapActions(createListingStore, ['saveDraft']),
    nextSubStep() {
      this.saveDraft(this.navigationState);
      // Emit an event to the parent component
      this.nextStep(); // Directly call the store's action
    },
    prevSubStep() {
      // Emit an event to the parent component
      this.prevStep(); // Directly call the store's action
    },
  }
}
</script>