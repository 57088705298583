<template>
  <div id="app">
    <AppHeader />
    <main>
      <router-view></router-view>
    </main>
    <AppFooter />
    <noscript>
      <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WW5C2RH" height="0" width="0" style="display:none;visibility:hidden"></iframe>
    </noscript>
  </div>
</template>

<script>
import AppHeader from './components/Header.vue';
import AppFooter from './components/Footer.vue';


export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
  },
};
</script>

<style>
/* Add global styles here */
</style>
