<template>
 <RenterDashboardTopNav />

 <div class="renter-dashboard security-dashboard">
   <div class="main-dashboard container flex-container">
     <RenterDashboardSidebar />

     <div class="dashboard-content">
       <header class="mb-2">
         <h1 class="heading-2">Security</h1>
       </header>

       <div class="cards-container flex-container">
        <div class="card">
            <h2 class="heading-6">Login</h2>

            <form action="">
              <div class="form-group">
                <div class="form-field">
                  <!-- needs the requiremnts for the password that exists in signup -->
                  <label for="password">New password</label>
                  <input
                      :type="showPassword ? 'text' : 'password'"
                      v-model="password"
                      name="password"
                      placeholder="Secure123?!?@"
                      id="password"
                      maxlength="30"
                      required
                  >
                  <a href="javascript:void(0)" class="toggle-password" @click="togglePasswordVisibility">
                    <img
                        :src="showPassword ? hideIcon : showIcon"
                        :alt="showPassword ? 'Hide password' : 'Show password'"
                    >
                  </a>
                </div>
              </div>

              <div class="form-group mt-4">
                <input
                    type="text"
                    name="OTP"
                    placeholder="enter OTP"
                />
              </div>

              <div class="form-group mt-5">
                <button class="primary-btn">Update</button>
              </div>

              <div class="form-group">
                <div class="form-group default-checkbox mt-4" tabindex="0">
                <input
                    type="checkbox"
                    id="keep-log-in"
                    name="keep-log-in"
                />
                <label for="keep-log-in">
                  Keep me logged in.
                </label>
              </div>
              </div>
            </form>
        </div>

        <div class="card">
            <h2 class="heading-6">Login</h2>

            <div class="edit-info mt-4">
              <p>Password</p>

              <a href="#" class="edit-link">
                <img src="@/assets/svgs/edit.svg" alt="edit">
                Edit
              </a>
            </div>

            <div class="info-note mt-5">
              <img src="@/assets/svgs/info.svg" alt="info" />
              <p>To change your password you are required to enter OTP sent to your email.</p>
            </div>

            <form action="">
              <div class="form-group">
                <div class="form-group default-checkbox mt-4" tabindex="0">
                <input
                    type="checkbox"
                    id="keep-log-in-password-update"
                    name="keep-log-in-password-update"
                />
                <label for="keep-log-in-password-update">
                  Keep me logged in.
                </label>
              </div>
              </div>
            </form>
        </div>

        <div class="card">
          <h2 class="heading-6">Account</h2>

          <form action="">
            <div class="form-group mt-5">
              <p><strong>What prompted you to delete?</strong></p>

              <div class="flex-container custom-checkbox-container mt-4" role="group" aria-labelledby="deletion-reason">
                <div class="checkbox-container">
                  <input type="checkbox" id="rented-room" value="I’ve rented a room">

                  <label for="rented-room">
                    I’ve rented a room
                  </label>
                </div>

                <div class="checkbox-container">
                  <input type="checkbox" id="not-needed" value="No longer need Happipad’s services">

                  <label for="not-needed">
                    No longer need Happipad’s services
                  </label>
                </div>

                <div class="checkbox-container">
                  <input type="checkbox" id="other" value="Other">

                  <label for="other">
                    Other
                  </label>
                </div>
              </div>
            </div>

            <div class="form-group mt-5">
              <button class="primary-btn">Delete</button>
            </div>
          </form>
        </div>

        <div class="card">
          <h2 class="heading-6">Account</h2>

          <div class="edit-info mt-4 mb-3">
            <p>Delete your account</p>

            <a href="#" class="edit-link">
              <img src="@/assets/svgs/delete-blue.svg" alt="delete">
              Delete
            </a>
          </div>
        </div>
       </div>
     </div>

     <RenterDashboardBottomNav />
   </div>
 </div>
</template>


<script>
 import RenterDashboardSidebar from "@/components/RenterDashboardSidebar.vue";
 import RenterDashboardBottomNav from "@/components/RenterDashboardBottomNav.vue";
 import RenterDashboardTopNav from "@/components/RenterDashboardTopNav.vue";

 export default {
   name: "RenterSecurity",

   components: {
     RenterDashboardSidebar,
     RenterDashboardBottomNav,
     RenterDashboardTopNav
   },
   data() {
    return {
      password: '',
      passwordError: '',
      showPassword: false, // Toggles password visibility
      showIcon: require('@/assets/svgs/show-password.svg'), // Path to show icon
      hideIcon: require('@/assets/svgs/hide-password.svg'), // Path to hide icon
    };
  },
  methods: {
    // Toggle password visibility
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
  },
 };
</script>