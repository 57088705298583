<template>
  <div class="background-light-blue">
    <div class="container flex-container flex-col-2 host-profile-container">
      <div class="mobile-progress">
        <div class="mobile-progress-bar">
          <span class="current"></span>
          <span></span>
          <span></span>
        </div>

        <div class="mobile-progress-info">
          <div class="current-steps-container">
            <span class="steps-progress">1 / <span class="total-steps"></span> steps</span>
          </div>

          <button class="see-steps-btn" tabindex="0">See steps
            <img src="@/assets/svgs/chevron-down-blue.svg" alt="Chevron icon">
          </button>
        </div>

        <div class="sidebar hidden">
          <div class="sidebar-steps-container">
            <p class="heading-5">Steps</p>
            <ul class="steps">
              <!-- Main Step 1 -->
              <li class="step" :class="{ active: navigationState.currentStep === '1', completed: isStepCompleted('1') }" data-step-index="1" tabindex="0">
                <div class="progress-bar"><div class="progress-bar-fill" :style="{ height: getStepProgress('1') + '%' }"></div></div>

                <div class="step-content">
                  <span @click="goToStep('1', null)">Background</span>
                  <ul class="sub-steps" style="display:none;">
                    <li
                        class="sub-step"
                        v-for="subStepIndex in navigationState.subSteps['1']"
                        :key="subStepIndex"
                        :class="{ active: navigationState.currentSubStep === subStepIndex, completed: isSubStepCompleted('1', subStepIndex) }"
                        :data-sub-step-index="subStepIndex"
                        tabindex="0"
                        @click="goToStep('1', subStepIndex)"
                    >
                      {{ getSubStepLabel(subStepIndex) }}
                    </li>
                  </ul>
                </div>
              </li>

              <!-- Main Step 2 -->
              <li class="step" :class="{ active: navigationState.currentStep === '2', completed: isStepCompleted('2') }" data-step-index="2" tabindex="0">
                <div class="progress-bar"><div class="progress-bar-fill" :style="{ height: getStepProgress('2') + '%' }"></div></div>

                <div class="step-content">
                  <span @click="goToStep('2', null)">Lifestyle</span>
                  <ul class="sub-steps" style="display:none;">
                    <li
                        class="sub-step"
                        v-for="subStepIndex in navigationState.subSteps['2']"
                        :key="subStepIndex"
                        :class="{ active: navigationState.currentSubStep === subStepIndex, completed: isSubStepCompleted('2', subStepIndex) }"
                        :data-sub-step-index="subStepIndex"
                        tabindex="0"
                        @click="goToStep('2', subStepIndex)"
                    >
                      {{ getSubStepLabel(subStepIndex) }}
                    </li>
                  </ul>
                </div>
              </li>

              <!-- Main Step 3 -->
              <li class="step" :class="{ active: navigationState.currentStep === '3', completed: isStepCompleted('3') }" data-step-index="3" tabindex="0">
                <div class="progress-bar"><div class="progress-bar-fill" :style="{ height: getStepProgress('3') + '%' }"></div></div>

                <div class="step-content">
                  <span @click="goToStep('3', null)">Habits</span>
                  <ul class="sub-steps" style="display:none;">
                    <li
                        class="sub-step"
                        v-for="subStepIndex in navigationState.subSteps['3']"
                        :key="subStepIndex"
                        :class="{ active: navigationState.currentSubStep === subStepIndex, completed: isSubStepCompleted('3', subStepIndex) }"
                        :data-sub-step-index="subStepIndex"
                        tabindex="0"
                        @click="goToStep('3', subStepIndex)"
                    >
                      {{ getSubStepLabel(subStepIndex) }}
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>

          <div class="progress-info">
            <div class="current-steps-container">
              <span class="steps-progress">{{navigationState.currentStep}} / {{navigationState.steps.length}}<span class="total-steps"></span> steps</span>
            </div>

            <button class="hide-steps-btn" tabindex="0">Hide steps
              <img src="@/assets/svgs/chevron-up-blue.svg" alt="Chevron icon">
            </button>
          </div>
        </div>
      </div>

      <div class="form-container">
        <form action="#" method="POST" id="host-form">
          <StepOne v-if="navigationState.currentStep === '1'"/>
          <StepTwo v-if="navigationState.currentStep === '2'"/>
          <StepThree v-if="navigationState.currentStep === '3'"/>
        </form>
      </div>
    </div>
  </div>
</template>


<script>
import {reactive} from "vue";
import StepOne from "@/views/host/profile/Step1/StepOne.vue";
import StepTwo from "@/views/host/profile/Step2/StepTwo.vue";
import StepThree from "@/views/host/profile/Step3/StepThree.vue";
import stepProgressMixin from "@/mixins/stepNavigationMixin";

export default {
  name: "CreateProfile",
  mixins: [stepProgressMixin],
  data() {
    return {
      navigationState: reactive({
        currentStep: '1',
        currentSubStep: '1.1',
        steps: ['1', '2', '3'],
        subSteps: {
          '1': ['1.1', '1.2'],
          '2': ['2.1', '2.2', '2.3', '2.4', '2.5', '2.6', '2.7'],
          '3': ['3.1', '3.2'],
        },
        labelKeys: {
          '1.1': 'home_type',
          '1.2': 'rental_space_type',
          '2.1': 'rental_space_type',
          '2.2': 'rental_space_type',
          '2.3': 'rental_space_type',
          '2.4': 'rental_space_type',
          '2.5': 'rental_space_type',
          '2.6': 'rental_space_type',
          '2.7': 'rental_space_type',
        },
      })
    };
  },
  provide(){
    return {
      navigationState: this.navigationState
    };
  },
  components: { StepOne, StepTwo, StepThree }
}
</script>


<style scoped>
.progress-bar-fill {
  background-color: #4969AF; /* The fill color you want */
  height: 0%; /* We'll adjust this dynamically */
  transition: width 0.3s ease; /* Smooth transition */
  border-radius:10px; /* Adjust radius as needed */
}
</style>