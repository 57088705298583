<template>
  <div class="form-step active" data-step="4.4">
    <div>
      <h1 class="heading-2">Set your calendar.</h1>
      <p class="font-size-medium">When your listing becomes available?</p>
    </div>

    <div class="form-group ios-input-fix">
      <label for="start-date">Start date</label>
      <div class="input-container">
        <flat-pickr
            v-model="formData.available_from"
            @onChange="handleAvailableFromChange"
        />
        <img class="input-icon calendar-icon" src="@/assets/svgs/calendar.svg" alt="calendar icon">
      </div>

      <div class="info-note mt-4">
        <img src="@/assets/svgs/info.svg" alt="Info">
        <p>If you wish to block your calendar during specific days, you can do it with <strong>Blackout dates</strong>.</p>
      </div>

      <div class="blackout-dates-section">
        <div id="blackoutDatesContainer">
          <div
              class="input-container blackout-date-bloc"
              v-for="(date, index) in blackoutDates"
              :key="index"
          >
            <div class="date-picker-wrapper">
              <flat-pickr
                  v-model="date.dateRange"
                  :config="getDateConfig"
                  class="date-input"
                  range
                  @change="handleDateRangeChange($event, index)"
              />
              <img class="input-icon calendar-icon" src="@/assets/svgs/calendar.svg" alt="calendar icon">
            </div>
            <button
                @click="removeDate(index)"
                class="remove-date-btn"
                type="button"
            >
              <span class="sr-only">Remove Date</span>
              ×
            </button>
          </div>
        </div>

        <a
            href="#"
            id="addBlackoutDate"
            class="add-blackout-date-btn mt-4 secondary-btn small-btn"
            @click.prevent="addNewDate"
        >
          <img src="@/assets/svgs/blackout-dates-blue.svg" alt="Calendar">
          Add blackout dates
        </a>
      </div>
    </div>

    <div class="btns-container">
      <button type="button" class="secondary-btn prev-step" @click="prevSubStep">
        Back
      </button>
      <button type="button" class="primary-btn next-step" @click="nextSubStep" :class="{'btn-disabled': !isValid}" :disabled="!isValid">
        Next
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import stepNavigationMixin from "@/mixins/stepNavigationMixin";
import { createListingStore } from "@/stores/host/listing/createListingStore";
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

export default {
  name: "SubStep4_4",
  inject: ['navigationState'],
  mixins: [stepNavigationMixin],
  computed: {
    ...mapState(createListingStore, ['formData']),
    getDateConfig() {
      return {
        ...this.dateConfig,
        minDate: this.formData.available_from || 'today'
      }
    },
    formattedBlackoutDates() {
      return this.blackoutDates.map(date => ({
        from_date: date.from_date,
        to_date: date.to_date
      })).filter(date => date.from_date && date.to_date);
    },
    isValid() {
      if (!this.formData.available_from) {
        return false;
      }
      const date = new Date(this.formData.available_from);
      return !isNaN(date.getTime());
    }
  },
  components: {
    flatPickr
  },
  data() {
    return {
      blackoutDates: [], // Will contain objects with from_date and to_date
      dateConfig: {
        mode: 'range',
        dateFormat: 'Y-m-d',
        conjunction: ' to ',
      }
    }
  },
  watch: {
    formattedBlackoutDates: {
      handler(newDates) {
        // Emit the formatted dates whenever they change
        this.$emit('update:dates', newDates)
        // Update the formData if needed
        this.formData.blackout_dates = newDates;
      },
      deep: true
    }
  },
  methods: {
    ...mapActions(createListingStore, ['saveDraft']),
    nextSubStep() {
      this.saveDraft(this.navigationState);
      this.nextStep();
    },
    prevSubStep() {
      this.prevStep();
    },
    addNewDate() {
      this.blackoutDates.push({
        dateRange: null,
        from_date: '',
        to_date: ''
      });
    },
    removeDate(index) {
      this.blackoutDates.splice(index, 1);
    },
    handleDateRangeChange(selectedDates, index) {
      if (selectedDates.length === 2) {
        this.blackoutDates[index] = {
          ...this.blackoutDates[index],
          dateRange: selectedDates,
          from_date: this.formatDate(selectedDates[0]),
          to_date: this.formatDate(selectedDates[1])
        };
      }
    },
    formatDate(date) {
      // Format date to YYYY-MM-DD
      return date.toISOString().split('T')[0];
    },
    handleAvailableFromChange() {
      const availableFromDate = new Date(this.formData.available_from);

      // Filter out invalid dates
      this.blackoutDates = this.blackoutDates.filter(date => {
        if (!date.from_date) return true;
        return new Date(date.from_date) >= availableFromDate;
      });
    }
  }
}
</script>


<style scoped>
.blackout-dates-section {
  width: 100%;
}

.input-container {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.date-picker-wrapper {
  position: relative;
  flex-grow: 1;
}

.date-input {
  width: 100%;
  padding: 0.5rem 2rem 0.5rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
}
.blackout-date-bloc{
  margin-top:10px;
}
.remove-date-btn {
  margin-left: 0.5rem;
  padding: 0.25rem 0.5rem;
  background-color: #ef4444;
  color: white;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  font-size: 1.25rem;
  line-height: 1;
}

.remove-date-btn:hover {
  background-color: #dc2626;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
</style>

