<template>
  <div class="form-step active">
    <div>
      <h2 class="heading-2">What is your schedule? (optional)</h2>

      <p class="font-size-medium">Select all that applies.</p>
    </div>

    <div class="form-group">
      <div class="flex-container custom-checkbox-container" role="group" aria-labelledby="schedule-label">
        <div class="checkbox-container">
          <input type="checkbox" id="home-to-sleep" v-model="formData.host_schedule" value="home-to-sleep" aria-labelledby="home-to-sleep-label">

          <label for="home-to-sleep" id="home-to-sleep-label">
            <div class="label-content">I’m home only to sleep</div>
          </label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="away" v-model="formData.host_schedule" value="away" aria-labelledby="away-label">

          <label for="away" id="away-label">
            <div class="label-content">Away business hours</div>
          </label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="wfh" v-model="formData.host_schedule" value="wfh" aria-labelledby="wfh-label">

          <label for="wfh" id="wfh-label">
            <div class="label-content">Work from home</div>
          </label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="other-schedule" v-model="formData.host_schedule" value="other-schedule" aria-labelledby="other-schedule-label">

          <label for="other-schedule" id="other-schedule-label">
            <div class="label-content">Other</div>
          </label>
        </div>
      </div>

      <div class="form-group mt-5" style="display:none;" id="other-schedule-container">
        <label class="heading-6" for="other-schedule-description">Describe your schedule</label>

        <input type="text" name="other-schedule-description" placeholder="Start typing.." id="other-schedule-description">
      </div>
    </div>

    <div class="btns-container">
      <button type="button" class="secondary-btn prev-step" @click.prevent="prevSubStep">Back</button>
      <button type="button" class="primary-btn next-step" @click.prevent="nextSubStep">Next</button>
    </div>
  </div>
</template>


<script>
import {mapState} from "pinia";
import stepNavigationMixin from "@/mixins/stepNavigationMixin";
import { useCreateHostProfile } from "@/stores/host/profile/useCreateHostProfile";


export default {
  name: "SubStep2_2",
  mixins: [stepNavigationMixin],
  inject: ['navigationState'],
  computed: {
    ...mapState(useCreateHostProfile, ['formData'])
  },
  methods: {
    nextSubStep() {
      this.nextStep();
    },
    prevSubStep() {
      this.prevStep();
    }
  }
}
</script>


<style scoped>

</style>