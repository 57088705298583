<template>
  <div class="form-step active" data-step="5">
    <div>
      <h1 class="heading-2">Review.</h1>

      <p class="font-size-medium">Is everything correct? Confirm before publishing.</p>
    </div>

    <!-- Price Section -->
    <div class="review-section">
      <div class="review-header">
        <h2 class="heading-6">Price</h2>

        <a href="#" class="secondary-btn small-btn" @click.prevent="edit('4', '4.2')">
          <img src="@/assets/svgs/edit.svg" alt="edit">
          Edit
        </a>
      </div>

      <div class="review-content">
        <p class="price">
          <img src="@/assets/svgs/dollar-sign.svg" alt="dollar sign">
          <strong>{{ formatPrice(formData.price) }}</strong>
        </p>

        <p v-if="formData.utilities_included === true">Including utilities</p>
        <p v-else> Utilities not included</p>
      </div>
    </div>

    <!-- Type of Space Section -->
    <div class="review-section">
      <div class="review-header">
        <h2 class="heading-6">Type of space</h2>

        <a href="#" class="secondary-btn small-btn" @click.prevent="edit('2', '2.2')">
          <img src="@/assets/svgs/edit.svg" alt="edit">
          Edit
        </a>
      </div>

      <div class="review-content">
        <p v-if="formData.space_type === 'bed_in_shared_room'">
          <img src="@/assets/svgs/bed.svg" alt="bed">
          Bed in a shared room
        </p>
        <p v-if="formData.space_type === 'private_room'">
          <img src="@/assets/svgs/private-room.svg" alt="bed">
          private room
        </p>
        <p v-if="formData.space_type === 'entire_space'">
          <img src="@/assets/svgs/entire-space.svg" alt="bed">
          Entire space
        </p>
        <p v-if="formData.space_type === 'separate_unit'">
          <img src="@/assets/svgs/separate-unit.svg" alt="bed">
          Separate unit
        </p>
      </div>
    </div>

    <!-- Bedroom Furniture Section -->
    <div class="review-section">
      <div class="review-header">
        <h2 class="heading-6">Bedroom furniture</h2>
        <a href="#" class="secondary-btn small-btn" @click.prevent="edit('2', '2.5')">
          <img src="@/assets/svgs/edit.svg" alt="edit"> Edit
        </a>
      </div>

      <div class="review-content">
        <p v-if="formData.furnishing === 'fully_furnished'">Fully furnished</p>
        <p v-if="formData.furnishing === 'partly_furnished'">Partly furnished</p>
        <p v-if="formData.furnishing === 'unfurnished'">Unfurnished</p>

        <p v-for="(element, index) in displayedElements" :key="index">
          <img :src="getBedroomFurnishing(element)" :alt="capitalize(element)">
          {{ capitalize(element) }}
        </p>

        <a href="#" class="view-more" @click.prevent="toggleShowMore" v-if="formData.bedroom_furnishings.length > 5">
          {{ showMore ? 'View less' : 'View more' }}
          <img :src="showMore ? require('@/assets/svgs/chevron-up-blue.svg') : require('@/assets/svgs/chevron-down-blue.svg')"
               :alt="showMore ? 'chevron up' : 'chevron down'">
        </a>
      </div>
    </div>

    <!-- Property Photos Section -->
    <div class="review-section">
      <div class="review-header">
        <h2 class="heading-6">Property photos</h2>
        <a href="#" class="secondary-btn small-btn" @click.prevent="edit('4', '4.3')">
          <img src="@/assets/svgs/edit.svg" alt="edit"> Edit
        </a>
      </div>

      <div class="review-content">
        <div class="property-photos">
          <div class="property-photo" v-for="(fileData, index) in formData.photos" :key="index">
            <button class="delete-button" @click="removePhoto(index)" aria-label="Delete photo">
              <img src="@/assets/svgs/delete.svg" alt="delete" />
            </button>
            <img class="property-image" :src="fileData.originalDataURL" alt="Property Photo" />
          </div>
        </div>
      </div>

    </div>

    <!-- Address Section -->
    <div class="review-section">
      <div class="review-header">
        <h2 class="heading-6">Address</h2>

        <a href="#" class="secondary-btn small-btn" @click.prevent="edit('1', null)">
          <img src="@/assets/svgs/edit.svg" alt="edit"> Edit
        </a>
      </div>

      <div class="review-content">
        <p>
          <img src="@/assets/svgs/house-address.svg" alt="house address">
          {{ formData.address }}
          </p>
      </div>
    </div>

    <!-- Emergency Housing Section -->
    <div class="review-section">
      <div class="review-header">
        <h2 class="heading-6">Emergency housing</h2>

        <a href="#" class="secondary-btn small-btn">
          <img src="@/assets/svgs/edit.svg" alt="edit"> Edit
        </a>
      </div>

      <div class="review-content">
        <p>No</p>
      </div>
    </div>

    <!-- Type of Building Section -->
    <div class="review-section">
      <div class="review-header">
        <h2 class="heading-6">Type of building</h2>

        <a href="#" class="secondary-btn small-btn" @click.prevent="edit('2', '2.2')">
          <img src="@/assets/svgs/edit.svg" alt="edit"> Edit
        </a>
      </div>

      <div class="review-content">
        <p v-if="formData.property_type === 'house'">
          <img src="@/assets/svgs/house.svg" alt="townhouse">
          House
        </p>
        <p v-if="formData.property_type === 'mobile_home'">
          <img src="@/assets/svgs/mobile-home.svg" alt="townhouse">
          Mobile home
        </p>
        <p v-if="formData.property_type === 'apartment'">
          <img src="@/assets/svgs/apartment.svg" alt="townhouse">
          Apartment/condo
        </p>
        <p v-if="formData.property_type === 'townhouse'">
          <img src="@/assets/svgs/townhouse.svg" alt="townhouse">
          Townhouse/duplex
        </p>
        <p v-if="formData.property_type === 'other'">
          <img src="@/assets/svgs/other-house.svg" alt="townhouse">
          Other
        </p>
      </div>
    </div>

    <!-- Renter’s Bathroom Section -->
    <div class="review-section">
      <div class="review-header">
        <h2 class="heading-6">Renter’s bathroom</h2>

        <a href="#" class="secondary-btn small-btn" @click.prevent="edit('2', '2.6')">
          <img src="@/assets/svgs/edit.svg" alt="edit"> Edit
        </a>
      </div>

      <div class="review-content">
        <p v-for="(bathroomKey, index) in formData.bathroom" :key="index">
          {{ bathrooms(bathroomKey) }}
        </p>
      </div>
    </div>

    <!-- Shared Spaces Section -->
    <div class="review-section">
      <div class="review-header">
        <h2 class="heading-6">Shared spaces</h2>

        <a href="#" class="secondary-btn small-btn" @click.prevent="edit('2', '2.7')">
          <img src="@/assets/svgs/edit.svg" alt="edit"> Edit
        </a>
      </div>


      <div class="review-content">
        <p v-for="(space, index) in formData.shared_spaces" :key="index">
          <img :src="getSpaceImage(space)" :alt="capitalize(space)">
          {{ capitalize(space.replace('_', ' ')) }}
        </p>
      </div>
    </div>

    <!-- Who lives at the place Section -->
    <div class="review-section">
      <div class="review-header">
        <h2 class="heading-6">Who lives at the place</h2>

        <a href="#" class="secondary-btn small-btn" @click.prevent="edit('2', '2.8')">
          <img src="@/assets/svgs/edit.svg" alt="edit"> Edit
        </a>
      </div>

      <div class="review-content">
        <p>{{ formData.number_people }} {{ formData.number_people > 1 ? 'residents' : 'resident' }}</p>

        <p>{{ formData.number_pets }} {{ formData.number_pets > 1 ? 'pets' : 'pet' }} {{ capitalize(formData.owner_pets.join(', ')) }}</p>

<!--        <p>“We have 2 amazing cats and a dog.”</p>-->

        <div class="photos-container">
          <img class="property-image" v-for="(pet, index) in formData.house_hold_pets_photos" :key="index" :src="pet.preview" alt="our pets">
        </div>
      </div>
    </div>

    <!-- What is provided Section -->
    <div class="review-section">
      <div class="review-header">
        <h2 class="heading-6">What is provided</h2>

        <a href="#" class="secondary-btn small-btn">
          <img src="@/assets/svgs/edit.svg" alt="edit"> Edit
        </a>
      </div>

      <div class="review-content">
        <p>
          <img src="@/assets/svgs/dish-detergent.svg" alt="Dish detergent">
          Dish detergent</p>

        <p>
          <img src="@/assets/svgs/toilet-paper.svg" alt="Toilet paper">
          Toilet paper</p>
      </div>
    </div>

    <!-- Building facilities Section -->
    <div class="review-section">
      <div class="review-header">
        <h2 class="heading-6">Building facilities</h2>

        <a href="#" class="secondary-btn small-btn">
          <img src="@/assets/svgs/edit.svg" alt="edit"> Edit
        </a>
      </div>

      <div class="review-content">
        <p>
          <img src="@/assets/svgs/onsite-managment.svg" alt="Onsite management">
          Onsite management</p>

        <p>
          <img src="@/assets/svgs/elevator.svg" alt="Elevator">
          Elevator</p>
      </div>
    </div>

    <!-- Amenities Section -->
    <div class="review-section">
      <div class="review-header">
        <h2 class="heading-6">Amenities</h2>

        <a href="#" class="secondary-btn small-btn" @click.prevent="edit('2', '2.11')">
          <img src="@/assets/svgs/edit.svg" alt="edit"> Edit
        </a>
      </div>

      <div class="review-content">
        <p v-for="(amenity, index) in formData.amenities" :key="index">
          <img :src="getAmenityImage(amenity)" :alt="capitalize(amenity)">
          {{ capitalize(amenity) }}
        </p>
      </div>
    </div>

    <!-- Safety features Section -->
    <div class="review-section">
      <div class="review-header">
        <h2 class="heading-6">Safety features</h2>

        <a href="#" class="secondary-btn small-btn" @click.prevent="edit('2', '2.12')">
          <img src="@/assets/svgs/edit.svg" alt="edit"> Edit
        </a>
      </div>

      <div class="review-content">
        <p v-for="(safetyFeature, index) in formData.safety_features" :key="index">
          <img :src="getSafetyFeature(safetyFeature)" :alt="capitalize(safetyFeature)">
          {{ capitalize(safetyFeature) }}
        </p>
      </div>
    </div>

    <!-- Bedroom size Section -->
    <div class="review-section">
      <div class="review-header">
        <h2 class="heading-6">Bedroom size</h2>

        <a href="#" class="secondary-btn small-btn" @click.prevent="edit('2', '2.4')">
          <img src="@/assets/svgs/edit.svg" alt="edit"> Edit
        </a>
      </div>

      <div class="review-content">
        <p> {{ getSpaceSize('space_size_' + formData.space_size) }} </p>
      </div>
    </div>


    <!-- House Rules Section -->
    <div class="review-section">
      <div class="review-header">
        <h2 class="heading-6">House rules</h2>
        <a href="#" class="secondary-btn small-btn" @click.prevent="edit('3', '3.2')">
          <img src="@/assets/svgs/edit.svg" alt="edit"> Edit
        </a>
      </div>

      <div class="review-content">
        <p>
          <img src="@/assets/svgs/no-animals.svg" alt="no animals">
          No animals allowed
        </p>

        <p>
          <img src="@/assets/svgs/no-smoking.svg" alt="no smoking">
          No smoking inside
        </p>

        <p>
          <img src="@/assets/svgs/policy-on-visitor.svg" alt="Policy on visitor’s stay">
          Policy on visitor’s stay
        </p>

        <p>
          <img src="@/assets/svgs/keep-space-clean.svg" alt="keep space clean">
          Keep spaces clean
        </p>

        <p>
          <img src="@/assets/svgs/scent-free.svg" alt="scent free">
          Scent free
        </p>

        <p style="display: block;"><strong>Additional info:</strong> <br>Coin laundry can be used only in the mornings. <br>Cleaning 2 times per week.</p>
      </div>
    </div>

    <!-- Who do you want to host Section -->
    <div class="review-section">
      <div class="review-header">
        <h2 class="heading-6">Who do you want to host?</h2>
        <a href="#" class="secondary-btn small-btn" @click.prevent="edit('2', '2.3')">
          <img src="@/assets/svgs/edit.svg" alt="edit"> Edit
        </a>
      </div>

      <div class="review-content">
        <p v-for="(preferredHost, index) in formData.preferred_host" :key="index">
          <img :src="getPreferredHost(preferredHost)" :alt="preferredHost">
          {{ capitalize(preferredHost) }}
        </p>
      </div>
    </div>

    <!-- Let's Make Your Listing Unique Section -->
    <div class="review-section">
      <div class="review-header">
        <h2 class="heading-6">Let's make your listing unique.</h2>

        <a href="#" class="secondary-btn small-btn">
          <img src="@/assets/svgs/edit.svg" alt="edit" @click.prevent="edit('4', '4.1')"> Edit
        </a>
      </div>

      <div class="review-content">
        <p><strong>Title</strong></p>

        <p>{{ formData.title }}</p>

        <p><strong>Description</strong></p>

        <p>{{ formData.description }}</p>
      </div>
    </div>

    <!-- Your Calendar Section -->
    <div class="review-section">
      <div class="review-header">
        <h2 class="heading-6">Your calendar</h2>

        <a href="#" class="secondary-btn small-btn" @click.prevent="edit('4', '4.4')">
          <img src="@/assets/svgs/edit.svg" alt="edit"> Edit
        </a>
      </div>

      <div class="review-content">
        <div class="flex-justify-between">
          <p>
            <img src="@/assets/svgs/calendar.svg" alt="calendar">
            Available from
          </p>

          <p class="text-align-right">04/12/2024</p>
        </div>

        <div class="flex-justify-between">
          <p>
            <img src="@/assets/svgs/blackout-dates-dark.svg" alt="blackout dates">
            Not available
          </p>

          <p class="text-align-right">
            from: 05/04/2024
            <br>
            to: 20/04/2024</p>
        </div>

        <div class="flex-justify-between">
          <p>
            <img src="@/assets/svgs/blackout-dates-dark.svg" alt="blackout dates">
            Not available
          </p>

          <p class="text-align-right">
            from: 05/04/2024
            <br>
            to: 20/04/2024</p>
        </div>
      </div>
    </div>

    <div class="btns-container">
      <button type="button" class="primary-btn next-step" @click.prevent="submitForm()" :disabled="isSubmitting">Submit</button>
    </div>

  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { createListingStore } from '@/stores/host/listing/createListingStore'; // Adjust the path to your store
import http from '@/http';
import stepNavigationMixin from "@/mixins/stepNavigationMixin";

export default {
  name: 'StepFive',
  inject: ['navigationState'],
  mixins: [stepNavigationMixin],
  data() {
    return {
      isSubmitting: false,
      showMore: false,
      submissionError: '',
      photoPreviews: [],
    };
  },
  computed: {
    ...mapState(createListingStore, ['formData']),
    displayedElements() {
      return this.showMore ? this.formData.bedroom_furnishings : this.formData.bedroom_furnishings.slice(0, 5);
    }
  },
  watch: {
    // Watch for changes in formData.photos to update photoPreviews
    'formData.photos': {
      handler() {
        this.generatePhotoPreviews();
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(createListingStore, ['updateFormData']),
    generatePhotoPreviews() {
      // Use the originalDataURL from formData.photos
      this.photoPreviews = this.formData.photos.map((fileData) => {
        return fileData.originalDataURL;
      });
    },
    removePhoto(index) {
      // Remove the photo from formData.photos
      const updatedPhotos = [...this.formData.photos];
      updatedPhotos.splice(index, 1);

      // Update the store
      this.updateFormData({ photos: updatedPhotos });

      // Update photoPreviews
      this.generatePhotoPreviews();
    },
    edit(step, subStep) {
      this.goToStep(step, subStep);
    },
    async submitForm() {
      try {
        this.isSubmitting = true;
        this.submissionError = '';

        // Create a FormData object
        const formData = new FormData();

        // Append all form data except photos and pet_photos
        for (const key in this.formData) {
          if (key !== 'photos' && key !== 'pet_photos') {
            const value = this.formData[key];

            // Handle arrays (e.g., amenities, facilities)
            if (Array.isArray(value)) {
              value.forEach((item) => {
                formData.append(`${key}[]`, item);
              });
            } else if (typeof value === 'object' && value !== null) {
              // For objects, stringify them
              formData.append(key, JSON.stringify(value));
            } else {
              formData.append(key, value);
            }
          }
        }

        // Append property photos
        if (this.formData.photos && this.formData.photos.length > 0) {
          this.formData.photos.forEach((fileData) => {
            const dataURL = fileData.originalDataURL;
            const blob = this.dataURLtoBlob(dataURL);
            const file = new File([blob], fileData.name, {
              type: fileData.type,
              lastModified: fileData.lastModified,
            });

            formData.append('photos[]', file);
          });
        }

        // Append pet photos
        if (this.formData.pet_photos && this.formData.pet_photos.length > 0) {
          this.formData.pet_photos.forEach((file) => {
            formData.append('pet_photos[]', file);
          });
        }

        // Send the FormData to the server using your custom axios instance
        const response = await http.post('/api/properties', formData);

        // Handle success
        console.log('Form submitted successfully:', response.data);
        // You can redirect the user or show a success message

      } catch (error) {
        // Handle error
        console.error('Error submitting form:', error);
        this.submissionError = 'An error occurred while submitting the form. Please try again.';
      } finally {
        this.isSubmitting = false;
      }
    },
    dataURLtoBlob(dataURL) {
      // Convert base64/URLEncoded data component to raw binary data held in a string
      const byteString = atob(dataURL.split(',')[1]);
      const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];

      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ia], { type: mimeString });
    },
    formatPrice(value) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      });
      return formatter.format(value);
    },
    bathrooms(key) {
      const bathrooms = {
        "shared_half": "Shared half bath",
        "shared_full": "Shared full bath",
        "private_half": "Private half bath",
        "private_full": "Private full bath"
      };
      return bathrooms[key] || 'Unknown bath type';
    },
    getSpaceImage(space) {
      const images = {
        kitchen: require('@/assets/svgs/kitchen.svg'),
        backyard: require('@/assets/svgs/backyard.svg'),
        patio: require('@/assets/svgs/patio.svg'),
        office: require('@/assets/svgs/office.svg'),
        den: require('@/assets/svgs/den.svg'),
        living_room: require('@/assets/svgs/living-room.svg'),
        other: require('@/assets/svgs/other-shared-space.svg')
      };
      return images[space]; // Fallback image
    },
    getAmenityImage(amenity) {
      const images = {
        wifi: require('@/assets/svgs/wifi.svg'),
        dryer: require('@/assets/svgs/dryer.svg'),
        street_parking: require('@/assets/svgs/parking.svg'),
        free_parking: require('@/assets/svgs/parking.svg'),
        swimming_pool: require('@/assets/svgs/swimming-pool.svg'),
        window_covering: require('@/assets/svgs/window-covering.svg'),
        microwave: require('@/assets/svgs/microwave.svg'),
        fridge: require('@/assets/svgs/fridge.svg'),
        iron: require('@/assets/svgs/iron.svg'),
        air_conditioner: require('@/assets/svgs/air-conditioner.svg'),
        washer: require('@/assets/svgs/washer.svg'),
        kitchenware: require('@/assets/svgs/kitchenware.svg'),
        stove_and_oven: require('@/assets/svgs/stove-oven.svg'),
        dishwasher: require('@/assets/svgs/dishwasher.svg'),
        blow_dryer: require('@/assets/svgs/blow-dryer.svg'),
        tv: require('@/assets/svgs/tv.svg'),
        hot_tub: require('@/assets/svgs/hot-tub.svg'),
        coffee_machine: require('@/assets/svgs/coffee-machine.svg'),
      };
      return images[amenity];
    },
    getSafetyFeature(feature) {
      const images = {
        smoke_detector: require('@/assets/svgs/smoke-detector.svg'),
        carbon_monoxide_detector: require('@/assets/svgs/carbon-monoxide-detector.svg'),
        fire_extinguisher: require('@/assets/svgs/fire-extinguisher.svg'),
        security_alarm: require('@/assets/svgs/security-alarm.svg'),
        first_aid_kit: require('@/assets/svgs/first-aid-kit.svg'),
        door_lock: require('@/assets/svgs/door-lock.svg'),
      };
      return images[feature];
    },
    getSpaceSize(size) {
      const spaceSizes = {
        "space_size_small": "Small up to 100 sqft",
        "space_size_medium": "Medium up to 200 sqft",
        "space_size_large": "Large up to 300 sqft",
        "space_size_extra_large": "Extra large more than 300 sqft"
      };
      return spaceSizes[size] || 'Unknown space size';
    },
    getPreferredHost(preferredHost) {
      const preferredToHost = {
        disabled_people: require('@/assets/svgs/disabled-people.svg'),
        refugees: require('@/assets/svgs/refugees.svg'),
        students: require('@/assets/svgs/students.svg'),
        seniors: require('@/assets/svgs/seniors.svg'),
        working_professionals: require('@/assets/svgs/working-professionals.svg'),
        no_preference: require('@/assets/svgs/no-preference.svg'),
      };
      return preferredToHost[preferredHost];
    },
    getBedroomFurnishing(furnishing) {
      const bedroomFurnishing = {
        pillows: require('@/assets/svgs/pillows.svg'),
        bedding: require('@/assets/svgs/bedding.svg'),
        blankets: require('@/assets/svgs/blankets.svg'),
        towels: require('@/assets/svgs/towels.svg'),
        desk: require('@/assets/svgs/desk.svg'),
        closet: require('@/assets/svgs/closet.svg'),
        drawers: require('@/assets/svgs/drawers.svg'),
        chair: require('@/assets/svgs/chair.svg'),
        lamp: require('@/assets/svgs/lamp.svg'),
        hangers: require('@/assets/svgs/hangers.svg'),
        tv: require('@/assets/svgs/tv.svg'),
        fridge: require('@/assets/svgs/fridge.svg'),
        curtains: require('@/assets/svgs/curtains.svg'),
      };
      return bedroomFurnishing[furnishing];
    },
    capitalize(text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
    toggleShowMore() {
      this.showMore = !this.showMore;
    },
  },
};
</script>



<style scoped>
.property-photos {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
}

.property-photo {
  position: relative;
}

.property-image {
  width: 100px;
  height: 100px;
  display: block;
  border-radius: 4px;
}

.delete-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  z-index: 1;
}

.delete-button img {
  width: 24px;
  height: 24px;
}
</style>


