<template>
 <RenterDashboardTopNav />

 <div class="renter-dashboard mystay-dashboard">
   <div class="main-dashboard container flex-container">
     <RenterDashboardSidebar />

     <div class="dashboard-content">
       <header class="mb-2">
         <h1 class="heading-2">My stay</h1>
       </header>

       <div class="cards-container flex-container">
         <a href="#" class="card mystay-details">
          <div class="property-details">
            <div class="property-header">
              <img src="@/assets/images/mystay-placeholder.jpeg" alt="Property Image" class="property-image" />

              <div class="property-info">
                <div class="info-item">
                  <img src="@/assets/svgs/clock-outline.svg" alt="clock" class="icon" />

                  <div class="info-item__content">
                    <p>Dates</p>
                    <p><strong>11/02/2024 - 05/02/2025</strong></p>
                  </div>
                </div>

                <div class="info-item">
                  <img src="@/assets/svgs/money-outline.svg" alt="money" class="icon" />

                  <div class="info-item__content">
                    <p>Price</p>
                    <p><strong>$1500.00/mo</strong></p>
                  </div>
                </div>

                <div class="info-item">
                  <img src="@/assets/svgs/address-outline.svg" alt="address" class="icon" />

                  <div class="info-item__content">
                    <p>Address</p>
                    <p><strong>565 Lakeshore blv, Toronto, ON, M6E 3F7</strong></p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="action-buttons">
            <button class="action-button">
              <div>
                <img src="@/assets/svgs/manage-outline.svg" alt="manage" class="icon" />

                Manage insurance
              </div>

              <img src="@/assets/svgs/chevron-right-dark.svg" alt="chevron" class="icon" />
            </button>

            <button class="action-button">
              <div>
                <img src="@/assets/svgs/contract-outline.svg" alt="contract" class="icon" />

                View contract
              </div>

              <img src="@/assets/svgs/chevron-right-dark.svg" alt="chevron" class="icon" />
            </button>

            <button class="action-button">
              <div>
                <img src="@/assets/svgs/receipt-outline.svg" alt="receipt" class="icon" />

                Request receipt
              </div>

              <img src="@/assets/svgs/chevron-right-dark.svg" alt="chevron" class="icon" />
            </button>
          </div>
         </a>

         <a href="#" class="card rental-payments">
          <div class="payments-header">
            <p><strong>Rental payments</strong></p>
          </div>

          <div class="payment-item">
            <div class="info-item">
              <img src="@/assets/svgs/card-outline.svg" alt="card" class="icon" />

              <div class="info-item__content">
                <p>Rent for Nov, 2024</p>
                <p><strong>$ 1 500.00</strong></p>
              </div>
            </div>

            <span class="status-badge status-badge--green">Paid</span>
          </div>

          <div class="payment-item">
            <div class="info-item">
              <img src="@/assets/svgs/card-outline.svg" alt="card" class="icon" />

              <div class="info-item__content">
                <p>Rent for Dec, 2024</p>
                <p><strong>$ 1 500.00</strong></p>
              </div>
            </div>

            <img src="@/assets/svgs/chevron-right-dark.svg" alt="chevron" class="icon" />
          </div>

          <div class="payment-item">
            <div class="info-item">
              <img src="@/assets/svgs/card-outline.svg" alt="card" class="icon" />

              <div class="info-item__content">
                <p>Rent for Jan, 2024</p>
                <p><strong>$ 1 500.00</strong></p>
              </div>
            </div>

            <img src="@/assets/svgs/chevron-right-dark.svg" alt="chevron" class="icon" />
          </div>

          <div class="payment-item">
            <div class="info-item">
              <img src="@/assets/svgs/card-outline.svg" alt="card" class="icon" />

              <div class="info-item__content">
                <p>Rent for Feb, 2025</p>
                <p><strong>$ 1 500.00</strong></p>
              </div>
            </div>

            <img src="@/assets/svgs/chevron-right-dark.svg" alt="chevron" class="icon" />
          </div>
         </a>

         <div class="listing-card hidden listing-card--empty text-align-center">
          <p>Hey Jay! No stays booked</p>

          <img src="@/assets/svgs/no-listings.svg" alt="">

          <a href="#" class="primary-btn">
            <img src="@/assets/svgs/house-outline.svg" alt="house">
            Find a place
          </a>
        </div>
       </div>
     </div>

     <RenterDashboardBottomNav />
   </div>
 </div>
</template>


<script>
 import RenterDashboardSidebar from "@/components/RenterDashboardSidebar.vue";
 import RenterDashboardBottomNav from "@/components/RenterDashboardBottomNav.vue";
 import RenterDashboardTopNav from "@/components/RenterDashboardTopNav.vue";

 export default {
   name: "RenterMyStay",

   components: {
     RenterDashboardSidebar,
     RenterDashboardBottomNav,
     RenterDashboardTopNav
   },
 };
</script>