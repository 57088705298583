<template>
  <div class="form-step active" data-step="4.1">
    <div>
      <h1 class="heading-2">Let’s make your listing unique.</h1>
    </div>

    <div class="form-group">
      <label for="listingTitle">Give your listing a title</label>

      <input type="text" v-model="formData.title" placeholder="Start typing.." id="listingTitle"
             required>

      <div id="listingTitleErrorText" class="validation-error"></div>

      <a href="#" class="mt-4 mb-5 secondary-btn small-btn" style="margin: auto;">
        <img src="@/assets/svgs/magic-wand.svg" alt="Magic wand">

        Generate a title
      </a>

      <div class="form-group">
        <label for="">Describe your listing (optional)</label>

        <textarea id="listingDescription" v-model="formData.description" rows="4" aria-required="true" aria-describedby="listingDescription-helper" required placeholder="Type special features, location, facilities...etc."></textarea>

        <div id="listingDescriptionErrorText" class="validation-error"></div>
      </div>
    </div>

    <div class="btns-container">
      <button type="button" class="secondary-btn prev-step" @click="prevSubStep">Back</button>
      <button type="button" class="primary-btn next-step" @click="nextSubStep" :class="{'btn-disabled': !isValid}" :disabled="!isValid">Next</button>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from "pinia";
import {createListingStore} from "@/stores/host/listing/createListingStore";
import stepNavigationMixin from "@/mixins/stepNavigationMixin";

export default {
  name: "SubStep4_1",
  inject: ['navigationState'],
  mixins: [stepNavigationMixin],
  computed: {
    ...mapState(createListingStore, ['formData']),
    isValid() {
      return this.formData.title.trim() !== '' && this.formData.description.trim() !== '';
    }
  },
  methods: {
    ...mapActions(createListingStore, ['saveDraft']),
    nextSubStep() {
      this.saveDraft(this.navigationState);
      // Emit an event to the parent component
      this.nextStep();
    },
    prevSubStep() {
      // Emit an event to the parent component
      this.prevStep();
    },
  }
}
</script>

<style scoped>

</style>