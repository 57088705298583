<template>
  <div class="form-step active">
    <div>
      <h2 class="heading-2">Do you have any dietary requirements or allergies?  (optional)</h2>

      <p class="font-size-medium">Select all that applies.</p>
    </div>

    <div class="form-group">
      <div class="flex-container custom-checkbox-container" role="group" aria-labelledby="allergy-label">
        <div class="checkbox-container">
          <input type="checkbox" id="no-allergy" v-model="formData.host_allergies" value="No, I don’t" aria-labelledby="no-allergy-label">

          <label for="no-allergy" id="no-allergy-label">
            <div class="label-content">No, I don’t</div>
          </label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="gluten-free" v-model="formData.host_allergies" value="Gluten free" aria-labelledby="gluten-free-label">

          <label for="gluten-free" id="gluten-free-label">
            <div class="label-content">Gluten free</div>
          </label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="lactose-free" v-model="formData.host_allergies" value="Lactose-free" aria-labelledby="lactose-free-label">

          <label for="lactose-free" id="lactose-free-label">
            <div class="label-content">Lactose-free</div>
          </label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="vegetarian" v-model="formData.host_allergies" value="Vegetarian" aria-labelledby="vegetarian-label">

          <label for="vegetarian" id="vegetarian-label">
            <div class="label-content">Vegetarian</div>
          </label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="vegan" v-model="formData.host_allergies" value="Vegan" aria-labelledby="vegan-label">

          <label for="vegan" id="vegan-label">
            <div class="label-content">Vegan</div>
          </label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="food-allergy" v-model="formData.host_allergies" value="Food allergy" aria-labelledby="food-allergy-label">

          <label for="food-allergy" id="food-allergy-label">
            <div class="label-content">Food allergy</div>
          </label>
        </div>
      </div>

      <div id="additional-dietaryReq"></div>

      <div class="add-dietary-req-container add-container" tabindex="0" role="button" aria-label="Add a requirement">
        <img src="@/assets/svgs/plus.svg" alt="Add icon">

        <p><strong>Add a dietary/allergy requirement</strong></p>
      </div>
    </div>

    <div class="btns-container">
      <button type="button" class="secondary-btn prev-step" @click.prevent="prevSubStep">Back</button>

      <button type="button" class="primary-btn next-step" @click.prevent="nextSubStep">Next</button>
    </div>
  </div>
</template>


<script>
import stepNavigationMixin from "@/mixins/stepNavigationMixin";
import {mapState} from "pinia";
import { useCreateHostProfile } from "@/stores/host/profile/useCreateHostProfile";

export default {
  name: "SubStep2_5",
  mixins: [stepNavigationMixin],
  inject: ['navigationState'],
  computed: {
    ...mapState(useCreateHostProfile, ['formData'])
  },
  methods: {
    nextSubStep() {
      this.nextStep();
    },
    prevSubStep() {
      this.prevStep();
    }
  }
}
</script>


<style scoped>

</style>