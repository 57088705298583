<template>
  <div class="container flex-container flex-col-2 mb-6 login-container">
    <div class="form-container">
      <form @submit.prevent="handleLogin" method="POST">
        <!-- Step 1: Email -->
        <div class="form-step" :class="{ active: currentStep === 1 }">
          <div>
            <h2 class="heading-2">What’s your email?</h2>
            <p class="font-size-medium">Let’s sign you in.</p>
          </div>

          <div class="form-group">
            <button type="button" class="tertiary-btn" @click="continueWithGoogle">
              <img src="@/assets/svgs/google.svg" alt="Google icon">
              Continue with Google
            </button>
            <div class="divider">
              <span>Or</span>
            </div>
            <input
                type="email"
                v-model="email"
                name="email"
                placeholder="john@acme.com"
                maxlength="254"
                id="email"
                required
            >
            <div v-if="emailError" class="validation-error">{{ emailError }}</div>
          </div>

          <div class="btns-container">
            <button type="button" class="primary-btn" @click="nextStep">Continue</button>
          </div>
        </div>

        <!-- Step 2: Password -->
        <div class="form-step" :class="{ active: currentStep === 2 }">
          <div>
            <h2 class="heading-2">What’s your password?</h2>
            <p class="font-size-medium">Let’s sign you in.</p>
          </div>

          <div class="form-group">
            <div class="form-field">
              <input
                  :type="showPassword ? 'text' : 'password'"
                  v-model="password"
                  name="password"
                  placeholder="Secure123?!?@"
                  id="password"
                  maxlength="30"
                  required
              >
              <a href="javascript:void(0)" class="toggle-password" @click="togglePasswordVisibility">
                <img
                    :src="showPassword ? hideIcon : showIcon"
                    :alt="showPassword ? 'Hide password' : 'Show password'"
                >
              </a>
            </div>
            <router-link class="forgot-password-link" to="/recover-password">Forgot password</router-link>
            <div class="form-group" tabindex="0">
              <div class="default-checkbox">
                <input type="checkbox" id="keep-me-signed" name="keep-me-signed" v-model="keepMeSigned">
                <label for="keep-me-signed">
                  <span>Keep me signed in</span>
                </label>
              </div>
            </div>
            <div v-if="errorMessage" class="validation-error">{{ errorMessage }}</div>
          </div>

          <div class="btns-container">
            <button type="button" class="secondary-btn prev-step" @click="prevStep">Back</button>
            <button type="submit" class="primary-btn">Sign in</button>
          </div>
        </div>
      </form>
    </div>

    <div class="illustration-container background-light-blue">
      <img src="@/assets/svgs/signup-illustration.svg" alt="Happipad Logo">
    </div>
  </div>
</template>

<script>
import { useAuthStore } from '@/stores/host/auth/useAuthStore';

export default {
  name: 'LoginPage',
  setup() {
    const authStore = useAuthStore();
    return { authStore };
  },
  data() {
    return {
      currentStep: 1, // Tracks the current form step
      email: '',
      password: '',
      keepMeSigned: false,
      errorMessage: '',
      emailError: '',
      passwordError: '',
      termsError: '',
      showPassword: false, // Toggles password visibility
      showIcon: require('@/assets/svgs/show-password.svg'), // Path to show icon
      hideIcon: require('@/assets/svgs/hide-password.svg'), // Path to hide icon
    };
  },
  methods: {
    // Handle "Continue with Google" button click
    continueWithGoogle() {
      // Implement Google authentication logic here
      // For example:
      // this.authStore.loginWithGoogle().then(() => { ... });
    },

    // Move to the next step after validating the email
    nextStep() {
      if (this.validateEmail(this.email)) {
        this.emailError = '';
        this.currentStep = 2;
      } else {
        this.emailError = 'Please enter a valid email address.';
      }
    },

    // Move back to the previous step
    prevStep() {
      if (this.currentStep > 1) {
        this.currentStep--;
      }
    },

    // Toggle password visibility
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },

    // Validate email format
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },

    // Handle form submission for login
    async handleLogin() {

      try {
        await this.authStore.login(this.email, this.password);
        // Redirect after successful login
        this.$router.push({ name: 'HostProfile' });
      } catch (error) {
        // Handle authentication errors
        this.errorMessage = 'Invalid email or password.';
      }
    },
  },
};
</script>


<style scoped>
.form-step {
  display: none; /* Hide all steps by default */
  transition: opacity 0.3s ease;
}
.form-step.active {
  display: block; /* Show the active step */
}
.validation-error {
  color: red;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}
/* Additional styles for buttons, inputs, etc. */
.btns-container {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}
.primary-btn, .secondary-btn, .tertiary-btn {
  padding: 0.75rem 1.5rem;
  border: none;
  cursor: pointer;
}
.primary-btn {
  color: white;
}

.tertiary-btn {
  width:100%;
  display: flex;
  align-items: center;
}
.tertiary-btn img {
  margin-right: 0.5rem;
}
.divider {
  display: flex;
  align-items: center;
  margin: 1rem 0;
}
.divider::before, .divider::after {
  content: '';
  flex: 1;
  height: 1px;
  background: #ccc;
}
.divider span {
  margin: 0 0.5rem;
  color: #666;
}
</style>