<template>
  <div class="container flex-container flex-col-2 mb-6 login-container">
    <div class="form-container">
      <form @submit.prevent="handleRecoverPassword">
        <div class="form-step active">
          <div>
            <h2 class="heading-2">Recover Password</h2>
            <p class="font-size-medium">We will send you a recovery link.</p>
          </div>

          <div class="form-group">
            <input
                type="email"
                v-model="email"
                name="email"
                placeholder="john@acme.com"
                maxlength="254"
                id="email"
                required
            />
          </div>

          <div v-if="successMessage" class="success-message">
            {{ successMessage }}
          </div>
          <div v-if="errorMessage" class="error-message">
            {{ errorMessage }}
          </div>

          <div class="btns-container">
            <button type="submit" class="primary-btn">Send email</button>
          </div>
        </div>
      </form>
    </div>

    <div class="illustration-container background-light-blue">
      <img src="@/assets/svgs/signup-illustration.svg" alt="Happipad Logo" />
    </div>
  </div>
</template>

<script>
import http from '@/http'; // Import your Axios instance

export default {
  name: 'RecoverPassword',
  data() {
    return {
      email: '',
      successMessage: '',
      errorMessage: '',
    };
  },
  methods: {
    async handleRecoverPassword() {
      try {
        const response = await http.post('/api/auth/password/email', {
          email: this.email,
        });

        // Handle success response
        this.successMessage = response.data.message;
        this.errorMessage = '';
      } catch (error) {
        // Handle error response
        if (error.response && error.response.status === 404) {
          this.errorMessage = 'User not found.';
        } else {
          this.errorMessage = 'An error occurred. Please try again.';
        }
        this.successMessage = '';
      }
    },
  },
};
</script>

<style scoped>
/* Add your styles here */
.success-message {
  color: green;
  margin-top: 1em;
}
.error-message {
  color: red;
  margin-top: 1em;
}
</style>
