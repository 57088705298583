<template>
  <div class="form-step active">
    <div>
      <h2 class="heading-2">What’s your Noise tolerance? *</h2>

      <p class="font-size-medium">Select one option.</p>
    </div>

    <div class="form-group" data-group="noise-tolerance-group">
      <div class="flex-container custom-radio-container" role="radiogroup" aria-labelledby="noise-tolerance-label">
        <div class="radio-container">
          <input type="radio" id="any-noise" v-model="formData.host_noise_tolerance" value="any-noise" aria-labelledby="any-noise-label">

          <label for="any-noise" id="any-noise-label">
            I’m fine with any noise
          </label>
        </div>

        <div class="radio-container">
          <input type="radio" id="quite-time" v-model="formData.host_noise_tolerance" value="quite-time" aria-labelledby="quite-time-label">

          <label for="quite-time" id="quite-time-label">
            I require quiet time often
          </label>
        </div>

        <div class="radio-container">
          <input type="radio" id="not-too-loud" v-model="formData.host_noise_tolerance" value="not-too-loud" aria-labelledby="not-too-loud-label">

          <label for="not-too-loud" id="not-too-loud-label">
            If it’s not too loud, I’m fine
          </label>
        </div>
      </div>

      <div id="noiseToleranceErrorText" class="validation-error"></div>
    </div>

    <div class="btns-container">
      <button type="button" class="secondary-btn prev-step" @click.prevent="prevSubStep">Back</button>

      <button type="button" class="primary-btn next-step" @click.prevent="nextSubStep">Next</button>
    </div>
  </div>
</template>

<script>
import {mapState} from "pinia";
import stepNavigationMixin from "@/mixins/stepNavigationMixin";
import { useCreateHostProfile } from "@/stores/host/profile/useCreateHostProfile";

export default {
  name: "SubStep2_1",
  mixins: [stepNavigationMixin],
  inject: ['navigationState'],
  computed: {
    ...mapState(useCreateHostProfile, ['formData'])
  },
  methods: {
    nextSubStep() {
      this.nextStep();
    },
    prevSubStep() {
      this.prevStep();
    }
  }
}
</script>

<style scoped>

</style>