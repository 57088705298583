<template>
 <RenterDashboardTopNav />

 <div class="renter-dashboard help-dashboard">
   <div class="main-dashboard container flex-container">
     <RenterDashboardSidebar />

     <div class="dashboard-content">
       <header class="mb-2">
         <h1 class="heading-2">Help 24/7</h1>
       </header>

       <div class="cards-container flex-container">
         <a href="#" class="card chat-container">
           <h2 class="heading-6">Contact support</h2>

           <p class="chat-date mt-4 mb-6 text-align-center">Today, June 19</p>

           <div class="chatbot-wrapper">
            <div class="chatbot-icon">
              <img src="@/assets/images/happipad-chat.png" alt="happiad" />
            </div>

            <div class="chatbot-message">
              <p>Hi! It’s  Robert, Happipad chatbot. What can I help you with?</p>
            </div>
           </div>

           <button class="secondary-btn mt-4">Start chat</button>
         </a>

         <a href="#" class="card faq-container">
            <h2 class="heading-6">FAQ</h2>

            <div class="faq-accordion">
              <div
                class="faq-item"
                v-for="(item, index) in faqItems"
                :key="index"
              >
                <div
                  class="faq-question"
                  @click="toggleAccordion(index, $event)"
                >
                  <p><strong>{{ item.question }}</strong></p>
                  <span class="arrow" :class="{ open: isOpen(index) }">
                    <img src="@/assets/svgs/chevron-down-dark.svg" alt="chevron down">
                  </span>
                </div>

                <div
                  class="faq-answer"
                  v-show="isOpen(index)"
                >
                  <p>{{ item.answer }}</p>
                </div>
              </div>
            </div>

            <button href="#" class="view-more mt-4">Load more topics
              <img src="@/assets/svgs/chevron-down-blue.svg" alt="chevron down">
            </button>
         </a>
       </div>
     </div>

     <RenterDashboardBottomNav />
   </div>
 </div>
</template>


<script>
 import RenterDashboardSidebar from "@/components/RenterDashboardSidebar.vue";
 import RenterDashboardBottomNav from "@/components/RenterDashboardBottomNav.vue";
 import RenterDashboardTopNav from "@/components/RenterDashboardTopNav.vue";

 export default {
   name: "RenterHelp",

   components: {
     RenterDashboardSidebar,
     RenterDashboardBottomNav,
     RenterDashboardTopNav
   },
   data() {
    return {
      activeIndex: null,
      faqItems: [
        { question: "Renting process", answer: "Nullam quis ante. Praesent venenatis metus at tortor pulvinar varius. Vivamus elementum semper nisi. Maecenas malesuada. Cras dapibus." },
        { question: "Posting your listing", answer: "Nullam quis ante. Praesent venenatis metus at tortor pulvinar varius. Vivamus elementum semper nisi. Maecenas malesuada. Cras dapibus." },
        { question: "Expired link during verification", answer: "Nullam quis ante. Praesent venenatis metus at tortor pulvinar varius. Vivamus elementum semper nisi. Maecenas malesuada. Cras dapibus." },
        { question: "Can’t get access to my account", answer: "Nullam quis ante. Praesent venenatis metus at tortor pulvinar varius. Vivamus elementum semper nisi. Maecenas malesuada. Cras dapibus." },
        { question: "Issues during logging in", answer: "Nullam quis ante. Praesent venenatis metus at tortor pulvinar varius. Vivamus elementum semper nisi. Maecenas malesuada. Cras dapibus." },
        { question: "Learn more about Happipad", answer: "Nullam quis ante. Praesent venenatis metus at tortor pulvinar varius. Vivamus elementum semper nisi. Maecenas malesuada. Cras dapibus." }
      ]
    };
  },
  methods: {
    toggleAccordion(index, event) {
      // Prevent the default click behavior
      if (event) event.preventDefault();

      // Toggle the accordion index
      this.activeIndex = this.activeIndex === index ? null : index;
    },
    isOpen(index) {
      return this.activeIndex === index;
    }
  }
 };
</script>