<template>
  <div class="container flex-container flex-col-2 mb-6 host-registration-container">
    <div class="form-container">
      <form @submit.prevent="submitForm" id="host-form">

        <div id="email" v-if="currentStep === 1" class="form-step active">
          <div>
            <h2 class="heading-2">What’s your email?</h2>
            <p class="font-size-medium">Let’s create your account.</p>
          </div>
          <div class="form-group">
            <input
                v-model="formData.email"
                type="email"
                name="email"
                placeholder="john@acme.com"
                maxlength="254"
                required
            />
            <div class="validation-error">
              <span v-if="errors.email">{{ errors.email[0] }}</span>
            </div>
          </div>
          <div class="btns-container">
            <button type="button" class="primary-btn" @click="nextStep">Next</button>
          </div>
        </div>

        <div id="password" v-if="currentStep === 2" class="form-step active">
          <h2 class="heading-2">Let’s set your password.</h2>
          <div class="form-group">
            <div class="form-field">
              <input v-model="formData.password" :type="showPassword ? 'text' : 'password'" name="password" placeholder="Secure123?!?@" maxlength="30" required/>
              <a href="javascript:void(0)" class="toggle-password" @click="togglePasswordVisibility">
                <img class="show-password-icon" src="/assets/svgs/show-password.svg" alt="Show password" v-if="!showPassword"/>
                <img class="hide-password-icon" src="/assets/svgs/hide-password.svg" alt="Hide password" v-if="showPassword"/>
              </a>
            </div>
            <div class="validation-error">
              <span v-if="errors.password">{{ errors.password[0] }}</span>
            </div>
            <div id="password-requirements" class="password-requirements mt-3">
              <p id="min-length" class="requirement" :class="{ met: passwordMinLengthMet }">
                <span class="requirement-icon">
                  <template v-if="passwordMinLengthMet">
                    <img src="@/assets/svgs/checkmark.svg" style="display:inline;">
                  </template>
                </span>
                Must be at least 8 characters
              </p>
              <p id="uppercase" class="requirement" :class="{ met: passwordHasUppercase }">
                <span class="requirement-icon">
                  <template v-if="passwordHasUppercase">
                    <img src="@/assets/svgs/checkmark.svg" style="display:inline;">
                  </template>
                </span>
                Must have at least one uppercase letter
              </p>
              <p id="symbol" class="requirement" :class="{ met: passwordHasSymbol }">
                <span class="requirement-icon">
                  <template v-if="passwordHasSymbol">
                    <img src="@/assets/svgs/checkmark.svg" style="display:inline;">
                  </template>
                </span>
                Must have at least one symbol
              </p>
              <p id="symbol" class="requirement" :class="{ met: passwordHasLowercase }">
                <span class="requirement-icon">
                  <template v-if="passwordHasLowercase">
                    <img src="@/assets/svgs/checkmark.svg" style="display:inline;">
                  </template>
                </span>
                Must have at least one lowercase letter
              </p>
              <p id="symbol" class="requirement" :class="{ met: passwordHasNumber }">
                <span class="requirement-icon">
                  <template v-if="passwordHasNumber">
                    <img src="@/assets/svgs/checkmark.svg" style="display:inline;">
                  </template>
                </span>
                Must have at least one number
              </p>
            </div>
          </div>
          <div class="btns-container">
            <button type="button" class="secondary-btn" @click="prevStep">Back</button>
            <button type="button" class="primary-btn" @click="nextStep">Next</button>
          </div>
        </div>

        <div id="name" v-if="currentStep === 3" class="form-step active">
          <div>
            <h2 class="heading-2">What’s your first and last name?</h2>
            <p class="font-size-medium">Your last name will not be visible to renters.</p>
          </div>
          <div class="form-group">
            <input
                v-model="formData.firstName"
                type="text"
                name="firstName"
                placeholder="Enter your first name"
                maxlength="30"
                required
            />
            <div class="validation-error">
              <span v-if="errors.firstName">{{ errors.firstName[0] }}</span>
            </div>

            <input
                v-model="formData.lastName"
                type="text"
                name="lastName"
                placeholder="Enter your last name"
                maxlength="30"
                required
            />
            <div class="validation-error">
              <span v-if="errors.lastName">{{ errors.lastName[0] }}</span>
            </div>
          </div>
          <div class="btns-container">
            <button type="button" class="secondary-btn" @click="prevStep">Back</button>
            <button type="button" class="primary-btn" @click="nextStep">Next</button>
          </div>
        </div>

        <div id="phone" v-if="currentStep === 4" class="form-step active">
          <div>
            <h2 class="heading-2">What’s your phone number?</h2>
            <p class="font-size-medium">
              Our customer support will contact you if you require assistance.
            </p>
          </div>
          <div class="form-group">
            <input
                v-model="formData.phone"
                type="tel"
                name="phone"
                placeholder="xxxxxxxxxxx"
                required
                max="10"
                @input="formData.phone = formData.phone.replace(/[^0-9]/g, '')"
            />
            <div class="validation-error">
              <span v-if="errors.phone">{{ errors.phone[0] }}</span>
            </div>

            <div class="form-group default-checkbox mt-4" tabindex="0">
              <input
                  v-model="formData.contactMethod"
                  type="checkbox"
                  id="contact-method"
                  name="contact-method"
              />
              <label for="contact-method">
                <span>Preferred contact method</span>
              </label>
            </div>
          </div>
          <div class="btns-container">
            <button type="button" class="secondary-btn" @click="prevStep">Back</button>
            <button type="button" class="primary-btn" @click="nextStep">Next</button>
          </div>
        </div>

        <div id="age" v-if="currentStep === 5" class="form-step active">
          <div>
            <h2 class="heading-2">What is your year of birth?</h2>
            <p class="font-size-medium">
              This information helps us better meet your needs.
            </p>
          </div>
          <div class="form-group">
            <input
                v-model="formData.birthYear"
                type="number"
                name="birth_year"
                placeholder="1924"
                :min="1924"
                :max="currentYear"
                maxlength="4"
                required
            />
            <div class="validation-error">
              <span v-if="errors.birthYear">{{ errors.birthYear[0] }}</span>
            </div>
          </div>
          <div class="btns-container">
            <button type="button" class="secondary-btn" @click="prevStep">Back</button>
            <button type="button" class="primary-btn" @click="nextStep">Next</button>
          </div>
        </div>

        <div id="province" v-if="currentStep === 6" class="form-step active">
          <div>
            <h2 class="heading-2">What’s your province?</h2>
            <p class="font-size-medium">
              This information is critical to determine your location and local rental laws.
            </p>
          </div>

          <div class="form-group">
            <DropdownComponent
                :options="provinces"
                @select="selectProvince"
                :selected="formData.province"
            />
            <div class="validation-error">
              <span v-if="errors.province">{{ errors.province[0] }}</span>
            </div>

            <input
                v-model="formData.postal_code"
                type="text"
                name="postal_code"
                placeholder="V1T1H6"
                required
            />
            <div class="validation-error">
              <span v-if="errors.postal_code">{{ errors.postal_code[0] }}</span>
            </div>
          </div>

          <div class="form-group" tabindex="0">
            <div class="default-checkbox">
              <input
                  v-model="formData.terms_agree"
                  type="checkbox"
                  id="agree-to-terms"
                  name="terms_agree"

              />
              <label for="agree-to-terms">
                <span>
                  By signing up you agree to our
                  <a href="https://happipad.com/privacy/">Privacy policy</a> and
                  <a href="https://happipad.com/terms/">Terms of use</a>.<span class="required">*</span>
                </span>
              </label>
            </div>
            <div class="validation-error">
              <span v-if="errors.terms_agree">{{ errors.terms_agree[0] }}</span>
            </div>
          </div>



          <div class="btns-container">
            <button type="button" class="secondary-btn" @click="prevStep">Back</button>
            <button type="submit" class="primary-btn">Agree and Sign up</button>
          </div>
        </div>

      </form>
    </div>
    <div class="illustration-container background-light-blue">
      <img src="../../../assets/svgs/signup-illustration.svg" alt="Signup Illustration" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useHostFormStore } from '@/stores/host/signup/RegistrationForm';
import http from '@/http';
import DropdownComponent from '@/components/DropdownComponent.vue';

export default defineComponent({
  name: 'Create-Profile',
  components: {
    DropdownComponent,
  },
  data() {
    return {
      showPassword: false,
      store: useHostFormStore(),
    };
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
    currentStep() {
      return this.store.currentStep;
    },
    formData() {
      return this.store.formData;
    },
    errors() {
      return this.store.errors;
    },
    provinces() {
      return this.store.provinces;
    },
    passwordMinLengthMet() {
      return this.formData.password && this.formData.password.length >= 8;
    },
    passwordHasUppercase() {
      return /[A-Z]/.test(this.formData.password);
    },
    passwordHasLowercase() {
      return /[a-z]/.test(this.formData.password);
    },
    passwordHasNumber() {
      return /\d/.test(this.formData.password);
    },
    passwordHasSymbol() {
      return /[@$!%*?#&]/.test(this.formData.password);
    },
  },
  methods: {
    nextStep() {
      if (this.validateStep()) {
        if (this.currentStep < 6) {
          this.store.currentStep++;
        }
      }
    },
    prevStep() {
      if (this.currentStep > 1) {
        this.store.currentStep--;
      }
    },
    async submitForm() {
      if (!this.validateAll()) {
        // If validation fails, prevent form submission
        return;
      }

      try {
        // Clear previous errors
        this.store.errors = {};

        console.log('Host registration in progress...');
        // Use http.js to send the form data to the backend
        const response = await http.post('/api/register', this.formData);

        this.$router.push({
          name: 'AwaitingVerification',
          query: { email: this.formData.email },
        });

        if (response.data.status === 'success') {
          alert('Form submitted successfully!');
          // Redirect or perform other actions as needed
        }
      } catch (error) {
        if (error.response && error.response.status === 422) {
          // Validation failed
          this.store.errors = error.response.data.errors;
          // Determine the first step with an error and show it
          this.handleValidationErrors();
        } else {
          console.error('Error submitting form:', error);
          alert('There was an error submitting the form.');
        }
      }
    },
    handleValidationErrors() {
      // Define a map of fields to steps
      const fieldToStep = {
        firstName: 3,
        lastName: 3,
        phone: 4,
        birthYear: 5,
        province: 4,
        postal_code: 4,
        password: 2,
        email: 1,
        terms_agree: 6,
      };

      // Find the first field with an error and navigate to that step
      for (const field in this.store.errors) {
        if (fieldToStep[field]) {
          this.store.currentStep = fieldToStep[field];
          break;
        }
      }
    },
    selectProvince(selectedProvince) {
      this.store.formData.province = selectedProvince;
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    validateStep() {
      // Clear previous errors
      this.store.errors = {};

      // Validate fields based on currentStep
      switch (this.currentStep) {
        case 1:
          // Validate Email
          if (!this.formData.email) {
            this.store.errors.email = ['Email is required'];
          } else if (!this.validateEmail(this.formData.email)) {
            this.store.errors.email = ['Invalid email address'];
          }
          break;

        case 2:
          // Validate Password
          if (!this.formData.password) {
            this.store.errors.password = ['Password is required'];
          } else if (!this.validatePassword(this.formData.password)) {
            this.store.errors.password = [
              'Password must be at least 8 characters and include uppercase, lowercase, number, and special character',
            ];
          }
          break;

        case 3:
          // Validate First Name and Last Name
          if (!this.formData.firstName) {
            this.store.errors.firstName = ['First name is required'];
          } else if (!/^[A-Za-z\s'-]{1,30}$/.test(this.formData.firstName)) {
            this.store.errors.firstName = ['First name contains invalid characters'];
          }
          if (!this.formData.lastName) {
            this.store.errors.lastName = ['Last name is required'];
          } else if (!/^[A-Za-z\s'-]{1,30}$/.test(this.formData.lastName)) {
            this.store.errors.lastName = ['Last name contains invalid characters'];
          }
          break;

        case 4:
          // Validate Phone Number
          if (!this.formData.phone) {
            this.store.errors.phone = ['Phone number is required'];
          } else if (!this.validatePhoneNumber(this.formData.phone)) {
            this.store.errors.phone = ['Invalid phone number'];
          }
          break;

        case 5: {
          // Validate Age (Birth Year)
          const currentYear = this.currentYear;
          const minAllowedYear = currentYear - 18; // Must be at least 18 years old

          if (!this.formData.birthYear) {
            this.store.errors.birthYear = ['Birth year is required'];
          } else if (
              isNaN(this.formData.birthYear) ||
              this.formData.birthYear < 1924 ||
              this.formData.birthYear > currentYear
          ) {
            this.store.errors.birthYear = ['Invalid birth year'];
          } else if (this.formData.birthYear > minAllowedYear) {
            this.store.errors.birthYear = ['You must be at least 18 years old to register'];
          }
          break;
        }

        case 6:
          // Validate Province, Postal Code, and Terms Agreement
          if (!this.formData.province) {
            this.store.errors.province = ['Province is required'];
          }
          if (!this.formData.postal_code) {
            this.store.errors.postal_code = ['Postal code is required'];
          } else if (!this.validatePostalCode(this.formData.postal_code)) {
            this.store.errors.postal_code = ['Invalid postal code'];
          }
          if (!this.formData.terms_agree) {
            this.store.errors.terms_agree = ['You must agree to the terms'];
          }
          break;

        default:
          break;
      }

      // Return true if there are no errors
      return Object.keys(this.store.errors).length === 0;
    },
    validateAll() {
      // Clear previous errors
      this.store.errors = {};

      // Step 1: Validate Email
      if (!this.formData.email) {
        this.store.errors.email = ['Email is required'];
      } else if (!this.validateEmail(this.formData.email)) {
        this.store.errors.email = ['Invalid email address'];
      }

      // Step 2: Validate Password
      if (!this.formData.password) {
        this.store.errors.password = ['Password is required'];
      } else if (!this.validatePassword(this.formData.password)) {
        this.store.errors.password = [
          'Password must be at least 8 characters and include uppercase, lowercase, number, and special character',
        ];
      }

      // Step 3: Validate First Name and Last Name
      if (!this.formData.firstName) {
        this.store.errors.firstName = ['First name is required'];
      } else if (!/^[A-Za-z\s'-]{1,30}$/.test(this.formData.firstName)) {
        this.store.errors.firstName = ['First name contains invalid characters'];
      }
      if (!this.formData.lastName) {
        this.store.errors.lastName = ['Last name is required'];
      } else if (!/^[A-Za-z\s'-]{1,30}$/.test(this.formData.lastName)) {
        this.store.errors.lastName = ['Last name contains invalid characters'];
      }

      // Step 4: Validate Phone Number
      if (!this.formData.phone) {
        this.store.errors.phone = ['Phone number is required'];
      } else if (!this.validatePhoneNumber(this.formData.phone)) {
        this.store.errors.phone = ['Invalid phone number'];
      }

      // Step 5: Validate Age (Birth Year)
      const currentYear = this.currentYear;
      const minAllowedYear = currentYear - 18; // Must be at least 18 years old
      if (!this.formData.birthYear) {
        this.store.errors.birthYear = ['Birth year is required'];
      } else if (
          isNaN(this.formData.birthYear) ||
          this.formData.birthYear < 1924 ||
          this.formData.birthYear > currentYear
      ) {
        this.store.errors.birthYear = ['Invalid birth year'];
      } else if (this.formData.birthYear > minAllowedYear) {
        this.store.errors.birthYear = ['You must be at least 18 years old to register'];
      }

      // Step 6: Validate Province, Postal Code, and Terms Agreement
      if (!this.formData.province) {
        this.store.errors.province = ['Province is required'];
      }
      if (!this.formData.postal_code) {
        this.store.errors.postal_code = ['Postal code is required'];
      } else if (!this.validatePostalCode(this.formData.postal_code)) {
        this.store.errors.postal_code = ['Invalid postal code'];
      }
      if (!this.formData.terms_agree) {
        this.store.errors.terms_agree = ['You must agree to the terms'];
      }

      // Return true if there are no errors
      return Object.keys(this.store.errors).length === 0;
    },
    // Validation helper methods
    validatePhoneNumber(phone) {
      // Simple regex for phone number validation (accepts digits, spaces, dashes, and parentheses)
      const phoneRegex = /^[0-9+\-\s()]{10}$/;
      return phoneRegex.test(phone);
    },
    validatePostalCode(postalCode) {
      // Canadian postal code format
      const postalCodeRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
      return postalCodeRegex.test(postalCode);
    },
    validatePassword(password) {
      // Password criteria: at least 8 characters, including uppercase, lowercase, number, and special character
      const passwordRegex =
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?#&])[A-Za-z\d@$!%*?&#]{8,}$/;
      return passwordRegex.test(password);
    },
    validateEmail(email) {
      // Simple email validation regex
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
  },
});
</script>