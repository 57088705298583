<template>
  <div class="form-step active" data-step="2-6">
    <div>
      <h2 class="heading-2">Renter’s bathroom is?</h2>

      <p class="font-size-medium">Select all that applies.</p>
    </div>

    <div class="form-group">
      <div class="flex-container custom-checkbox-container" role="group" aria-labelledby="bathroom-label">
        <div class="checkbox-container">
          <input type="checkbox" id="private-full-bath" v-model="formData.bathroom" value="private_full">

          <label for="private-full-bath">Private full bath</label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="private-half-bath" v-model="formData.bathroom" value="private_half">

          <label for="private-half-bath">Private half bath</label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="shared-full-bath" v-model="formData.bathroom" value="shared_full">

          <label for="shared-full-bath">Shared full bath</label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="shared-half-bath" v-model="formData.bathroom" value="shared_half">

          <label for="shared-half-bath">Shared half bath</label>
        </div>
      </div>

      <div id="bathroomErrorText" class="validation-error"></div>

      <div class="form-group" id="quantity-container">
        <label class="heading-6 mb-3" for="quantity">How many people are sharing this bathroom?</label>

        <div class="input-number-container">
          <button type="button" class="decrement" aria-label="Decrease value" @click="decrement()">-</button>

          <input type="number" v-model="formData.bathroom_occupants" value="1" min="0" max="100" aria-label="Quantity">

          <button type="button" class="increment" aria-label="Increase value" @click="increment()">+</button>
        </div>
      </div>
    </div>

    <div class="btns-container">
      <button type="button" class="secondary-btn prev-step" @click="prevSubStep">Back</button>
      <button type="button" class="primary-btn next-step" @click="nextSubStep" :class="{'btn-disabled': !isValid}" :disabled="!isValid">Next</button>
    </div>
  </div>
</template>

<style scoped>

</style>
<script>
import {mapState, mapActions} from "pinia";
import {createListingStore} from "@/stores/host/listing/createListingStore";
import stepNavigationMixin from "@/mixins/stepNavigationMixin";
import attributeIncrementMixin from "@/mixins/attributeIncrementMixin";

export default {
  name: "SubStep2_6",
  mixins: [stepNavigationMixin, attributeIncrementMixin],
  inject: ['navigationState'],
  computed: {
    ...mapState(createListingStore, ['formData']),
    isValid() {
      return this.formData.bathroom.length > 0;
    },
  },
  methods: {
    ...mapActions(createListingStore, ['saveDraft']),
    nextSubStep() {
      this.saveDraft(this.navigationState);
      // Emit an event to the parent component
      this.nextStep(); // Directly call the store's action
    },
    prevSubStep() {
      // Emit an event to the parent component
      this.prevStep(); // Directly call the store's action
    },
    increment(){
      this.incrementAttribute('formData.bathroom_occupants');
    },
    decrement(){
      this.decrementAttribute('formData.bathroom_occupants');
    }
  }
}
</script>