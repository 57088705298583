<template>
  <div class="form-step active" data-step="2-12">
    <div>
      <h2 class="heading-2">What safety features are available?</h2>

      <p class="font-size-medium">Select all that applies.</p>
    </div>

    <div class="form-group">
      <div class="flex-container custom-checkbox-container mt-5" role="group" aria-labelledby="safety-equipment-label">
        <!-- First Column -->
        <div class="checkbox-container">
          <input type="checkbox" id="smoke-detector" v-model="formData.safety_features" value="smoke_detector">
          <label for="smoke-detector">
            <img src="@/assets/svgs/smoke-detector.svg" alt="smoke detector">
            Smoke detector
          </label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="carbon-monoxide-detector" v-model="formData.safety_features" value="carbon_monoxide_detector">
          <label for="carbon-monoxide-detector">
            <img src="@/assets/svgs/carbon-monoxide-detector.svg" alt="carbon monoxide detector">
            Carbon monoxide detector
          </label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="security-alarm" v-model="formData.safety_features" value="security_alarm">
          <label for="security-alarm">
            <img src="@/assets/svgs/security-alarm.svg" alt="security alarm">
            Security alarm
          </label>
        </div>

        <!-- Second Column -->
        <div class="checkbox-container">
          <input type="checkbox" id="fire-extinguisher" v-model="formData.safety_features" value="fire_extinguisher">
          <label for="fire-extinguisher">
            <img src="@/assets/svgs/fire-extinguisher.svg" alt="fire extinguisher">
            Fire extinguisher
          </label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="first-aid-kit" v-model="formData.safety_features" value="first_aid_kit">
          <label for="first-aid-kit">
            <img src="@/assets/svgs/first-aid-kit.svg" alt="first aid kit">
            First aid kit
          </label>
        </div>

        <div class="checkbox-container">
          <input type="checkbox" id="lock-on-the-door" v-model="formData.safety_features" value="door_lock">
          <label for="lock-on-the-door">
            <img src="@/assets/svgs/door-lock.svg" alt="first aid kit">
            Lock on the door
          </label>
        </div>
      </div>

      <div id="safetyErrorText" class="validation-error"></div>
    </div>

    <div class="info-note mt-4 mb-5">
      <img src="@/assets/svgs/info.svg" alt="Info">

      <p>Cameras are not allowed inside the property. If you have cameras installed, you have to disclose them.</p>
    </div>

    <div class="btns-container">
      <button type="button" class="secondary-btn prev-step" @click="prevSubStep">Back</button>
      <button type="button" class="primary-btn next-step" @click="nextSubStep" :class="{'btn-disabled': !isValid}" :disabled="!isValid">Next</button>
    </div>
  </div>
</template>

<style scoped>

</style>
<script>
import {mapState, mapActions} from "pinia";
import {createListingStore} from "@/stores/host/listing/createListingStore";
import stepNavigationMixin from "@/mixins/stepNavigationMixin";

export default {
  name: "SubStep2_12",
  mixins: [stepNavigationMixin],
  inject: ['navigationState'],
  computed: {
    ...mapState(createListingStore, ['formData']),
    isValid() {
      return (this.formData.safety_features.length > 0);
    }
  },
  methods: {
    ...mapActions(createListingStore, ["saveDraft"]),
    nextSubStep() {
      this.saveDraft(this.navigationState);
      this.nextStep(); // Directly call the store's action
    },
    prevSubStep() {
      this.prevStep(); // Directly call the store's action
    },
  }
}
</script>