<template>
  <div class="form-step active">
    <div>
      <h2>Tell us about yourself. (optional)</h2>
    </div>

    <div class="form-group">
      <div>
        <h3 class="heading-6">Upload your photo.</h3>
        <p>Formats excepted: png, jpeg, jpg</p>
      </div>

      <div class="file-upload" id="file-upload" tabindex="0">
        <input type="file" id="file" name="file" class="file-input" multiple>

        <label for="file" class="file-label">
          <div class="file-upload-container">
            <img src="@/assets/svgs/profile-upload.svg" alt="Upload Icon" class="upload-icon">
            <div class="file-upload-content">
              <p class="upload-text">Drop your files here or click <strong>browse</strong></p>
              <p class="upload-size">Maximum size: 100MB</p>
            </div>
          </div>
        </label>
      </div>
    </div>

    <div class="form-group">
      <label for="self-description">Tell us about yourself.</label>

      <input type="text" v-model="formData.host_description" placeholder="Start typing.." id="self-description">
    </div>

    <div class="btns-container">
      <button type="button" class="primary-btn next-step" @click.prevent="nextSubStep">Save</button>
    </div>
  </div>
</template>

<script>
import {mapState} from "pinia";
import stepNavigationMixin from "@/mixins/stepNavigationMixin";
import { useCreateHostProfile } from "@/stores/host/profile/useCreateHostProfile";

export default {
  name: "SubStep3_4",
  mixins: [stepNavigationMixin],
  inject: ['navigationState'],
  computed: {
    ...mapState(useCreateHostProfile, ['formData']),
  },
  methods: {
    nextSubStep() {
      this.$router.push({ name: 'test' });
      // this.nextStep();
    },
    prevSubStep() {
      // the end
    },
  }
}
</script>

<style scoped>

</style>