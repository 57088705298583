<!--CreateListing.vue-->
<template>
  <div class="background-light-blue">
    <div class="container flex-container flex-col-2 create-listing-container">
      <div class="mobile-progress">
        <div class="mobile-progress-bar">
          <span class="completed"></span>
          <span class="completed"></span>
          <span class="current"></span>
          <span></span>
          <span></span>
          <span></span>
        </div>

        <div class="mobile-progress-info">
          <span class="current-step"><strong>Basics</strong> <span class="steps-progress">1 / 5 steps</span></span>

          <button class="see-steps-btn" tabindex="0">See steps
            <img src="@/assets/svgs/chevron-down-blue.svg" alt="Chevron icon">
          </button>
        </div>

        <div class="sidebar">
          <div class="sidebar-steps-container">
            <p class="heading-5">{{ $t('steps') }}</p>
            <ul class="steps">
              <!-- Main Step 1 -->
              <li class="step" :class="{ active: navigationState.currentStep === '1', completed: isStepCompleted('1') }" data-step-index="1" tabindex="0" @click="goToStep('1', 1)">
                <div class="progress-bar"></div>
                <div class="step-content">
                  <span>{{ $t('property_address') }}</span>
                </div>
              </li>

              <li class="step" :class="{ active: navigationState.currentStep === '2', completed: isStepCompleted('2') }" data-step-index="2" tabindex="0">
                <div class="progress-bar"><div class="progress-bar-fill" :style="{ height: getStepProgress('2') + '%' }"></div></div>

                <div class="step-content">
                  <span>{{ $t('about_listing') }}</span>

                  <ul class="sub-steps">
                    <li
                        class="sub-step"
                        v-for="subStepIndex in navigationState.subSteps['2']"
                        :key="subStepIndex"
                        :class="{ active: navigationState.currentSubStep === subStepIndex, completed: isSubStepCompleted('2', subStepIndex) }"
                        :data-sub-step-index="subStepIndex"
                        tabindex="0"
                        @click="goToStep('2', subStepIndex)"
                    >
                      {{ getSubStepLabel(subStepIndex) }}
                    </li>
                  </ul>
                </div>
              </li>

              <li class="step" :class="{ active: navigationState.currentStep === '3', completed: isStepCompleted('3') }" data-step-index="3" tabindex="0">
                <div class="progress-bar"><div class="progress-bar-fill" :style="{ height: getStepProgress('3') + '%' }"></div></div>

                <div class="step-content">
                  <span>{{ $t('about_listing') }}</span>

                  <ul class="sub-steps">
                    <li class="sub-step" v-for="subStepIndex in navigationState.subSteps['3']" :key="subStepIndex"
                        :class="{ active: navigationState.currentSubStep === subStepIndex, completed: isSubStepCompleted('3', subStepIndex) }"
                        :data-sub-step-index="subStepIndex" tabindex="0"
                        @click="goToStep('3', subStepIndex)"
                    >
                      {{ getSubStepLabel(subStepIndex) }}
                    </li>
                  </ul>
                </div>
              </li>

              <li class="step" :class="{ active: navigationState.currentStep === '4', completed: isStepCompleted('4') }" data-step-index="4" tabindex="0">
                <div class="progress-bar"><div class="progress-bar-fill" :style="{ height: getStepProgress('4') + '%' }"></div></div>

                <div class="step-content">
                  <span>Listing information</span>

                  <ul class="sub-steps">
                    <li class="sub-step" v-for="subStepIndex in navigationState.subSteps['4']" :key="subStepIndex"
                        :class="{ active: navigationState.currentSubStep === subStepIndex, completed: isSubStepCompleted('4', subStepIndex) }"
                        :data-sub-step-index="subStepIndex" tabindex="0"
                        @click="goToStep('4', subStepIndex)"
                    >
                      {{ getSubStepLabel(subStepIndex) }}
                    </li>
                  </ul>
                </div>
              </li>

              <li class="step" data-step-index="5" tabindex="0" @click="goToStep('5', null)">
                <div class="progress-bar"></div>
                <div class="step-content">
                  <span>Review</span>
                </div>
              </li>
            </ul>

          </div>

          <div class="progress-info">
            <div class="current-steps-container">
              <span class="steps-progress"> {{navigationState.currentStep}}/{{navigationState.steps.length}} steps</span>
            </div>

            <button class="hide-steps-btn" tabindex="0">Hide steps
              <img src="/assets/svgs/chevron-up-blue.svg" alt="Chevron icon">
            </button>
          </div>
        </div>
      </div>

      <div class="form-container">
        <form action="" method="POST" id="host-form" enctype="multipart/form-data">

          <StepOne v-if="navigationState.currentStep === '1'"/>
          <StepTwo v-if="navigationState.currentStep === '2'"/>
          <StepThree v-if="navigationState.currentStep === '3'"/>
          <StepFour v-if="navigationState.currentStep === '4'"/>
          <div>
            <StepFive  v-if="navigationState.currentStep === '5'"/>
          </div>

        </form>
      </div>
    </div>
  </div>
</template>

<script>

import http from "@/http";
import {reactive} from "vue";
import { mapState, mapActions } from 'pinia';
import StepOne from './Step1/StepOne.vue';
import StepTwo from './Step2/StepTwo.vue';
import StepThree from './Step3/StepThree.vue';
import StepFour from './Step4/StepFour.vue';
import StepFive from './Step5/StepFive.vue';
import stepProgressMixin from '@/mixins/stepNavigationMixin.js';
import { createListingStore } from '@/stores/host/listing/createListingStore';

export default {
  mixins: [stepProgressMixin],
  name: 'CreateListing',
  data() {
    return {
      navigationState: reactive({
        currentStep: '1',
        currentSubStep: "1",
        steps: ['1', '2', '3', '4', '5'],
        subSteps: {},
        labelKeys: {
          '2.1': 'home_type',
          '2.2': 'rental_space_type',
          '2.3': 'hosting_preference',
          '2.4': 'rental_space_size',
          '2.5': 'available_areas',
          '2.6': 'renter_bathroom',
          '2.7': 'shared_spaces',
          '2.8': 'household_information',
          '2.9': 'household_items',
          '2.10': 'building_facilities',
          '2.11': 'space_amenities',
          '2.12': 'safety_features',
          '3.1': 'pets',
          '3.2': 'other_house_rules',
          '4.1': 'title_description',
          '4.2': 'price',
          '4.3': 'photos',
          '4.4': 'available_dates',
          '5.1': 'review',
        },
      }),
      baseSubSteps: {
        '2': ['2.1', '2.2', '2.3', '2.4', '2.5', '2.6', '2.7', '2.8', '2.9', '2.10', '2.11', '2.12'],
        '3': ['3.1', '3.2'],
        '4': ['4.1', '4.2', '4.3', '4.4'],
      }
    }
  },

  mounted() {
    // Deep copy of baseSubSteps to prevent mutations
    this.navigationState.subSteps = JSON.parse(JSON.stringify(this.baseSubSteps));

    const id = this.$route.params.id;
    if (id) {
      this.formData.property_id = id;
      this.fetchPropertyData(id);
    }

    // Update sub-steps based on the initial `formData.space_type`
    // this.updateSubStepsBasedOnSpaceType(this.formData.space_type);
  },
  provide() {
    return {
      navigationState: this.navigationState,
    };
  },
  watch: {
    'formData.space_type'(newVal) {
      this.updateSubStepsBasedOnSpaceType(newVal);
    },
  },
  computed: {
    ...mapState(createListingStore, ['formData']),
  },
  components:{
    StepOne, StepTwo, StepThree, StepFour, StepFive
  },
  methods: {
    ...mapActions(createListingStore, ['updateFormData']),
    async fetchPropertyData(id) {
      try {
        const response = await http.get(`/api/properties/${id}`);
        const propertyData = response.data.property;

        // parse listing step from the database so we can lead the user to where he stopped last time
        const listingSubStep = propertyData.listing_step;
        const stepIndex = listingSubStep.split(".")[0];

        // Update the formData in the store
        this.updateFormData(propertyData);


        // lead the user where he stopped last time
        this.goToStep(stepIndex, listingSubStep);


        // console.log('Property data fetched:', propertyData);
      } catch (error) {
        console.error('Error fetching property data:', error);

        if (error.response && error.response.status === 404) {
          // Property not found, redirect to create a new one
          this.$router.replace({ name: 'CreateListing' });
        } else {
          // Handle other errors appropriately
          console.error('An error occurred while fetching property data.');
        }
      }
    },
    updateSubStepsBasedOnSpaceType(spaceType) {
      // Reset `subSteps` to the base state
      this.navigationState.subSteps = JSON.parse(JSON.stringify(this.baseSubSteps));

      // Define steps to remove for 'entire_space'
      const stepsToRemove = {
        '2': ['2.6', '2.4', '2.7', '2.8', '2.9'],
        '3': ['3.1'],
      };

      // If `spaceType` is 'entire_space', remove specified steps
      if (spaceType === 'entire_space') {
        Object.keys(stepsToRemove).forEach(stepGroup => {
          stepsToRemove[stepGroup].forEach(step => {
            const index = this.navigationState.subSteps[stepGroup].indexOf(step);
            if (index !== -1) {
              this.navigationState.subSteps[stepGroup].splice(index, 1);
            }
          });
        });
      }

      // Check if the current sub-step is still valid
      const currentSubSteps = this.navigationState.subSteps[this.navigationState.currentStep] || [];
      if (!currentSubSteps.includes(this.navigationState.currentSubStep)) {
        this.nextStep();
      }
    }
  },
};
</script>


<style scoped>
.progress-bar-fill {
  background-color: #4969AF; /* The fill color you want */
  height: 0%; /* We'll adjust this dynamically */
  transition: width 0.3s ease; /* Smooth transition */
  border-radius:10px; /* Adjust radius as needed */
}
</style>