<template>
  <SubStep2_1 v-if="navigationState.currentSubStep === '2.1'"/>
  <SubStep2_2 v-if="navigationState.currentSubStep === '2.2'"/>
  <SubStep2_3 v-if="navigationState.currentSubStep === '2.3'"/>
  <SubStep2_4 v-if="navigationState.currentSubStep === '2.4'"/>
  <SubStep2_5 v-if="navigationState.currentSubStep === '2.5'"/>
  <SubStep2_6 v-if="navigationState.currentSubStep === '2.6'"/>
  <SubStep2_7 v-if="navigationState.currentSubStep === '2.7'"/>
</template>

<script>
import SubStep2_1 from "@/views/host/profile/Step2/SubStep2_1.vue";
import SubStep2_2 from "@/views/host/profile/Step2/SubStep2_2.vue";
import SubStep2_3 from "@/views/host/profile/Step2/SubStep2_3.vue";
import SubStep2_4 from "@/views/host/profile/Step2/SubStep2_4.vue";
import SubStep2_5 from "@/views/host/profile/Step2/SubStep2_5.vue";
import SubStep2_6 from "@/views/host/profile/Step2/SubStep2_6.vue";
import SubStep2_7 from "@/views/host/profile/Step2/SubStep2_7.vue";
import stepNavigationMixin from "@/mixins/stepNavigationMixin";

export default {
  name: "StepTwo",
  inject: ['navigationState'],
  mixins: [stepNavigationMixin],
  components: {SubStep2_1, SubStep2_2, SubStep2_3, SubStep2_4, SubStep2_5, SubStep2_6, SubStep2_7},
}
</script>

<style scoped>

</style>