<template>
  <SubStep4_1 v-if="navigationState.currentSubStep === '4.1'"/>
  <SubStep4_2 v-if="navigationState.currentSubStep === '4.2'"/>
  <SubStep4_3 v-if="navigationState.currentSubStep === '4.3'"/>
  <SubStep4_4 v-if="navigationState.currentSubStep === '4.4'"/>
</template>

<style scoped>

</style>

<script>
import SubStep4_1 from "@/views/host/listing/Step4/SubStep4_1.vue";
import SubStep4_2 from "@/views/host/listing/Step4/SubStep4_2.vue";
import SubStep4_3 from "@/views/host/listing/Step4/SubStep4_3.vue";
import SubStep4_4 from "@/views/host/listing/Step4/SubStep4_4.vue";
import stepNavigationMixin from "@/mixins/stepNavigationMixin";

export default {
  name: "StepThree",
  inject: ['navigationState'],
  mixins: [stepNavigationMixin],
  components: {
    SubStep4_1,
    SubStep4_2,
    SubStep4_3,
    SubStep4_4,
  },
  computed: {
    subStepArray() {
      return this.subSteps['4'];
    },
  },
  methods: {
    // nextSubStep() {
    //   const currentIndex = this.subStepArray.indexOf(this.currentSubStep);
    //   if (currentIndex < this.subStepArray.length - 1) {
    //     // Move to next sub-step
    //     const nextSubStep = this.subStepArray[currentIndex + 1];
    //     this.setCurrentSubStep(nextSubStep);
    //   } else {
    //     // Finished sub-steps for step 2, move to next main step
    //     this.nextStep();
    //   }
    // },
    // prevSubStep() {
    //   const currentIndex = this.subStepArray.indexOf(this.currentSubStep);
    //   if (currentIndex > 0) {
    //     // Move to previous sub-step
    //     const prevSubStep = this.subStepArray[currentIndex - 1];
    //     this.setCurrentSubStep(prevSubStep);
    //   } else {
    //     // At the first sub-step, move back to previous main step
    //     this.prevStep();
    //   }
    // },
  },
};
</script>