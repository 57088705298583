<template>
  <div class="form-step active">
    <div>
      <h2 class="heading-2">How often do your clean your house? *</h2>

      <p class="font-size-medium">Select what applies.</p>
    </div>

    <div class="form-group" data-group="cleaning-group">
      <div class="flex-container custom-radio-container" role="radiogroup" aria-labelledby="cleaning-frequency-label">
        <div class="radio-container">
          <input type="radio" id="daily-cleaning" v-model="formData.host_cleaning_frequency" value="daily" aria-labelledby="daily-cleaning-label">
          <label for="daily-cleaning" id="daily-cleaning-label">
            Daily
          </label>
        </div>

        <div class="radio-container">
          <input type="radio" id="few-times-week-cleaning" v-model="formData.host_cleaning_frequency" value="few-times-week-cleaning" aria-labelledby="few-times-week-cleaning-label">
          <label for="few-times-week-cleaning" id="few-times-week-cleaning-label">
            A few times per week
          </label>
        </div>

        <div class="radio-container">
          <input type="radio" id="weekly-cleaning" v-model="formData.host_cleaning_frequency" value="weekly" aria-labelledby="weekly-cleaning-label">
          <label for="weekly-cleaning" id="weekly-cleaning-label">
            Weekly
          </label>
        </div>

        <div class="radio-container">
          <input type="radio" id="every-two-weeks-cleaning" v-model="formData.host_cleaning_frequency" value="Once every two weeks" aria-labelledby="every-two-weeks-cleaning-label">
          <label for="every-two-weeks-cleaning" id="every-two-weeks-cleaning-label">
            Once every two weeks
          </label>
        </div>

        <div class="radio-container">
          <input type="radio" id="monthly-cleaning" v-model="formData.host_cleaning_frequency" value="monthly" aria-labelledby="monthly-cleaning-label">
          <label for="monthly-cleaning" id="monthly-cleaning-label">
            Monthly
          </label>
        </div>
      </div>

      <div id="cleaningErrorText" class="validation-error"></div>
    </div>

    <div class="btns-container">
      <button type="button" class="secondary-btn prev-step" @click.prevent="prevSubStep">Back</button>

      <button type="button" class="primary-btn next-step" @click.prevent="nextSubStep">Next</button>
    </div>
  </div>
</template>

<script>
import stepNavigationMixin from "@/mixins/stepNavigationMixin";
import { useCreateHostProfile } from "@/stores/host/profile/useCreateHostProfile";
import {mapState} from "pinia";

export default {
  name: "SubStep3_2",
  mixins: [stepNavigationMixin],
  inject: ['navigationState'],
  computed: {
    ...mapState(useCreateHostProfile, ['formData']),
  },
  methods: {
    nextSubStep() {
      this.nextStep();
    },
    prevSubStep() {
      this.prevStep();
    }
  }
}
</script>

<style scoped>

</style>